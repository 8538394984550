<template>
  <sweet-dialog v-model="dialogVisible" :title="$t('agentconsole.Intelligent.labelname')" width="616px"
                :close-on-click-modal="false">
    <div class="srp">
      <div class="srp-main">
        <div class="srp-content" v-for="(x, $index) in record">
          <div :class="[{'hlds-text-align--right':x.isMachine}]" v-show="!x.isNone">
            <span class="hlds-text-color--weak">{{ x.name }}&nbsp;</span>
            <span class="hlds-text-color--weak">{{ x.time }}</span>
            <span style="display: block">{{ x.content }}</span>
            <span style="color:#212121;font-weight: 600"
                  v-if="x.keyword != undefined && x.keyword != ''">【{{ x.keyword }}】</span>
          </div>
        </div>

        <div class="srp-content" style="text-align: center;margin-top: 130px;}" v-for="(x, $index) in record">
          <div v-show="x.isNone">
				    <span style="font-size: 15px;">{{ x.content }}
			      </span>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
            <span class="dialog-footer">
                <sweet-button @click="dialogVisible = false">{{ $t('agentconsole.Intelligent.close') }}</sweet-button>
            </span>
    </template>
  </sweet-dialog>
</template>


<script>
import {useCallIdStore} from "@/views/agentconsole/stores";
import {queryInteractiveRecord} from "@/views/agentconsole/api/audio-video-workbench";

export default {
  name: 'IntelligentRecord',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const callIdStore = useCallIdStore();
    return {callIdStore}
  },
  data() {
    return {
      resultModel: {},
      searchObject: {},
      callid: "",
      params: {},
      record: []
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    escapeHtml(text) {
      text = '' + text;
      var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "''": '&#039;',
        '/': '/',
        '(': '&#40;',
        ')': '&#41;'
      };

      return text.replace(/[&<>""'()/]/g, function (m) {
        return map[m];
      });
    }
  },
  mounted() {
    this.searchObject = {'params': {'callid': this.callIdStore.callId, 'caller': window.$Model.audioVideoCaller}};
    queryInteractiveRecord(this.searchObject).then(data => {
      this.resultModel = data;
      this.record['isNone'] = false;
      if (!this.resultModel || !this.resultModel['beans']) {
        let userModel = [];
        userModel['content'] = $t('agentconsole.Intelligent.noRecord');
        userModel['isNone'] = true;
        this.record.push(userModel);
      } else {
        for (let i = 0; i < this.resultModel['beans'].length; i++) {
          let userModel = {};
          var machineModel = {};
          var time = this.escapeHtml(this.resultModel['beans'][i].timestamp);
          if (time && time.lastIndexOf(".") > 0) {
            this.resultModel['beans'][i].timestamp = time.substring(0, time.lastIndexOf("."));
          }
          if (this.resultModel['beans'][i].userSaying) {
            userModel['name'] = this.escapeHtml(this.resultModel['callSorId']);
            userModel['time'] = this.escapeHtml(this.resultModel['beans'][i].timestamp);
            userModel['content'] = this.escapeHtml(this.resultModel['beans'][i].userSaying);
            userModel['keyword'] = this.escapeHtml(this.resultModel['beans'][i].intention);
            userModel['isMachine'] = false;
          }
          if (this.resultModel['beans'][i].systemReply) {
            machineModel['name'] = $t('agentconsole.Intelligent.robot');
            machineModel['time'] = this.escapeHtml(this.resultModel['beans'][i].timestamp);
            machineModel['content'] = this.escapeHtml(this.resultModel['beans'][i].systemReply);
            machineModel['isMachine'] = true;
          }
          this.record.push(userModel);
          this.record.push(machineModel);
        }
      }
    })
  }
}
</script>

<style lang="less" scoped>
@import '@/views/agentconsole/css/audioandvideoworkbench.css';

.popwin .popwin_iframe {
  padding-right: 0 !important;
}

.popwin_middle .popwin_middle_center {
  overflow-y: hidden !important;
}

.srp {
  padding: 2px;
  position: relative;

}

.srp-main {
  height: 324px;
  overflow-y: scroll;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.srp-content {
  line-height: 1.5;
  margin-bottom: 1rem;
}

.srp-content:last-child {
  margin-bottom: 0;
}

.hlds-text-align--right {
  text-align: right
}

.hlds-text-color--weak {
  color: #6f7479
}
</style>