<template>
  <body class="hlds-self" id="workbeanch_body">
  <div class="page-top">
    <div class="user-info" style="display: none" v-text="$Model['callNumber'] || ''"></div>
    <div style="display: flex">
      <div class="button-div" v-if="iolpFlowVisible && $Model.hasIolpAuth" @click="openIolpTab($Model)">
        <img src="../../assets/img/ic_业务引导@2x.png" class="button-img"/>
        <div class="text-button">{{ $t('iolp.workflow.title.name') }}</div>
      </div>
      <div class="button-div" v-if="$Model.isCreateCaseFlag" @click="createCase($Model, this)">
        <img src="../../assets/img/ic_case@2x_new.png" class="button-img"/>
        <div class="text-button">{{ $t('ccm.asr.create.case') }}</div>
      </div>
      <div class="button-div" @click="generateSummary">
        <img src="../../assets/img/ic_GenerateSummary@2x.png" class="button-img"/>
        <div class="text-button">{{ $t('agentconsole.agent.generate.summary') }}</div>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div style="width: 100%;display: flex;">
      <div class="tab-div width-large" style="flex-grow: 0; margin-bottom:16px;margin-top:16px;">
        <div class="user-info-name number-plaintext">
          <div v-text=" $Model['callNumberName'] || ''"></div>
          <div class="aicc-user-info-verify">
            <sweet-tooltip :content="$t('ccm.verifyidentity.label')" placement="top-start" v-if="$Model.showVerifyButton">
              <sweet-dropdown trigger="click">
                <div v-if="$Model.showVerifyButton" id="ic_verify_identity" class="iconMargin">
                  <img class="icon-button-verify" src="@/views/agentconsole/assets/img/ic_identity_verification@2x.png"
                       alt=""/>
                </div>
                <template #dropdown>
                  <sweet-dropdown-menu>
                    <sweet-dropdown-item
                        v-for="item in $Model.VerifyConfigItems"
                        :key="item.id"
                        @click="selectVerifyIdentity($Model, item)"
                    >{{ item.verifyName }}
                    </sweet-dropdown-item>
                  </sweet-dropdown-menu>
                </template>
              </sweet-dropdown>
            </sweet-tooltip>
            <sweet-tooltip :content="$t('ccm.agent.contact.createbother')" placement="bottom" effect="light"
                           v-if="$Model.showCreateBotherFlag">
              <div id="ic_BotherCall" class="iconMargin">
                <img class="icon-button" src="@/views/agentconsole/assets/img/ic_harassment_record@2x.png" alt=""
                     @click="dialogVisibleBother = true">
              </div>
            </sweet-tooltip>
            <sweet-tooltip :content="$t('agentconsole.header.title.send.whatsapp.notification')" placement="bottom"
                           effect="light" v-if="$Model.showWhatsAppButton">
              <div id="ic_whatsapp" class="iconMargin">
                <img class="icon-button" src="@/views/agentconsole/assets/img/ic_whatsapp@2x.png" alt=""
                     @click="transToSendNotification('whatsapp');">
              </div>
            </sweet-tooltip>
            <sweet-tooltip :content="$t('agentconsole.header.title.send.email.notification')" placement="bottom"
                           effect="light" v-if="$Model.showEmailButton">
              <div id="ic_e-mail" class="iconMargin">
                <img class="icon-button" src="@/views/agentconsole/assets/img/ic_e-mail__@2x.png" alt=""
                     @click="transToSendNotification( 'email');">
              </div>
            </sweet-tooltip>
            <sweet-tooltip :content="$t('agentconsole.header.title.send.sms.notification')" placement="bottom"
                           effect="light" v-if="$Model.showSMSButton">
              <div id="ic_message" class="iconMargin">
                <img class="icon-button" src="@/views/agentconsole/assets/img/ic_message@2x.png" alt=""
                     @click="transToSendNotification( 'sms');">
              </div>
            </sweet-tooltip>
            <sweet-tooltip :content="$t('ccm.callreason.label.callreason')" placement="bottom" effect="light">
              <div id="ic_Conversation_Summarya" style="margin-right:12px" class="iconMargin">
                <img class="icon-button" src="@/views/agentconsole/assets/img/contact_reason@2x.png" alt=""
                     @click="openEditCallresonDialog">
              </div>
            </sweet-tooltip>
          </div>
        </div>
        <div style="flex-grow: 1">
          <sweet-tabs v-model="activeName" style="height: 100%">
            <sweet-tab-pane style="height: 100%" :label="$t('agentconsole.title.realtimetransfer')" name="first">
              <VoiceTransfer :callReasonList="callReasonList" :callDataBot="callDataBot" @moodChange="moodChange"
                             @sensitiveWord="sensitiveWord" @summaryChange="summaryChange" @countDown="countDown"
                             @chatBotSummaryChange="chatBotSummaryChange"></VoiceTransfer>
            </sweet-tab-pane>
            <sweet-tab-pane style="height: 100%" :label="$t('ccmessaging.chat.degree.view')" name="second">
              <PocCustomerInfo></PocCustomerInfo>
            </sweet-tab-pane>
            <sweet-tab-pane style="height: 100%" v-for="($item, $index) in $Model.urlList" :key="$index" :name="$index"
                            :label="$item.pageDescript">
              <div v-if="$item.type == '2' && $item.pageConfig" >
                <PopUpInternalPage :pageId="$item.pageConfig.id" :layoutId="$item.pageConfig.layoutId" :numInRow="$item.pageConfig.numInRow" :callData="internalPageCallData"></PopUpInternalPage>
              </div>
              <div v-else>
                <div class="frm_content"
                     v-if="$item.integrationType != 3 && (!isNaN($item.indexInt) || $item.indexInt == 'transfer' || $item.indexInt == 'custinfo')">
                  <iframe :id="'frmFlag'+$item.indexInt" :src="$item.pageUrl" sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
                          style="width: 100%; border: 0px; padding-top: 0px; display: inline"></iframe>
                </div>
                <div class="frm_content" v-if="$item.integrationType == 3">
                  <iframe :id="'frmFlag'+$item.indexInt" :src="$item.emptySrc" :name="$item.targetIndexName" sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
                          style="width: 100%;  border: 0; padding-top: 0; display: inline"></iframe>
                </div>
              </div>
            </sweet-tab-pane>
          </sweet-tabs>
        </div>
      </div>
      <div style="width: 55%; flex-grow: 0; display: flex; flex-direction: column; height: 100%">
        <!-- 智能填单 -->
        <div style="margin-top: 1rem;margin-left: 1rem;background: #ffffff;border-radius: 8px;display: flex;align-items: center;" v-show="showClickCase">
          <div class="case-div">
            <div style="display: flex; align-items: center; margin-left: 0.625rem">
              <img src="../../assets/img/ic_case@2x_new.png"
                   style="width: 0.875rem; height: 1rem; margin-right: 0.625rem" />
              <div class="case-font">{{ $t('agentconsole.agent.intelligent.fill') }}</div>
            </div>
            <div class="click-div" @click="clickCase">
              <span class="click-span">{{ $t('agentconsole.agent.intelligent.click') }}</span>
            </div>
          </div>
          <div class="timer-div">
            <span class="timer-span">{{ timeCount }}</span>
          </div>
        </div>
        <div style="display: flex; flex-grow: 1; height:100%">
          <!-- 创建工单 -->
          <div class="card-div-line" style="width: 50%; padding: 16px;" v-show="showCase" v-if="caseAuth">
            <div class="card-div" style="margin-bottom: 0; height: 100%;margin-left: 0;">
              <div class="card-div-title">
                <div class="card-div-text">{{ $t('ccm.asr.create.case') }}</div>
                <div class="card-control">
                  <img class="full-screen-icon" src="@/assets/img/full_screen.png" alt="full-screen" />
                  <i class="sweetui-icon-close8x8 close-icon" @click="closeCard('case')"></i>
                </div>
              </div>
              <div class="" style="height: 100%">
                <iframe :src="caseUrl" sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"
                        style="width: 100%; border: 0; padding-top: 0; display: inline; height: 100%" id="AICC__createCasePoc"
                        @load="onCaseIframeLoad"></iframe>
              </div>
            </div>
          </div>
          <div class="card-div-line" style="margin-left: 0;width: 50%; padding:16px;overflow-y:scroll;"
               v-show="!showCase">
            <div class="card-div" v-if="showCallContext">
              <div class="card-div-title">
                <div class="card-div-text">{{ $t('agentconsole.agent.call.context') }}</div>
                <div class="card-control">
                  <img class="full-screen-icon" src="@/assets/img/full_screen.png" alt="full-screen" />
                  <i class="sweetui-icon-close8x8 close-icon" @click="closeCard('callContext')"></i>
                </div>
              </div>
              <div class="intent-div">
                <div class="intent-title">{{ $t('ccm.audiovideoworkbench.intention') }}</div>
                <div class="intent-text">{{ intention }}</div>
              </div>
              <div class="grey-div">
                <div class="grey-div-title">ChatBot</div>
                <div class="grey-div-value">{{ chatBotSummary }}</div>
              </div>
            </div>
            <div class="card-div" v-show="showSessionMonitor">
              <div class="card-div-title">
                <div class="card-div-text">{{ $t('agentconsole.agent.session.monitor') }}</div>
                <div class="card-control">
                  <img class="full-screen-icon" src="@/assets/img/full_screen.png" alt="full-screen" />
                  <i class="sweetui-icon-close8x8 close-icon" @click="closeCard('sessionMonitor')"></i>
                </div>
              </div>
              <div class="">
                <div class="mood-div">
                  <div :title="$t('ccm.audiovideoworkbench.mood')">
                    <div class="mood-text">
                      <span class="mood-text-title">{{ $t('ccm.audiovideoworkbench.mood') }}：</span>
                      <div :class="['mood-value', { text_red: moodNegativeList.includes(mood) }]">
                        {{ mood }}
                      </div>
                      <div class="mood-value" v-if="complainList.includes(complain)" style="margin-left: 0.5rem">
                        <i class="sweetui-icon-minor"></i>
                        <span style="
                              color: #ee343f;
                              font-size: 0.875rem;
                              line-height: 1.625rem;
                              margin-left: 3px;
                            ">
                            {{ $t('agentconsole.agent.complaint.risk') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="div-agent">
                  <div class="div-agent-overview">
                    {{ $t('agentconsole.title.agent.overview') }}
                  </div>
                  <div style="display: flex; margin-top: 1.25rem">
                    <div class="text_mid_li" :title="$t('ccm.agent.label.positiveEmotionNumber')">
                      <div class="sensitive_icon color_green"></div>
                      <div class="sensitive_text">
                        {{ $t('ccm.agent.label.positiveEmotionNumber') }}：{{
                          positiveEmotionNumber
                        }}
                      </div>
                    </div>
                    <div class="text_mid_li" :title="$t('ccm.agent.label.negativeEmotionNumber')"
                         style="margin-left: 6.5rem">
                      <div class="sensitive_icon color_red"></div>
                      <div class="sensitive_text">
                        {{ $t('ccm.agent.label.negativeEmotionNumber') }}：{{
                          negativeEmotionNumber
                        }}
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 1rem; margin-bottom: 1.25rem">
                    <div class="text_mid_li" :title="$t('ccm.agent.label.sensitiveWordNumber')">
                      <div class="sensitive_icon had_img">
                        <img alt="" src="@/views/agentconsole/assets/img/ic_Prohibited_Words@2x.png"
                             style="width: 41px; max-width: none" />
                      </div>
                      <div class="sensitive_text">
                        {{ $t('ccm.agent.label.sensitiveWordNumber') }}：{{ sensitiveWordNumber }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-div" v-show="showSummary">
              <div class="card-div-title">
                <div class="card-div-text">{{ $t('agentconsole.agent.summary') }}</div>
                <div class="card-control">
                  <img class="full-screen-icon" src="@/assets/img/full_screen.png" alt="full-screen" />
                  <i class="sweetui-icon-close8x8 close-icon" @click="closeCard('summary')"></i>
                </div>
              </div>
              <div class="" style="margin-top: 1rem">
                <sweet-input v-model="summary" :rows="14" type="textarea" />
                <div style="margin-top: 1rem; text-align: right">
                  <sweet-button @click="reset">{{ $t('aicc.reset') }}</sweet-button>
                  <sweet-button type="primary" @click="submitSummary">
                    {{ $t('ccmessaging.chat.channelconfig.submit') }}
                  </sweet-button>
                </div>
              </div>
            </div>
            <div class="card-div" v-show="showAssistant"
                 style="min-height: 33.5rem; margin-bottom: 0; overflow-y: scroll;">
              <div class="card-div-title">
                <div class="card-div-text">
                  {{ $t('agentconsole.workbench.label.intelligentrecommendation') }}
                </div>
                <div class="card-control">
                  <img class="full-screen-icon" src="@/assets/img/full_screen.png" alt="full-screen" />
                  <i class="sweetui-icon-close8x8 close-icon" @click="closeCard('assistant')"></i>
                </div>
              </div>
              <div class="" style="flex-grow: 1">
                <FaqWord></FaqWord>
              </div>
            </div>
<!--            <div class="card-div" v-show="showKnowledge">
              <div class="card-div-title" style="padding-bottom: 20px">
                <div class="card-div-text">
                  {{ $t('agentconsole.agent.knowledge.recommend') }}
                </div>
                <div class="card-control">
                  <img class="full-screen-icon" src="@/assets/img/full_screen.png" alt="full-screen" />
                  <i class="sweetui-icon-close8x8 close-icon" @click="closeCard('knowledge')"></i>
                </div>
              </div>
              <div class="">
                <h2 class="sub-title">{{
                    recommend ? $t('agentconsole.agent.knowledge.product.recommend') :
                        $t('agentconsole.agent.knowledge.product.popular')
                  }}</h2>
                <ProductsCard :language="language" :recommend="recommend" />
              </div>
            </div>
            <div class="card-div" v-show="showKnowledge">
              <div class="card-div-title" style="padding-bottom: 20px">
                <div class="card-div-text">
                  {{ $t('agentconsole.agent.knowledge.recommend') }}
                </div>
                <div class="card-control">
                  <img class="full-screen-icon" src="@/assets/img/full_screen.png" alt="full-screen" />
                  <i class="sweetui-icon-close8x8 close-icon" @click="closeCard('knowledge')"></i>
                </div>
              </div>
              <div class="">
                <h2 class="sub-title">{{ $t('agentconsole.agent.knowledge.map') }}</h2>
                <MapCard :initialAddress="address" />
              </div>
            </div>-->
          </div>
          <div class="card-div-line"
               style="margin-left:0;width: 50%;padding-bottom:16px;padding-top:16px;overflow-y: scroll;border-radius:0;">
            <div class="card-div" v-show="!showUserInfo && showCaseList"
                 style="overflow-y: auto;flex-grow: 1;; flex-shrink: 0;">
              <div class="" style="width: 100%; height: 100%">
                <iframe ref="appcubeCaseList" frameBorder="No" :src="caseListUrl" class="panel-width"
                        :style="{ height: '100%' }" @load="onLoadAppcubeCaseListIframe()"></iframe>
              </div>
            </div>
            <div class="card-div" v-show="showUserInfo" style="flex-shrink: 0;flex-grow: 1;">
              <div class="card-div-title">
                <div class="card-div-text">{{ $t('agentconsole.agent.customer.info') }}</div>
                <div class="card-control">
                  <img class="full-screen-icon" src="@/assets/img/full_screen.png" alt="full-screen" />
                  <i class="sweetui-icon-close8x8 close-icon" @click="closeCard('userInfo')"></i>
                </div>
              </div>
              <div class="" style="width: 100%; height: 100%">
                <div v-if="bazhan">
                  <div style="display: flex; flex-direction: column">
                    <div style="
                        font-size: 1rem;
                        line-height: 1.5rem;
                        font-weight: 600;
                        margin-top: 0.5625rem;
                        text-align: center;
                      " v-text="$Model['callNumberName'] || '***'"></div>
                    <div style="
                        font-size: 0.75rem;
                        line-height: 1rem;
                        font-weight: 600;
                        text-align: center;
                        padding-bottom: 1.25rem;
                        border-bottom: 1px solid;
                        border-color: #f3f3f3;
                      " v-text="$Model['callNumber'] || '--'"></div>
                  </div>
                  <CustomerInfo :customerNumber="$Model['callNumber']"></CustomerInfo>
                  <ContactHistory class="contact-history" :contactCallId="callid" :eventStoreData="consoleStoreData" />
                </div>
                <iframe ref="appcubeCustomerCenter" frameBorder="No" :src="appcubeCustomerCenterUrl" class="panel-width"
                        :style="{ height: appcubeCustomerCenterShowFlag && !bazhan ? '572px' : '0' }"
                        @load="onLoadAppcubeCustomerCenterIframe()"></iframe>
                <div v-show="!appcubeCustomerCenterShowFlag && !bazhan">
                  <CallMessage @updateAcceptNo="updateAcceptNo"></CallMessage>
                  <ContactCustomerInfo></ContactCustomerInfo>
                  <ContactHistory class="contact-history" :contactCallId="callid" :eventStoreData="consoleStoreData" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="card-div-line" style="margin-left: 0; margin-top: 0.25rem">
      <div class="right-button-div" :title="$t('agentconsole.workbench.label.userinfo')" @click="showCard('userInfo')">
        <img style="background-color: #fff" v-show="showUserInfo" src="@/views/agentconsole/assets/img/user@2x.png"
             alt="" />
        <img v-show="!showUserInfo" style="opacity: 40%" src="@/views/agentconsole/assets/img/user@2x.png" alt="" />
      </div>
      <div class="right-button-div" :title="$t('ccm.audiovideoworkbench.case.list')" @click="showCard('case')">
        <img style="background-color: #fff" v-show="showCaseList" src="@/views/agentconsole/assets/img/case@2x.png"
             alt="" />
        <img v-show="!showCaseList" style="opacity: 40%" src="@/views/agentconsole/assets/img/case@2x.png" alt="" />
      </div>
      <div class="right-button-div" :title="$t('agentconsole.agent.session.monitor')"
           @click="showCard('sessionMonitor')">
        <img style="background-color: #fff" v-show="showSessionMonitor"
             src="@/views/agentconsole/assets/img/session@2x.png" alt="" />
        <img v-show="!showSessionMonitor" style="opacity: 40%" src="@/views/agentconsole/assets/img/session@2x.png"
             alt="" />
      </div>
      <div class="right-button-div" :title="$t('agentconsole.workbench.label.intelligentrecommendation')"
           @click="showCard('assistant')">
        <img style="background-color: #fff" v-show="showAssistant"
             src="@/views/agentconsole/assets/img/assistant@2x.png" alt="" />
        <img v-show="!showAssistant" style="opacity: 40%" src="@/views/agentconsole/assets/img/assistant@2x.png"
             alt="" />
      </div>
      <div class="right-button-div" :title="$t('agentconsole.agent.knowledge.recommend')"
           @click="showCard('knowledge')">
        <img style="background-color: #fff" v-show="showKnowledge"
             src="@/views/agentconsole/assets/img/knowledge@2x.png" alt="" />
        <img v-show="!showKnowledge" style="opacity: 40%" src="@/views/agentconsole/assets/img/knowledge@2x.png"
             alt="" />
      </div>
      <div class="right-button-div" :title="$t('agentconsole.agent.call.context')" @click="showCard('callContext')">
        <img style="background-color: #fff" v-show="showCallContext"
             src="@/views/agentconsole/assets/img/context@2x.png" alt="" />
        <img v-show="!showCallContext" style="opacity: 40%" src="@/views/agentconsole/assets/img/context@2x.png"
             alt="" />
      </div>
    </div>
  </div>
<!--  <div class="page-left">
    <div class="page-left-foot">
      <div class="information">
        <span v-text="$t('agentconsole.workbench.signInCallNum')"></span>
        <span class="information_style" v-text="$Model.totalTalkingCount"></span>
      </div>
      <div class="information">
        <span v-text="$t('agentconsole.workbench.talkavg')"></span>
        <span class="information_style" v-text="$Model.avgTalkingTime"></span>
      </div>
      <div class="information">
        <span v-text="$t('agentconsole.workbench.queNum')"></span>
        <span class="information_style" v-text="$Model.waitingNumRes"></span>
      </div>
    </div>
  </div>-->
  <!--选择来电原因-->
  <EditCallreason v-if="dialogVisibleCase"
                  v-model:show="dialogVisibleCase"
                  :callid="$Model.audioVideoCallId || sessionStorage.callid"></EditCallreason>
  <!--创建骚扰记录-->
  <BotherCall v-if="dialogVisibleBother"
              v-model:show="dialogVisibleBother"
              :callid="$Model.audioVideoCallId || sessionStorage.callid"
              :workno="agentId"></BotherCall>
  <!--创建工单-->
  <topthireWorkorder v-if="dialogVisibleCreateCase"
                     v-model:show="dialogVisibleCreateCase"
                     :label0="label0" :label1="label1" :label2="label2"
                     @selectWorkOrderType="onSelectWorkOrderType"></topthireWorkorder>
  <!--发送whatsapp消息-->
  <SendWhatsappDialog v-if="whatsappDialogVisible"
                      :whatsappDialogVisible="whatsappDialogVisible"
                      :audioVideoCaller="callInfo.audioVideoCaller"
                      :audioVideoCallId="callInfo.audioVideoCallId"
                      @handleWhatsappClose="handleWhatsappClose"/>

  <!--发送短信消息-->
  <SendSMSNotificationDialog v-if="SMSDialogVisible"
                             :SMSDialogVisible="SMSDialogVisible"
                             :callInfo="callInfo"
                             @handleSMSClose="handleSMSDialogClose"/>
  </body>
</template>

<script>
export default {
  name: '6006' // 音视频工作台
}
</script>
<script setup>
import {
  getauths,
  isCreateCase,
  queryAgentAIFlagById,
  queryAgentSkillsApi,
  queryCallInfoByCallId,
  queryEnabledPageUrls,
  queryEnabledVerifyIdentityConfig,
  queryParamIsShowCallData,
  textClassification,
  verifyIdentityflow
} from "@/views/agentconsole/api/audio-video-workbench";
import {
  queryCustomerCenterForUser,
  queryTenantSpaceLocale,
  queryTenantSysParamByItemIds
} from '@/views/ccmanagement/ccma-api/index.js'
import {computed, reactive, ref, onActivated, onBeforeMount, onMounted} from "vue";
import cookieUtils from '@/utils/cookie'
import CallMessage from './CallMessage.vue'
import VoiceTransfer from './VoiceTransfer.vue'
import PocCustomerInfo from './PocCustomerInfo.vue'
import PopUpInternalPage from './PopUpInternalPage.vue'
import ContactCustomerInfo from './ContactCustomerInfo.vue'
import ContactHistory from '../../components/contact-history/ContactHistory.vue'
import EditCallreason from '@/views/ccmanagement/components/edit-callreason/EditCallreason.vue'
import BotherCall from '@/views/ccmanagement/components/bother-call/BotherCall.vue'
import topthireWorkorder from '@/views/ccmanagement/pages/topthire-workorder/topthire-workorder.vue'
import SendWhatsappDialog from '@/views/ccmanagement/pages/notification/SendWhatsapppDialog.vue'
import SendSMSNotificationDialog from '@/views/ccmanagement/pages/notification/send-sms-notification-dialog/SendSMSNotificationDialog.vue'
import { ElMessageBox} from "element-plus";
import {router, moduleRoutes} from '@/router'
import {
  sendAiStatusStore,
  sendSummarySwitchStore,
  useAiSummaryStore,
  useCallIdStore
} from "@/views/agentconsole/stores";
import {useVoiceTransferStore} from "@/views/agentconsole/stores";
import {useAgentEventStore, useAgentConsoleTabStore} from "@/views/ccdesktop/stores/agentConsoleStore";
import FaqWord from "@/views/agentconsole/pages/audio-video-workbench/FaqWord.vue";
import $ from 'jquery';
import { queryTenantFeaturesForWeb } from '@/views/agentconsole/api/audio-video-workbench/index.js';
import { throttle } from "lodash";
import AiccElMessage from "@/utils/el-message";
import { queryUserCallReason } from '@/views/ccmanagement/ccma-api/index'
import ProductsCard from '@/views/agentconsole/components/knowledge-card/ProductsCard.vue'
import MapCard from '@/views/agentconsole/components/knowledge-card/MapCard.vue'

function onCaseIframeLoad() {
  const iframe = document.getElementById('AICC__createCasePoc');
  const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
  const style = iframeDocument.createElement('style');
  style.type = 'text/css';
  style.textContent = '.caseCreateV2 .ivu-form-item { width: 90% !important;margin-left:0 !important; }.ivu-col.ivu-col-flex.ivu-col-span-xs-24.ivu-col-span-sm-24.ivu-col-span-md-24.ivu-col-span-lg-24 span {margin:0!important}.xview-container{display: flex;}.case-info{padding-left:0 !important;} div[layoutcomponentname="x-default-master-template"] > .contents > .xview-container > .xview-container:not(.caseCreateV2) {flex-direction:column !important; gap: 10px;}.ivu-col.ivu-col-flex.ivu-col-span-xs-24.ivu-col-span-sm-24.ivu-col-span-md-24.ivu-col-span-lg-24 span[layoutcomponentname="label"]{margin:14px!important;}     .customization-container.xview-container-custom.xview-container { display: flex !important; flex-direction: column !important; } .caseCreateV2 { margin:0 !important; } .box_center .ivu-form-item { width: auto !important;}';
  iframeDocument.head.appendChild(style);
}

const $Scope = reactive(window.$Scope);
const $Model = reactive(window.$Model);
const sessionStorage = reactive(window.sessionStorage);
const dialogVisibleCase = ref(false);
const dialogVisibleBother = ref(false);
const dialogVisibleCreateCase = ref(false);
let label0 = '';
let label1 = '';
let label2 = '';
const agentEventStore = useAgentEventStore();
const agentConsoleTabStore = useAgentConsoleTabStore();
const callIdStore = useCallIdStore();
const voiceTransferStore = useVoiceTransferStore();
const aiSummaryStore = useAiSummaryStore()
const aiStatusStore = sendAiStatusStore()
const callid = computed(() => $Model.audioVideoCallId || sessionStorage.callid || callId);

var urlList = ref([]);
var externalWebUrlList = [];
var externalWebList = [];
let outOpenWindowList = [];
let innerOpenWindowList = [];
var webApiPageList = [];
var initEvent = true;
var agentSkills = [];
var outCallMediaType = new Map();
let callId;
var orderCallId;
let hasVerifyIdentityAuth = false;
let agentId = ref('');
const consoleStoreData = reactive({
  pageParams: {},
  dataSrc: "",
});
const whatsappDialogVisible = ref(false);
const SMSDialogVisible = ref(false);
const callInfo = reactive({}) // 对话对象
const iolpFlowVisible = ref(false);

let caseType = ''
let customerList = []
let contactId = ''
let origin = ''
const caseUrl = `${window.location.protocol}//${window.location.host}/besBaas/baas/abc/foundation/index.html#/AICCPOC__createCasePoc`
const caseListUrl = `${window.location.protocol}//${window.location.host}/besBaas/baas/abc/foundation/index.html#/AICC__caseListByPlatform`
let callReasonList = []
const showVoiceTrans = ref(false)
const showCallContext = ref(true)
const showUserInfo = ref(true)
const showKnowledge = ref(true)
const showAssistant = ref(true)
const showSessionMonitor = ref(true)
const showSummary = ref(false)
const showCase = ref(false)
const showCaseList = ref(false)
const showClickCase = ref(false)
const activeName = ref('first')
const mood = ref('--')
const intention = ref('--')
const complain = ref('')
const address = ref('')
const moodNegativeList = [
  $t('ccmessaging.chat.case.upset'),
  $t('ccmessaging.chat.case.angry'),
  $t('ccmessaging.chat.case.little.angry'),
  $t('ccmessaging.chat.case.very.angry')
]
const complainList = [
  $t('ccmessaging.chat.case.complaint.yes'),
  $t('ccmessaging.chat.case.complaint.shi')
]
const positiveEmotionNumber = ref(0)
const negativeEmotionNumber = ref(0)
const sensitiveWordNumber = ref(0)
const timeCount = ref(10)
const bazhan = ref(false)
const summary = ref('')
const chatBotSummary = ref('')
let callDataBot = ref('')
let timer = null
const summarySwitchStore = sendSummarySwitchStore()
const caseAuth = ref(false)

const cardType = ref('map')
const recommend = ref(false)

const countDown = () => {
  showClickCase.value = true
  timer = setInterval(() => {
    timeCount.value = timeCount.value - 1
    if (timeCount.value === 0) {
      clearInterval(timer)
      showClickCase.value = false
      timer = null
      timeCount.value = 10
    }
  }, 1000)
}

const clickCase = () => {
  showClickCase.value = false
  showCard('case')
}
const generateSummary = () => {
  summarySwitchStore.setSummarySwitch(true, 'summarySwitch')
  showSummary.value = true
  showKnowledge.value = false
}

const submitSummary = () => {
  ElMessage({
    message: $t('agentconsole.agent.submit.success'),
    type: 'success'
  })
}

const reset = () => {
  summary.value = ''
}

const summaryChange = (data) => {
  summary.value = data
}

const chatBotSummaryChange = (data) => {
  chatBotSummary.value = data
}

let language = window.localStorage.getItem('i18n_language')
let callReason = []

let lastIntention = null;
let lastAddress = null;

const moodChange = (data) => {
  mood.value = data.mood;
  complain.value = data.complain;

  let intentionChanged = data.intention !== lastIntention;
  let addressChanged = false;

  if (data.address) {
    if (Array.isArray(data.address)) {
      addressChanged = data.address[data.address.length - 1] !== lastAddress;
    } else {
      addressChanged = data.address !== lastAddress;
    }
  }

  // 检查哪个字段发生了变化并优先处理变化的字段
  if (intentionChanged && !addressChanged) {
    intention.value = data.intention;
    cardType.value = 'product';
    recommend.value = (/宽带/i.test(data.intention) || /broadband/i.test(data.intention))
    showKnowledge.value = true;
    lastIntention = data.intention;
  } else if (addressChanged) {
    if (Array.isArray(data.address)) {
      address.value = data.address[data.address.length - 1];
    } else {
      address.value = data.address;
    }
    cardType.value = 'map';
    showKnowledge.value = true;
    lastAddress = address.value;
  } else if (intentionChanged) {
    intention.value = data.intention;
    cardType.value = 'product';
    recommend.value = (/宽带/i.test(data.intention) || /broadband/i.test(data.intention))
    showKnowledge.value = true;
    lastIntention = data.intention;
  }

  // 如果没有变化，保持上一次的值
  if (!intentionChanged) {
    intention.value = lastIntention;
  }
  if (!addressChanged) {
    address.value = lastAddress;
  }
}

const sensitiveWord = (data) => {
  positiveEmotionNumber.value = data.positiveEmotionNumber
  negativeEmotionNumber.value = data.negativeEmotionNumber
  sensitiveWordNumber.value = data.sensitiveWordNumber
}

const closeCard = (name) => {
  if (name === 'callContext') {
    showCallContext.value = false
  }
  if (name === 'userInfo') {
    showUserInfo.value = false
  }
  if (name === 'knowledge') {
    showKnowledge.value = false
  }
  if (name === 'assistant') {
    showAssistant.value = false
  }
  if (name === 'summary') {
    showSummary.value = false
  }
  if (name === 'case') {
    showCase.value = false
    aiStatusStore.setAiStatus(false, 'autoCaseStatus')
  }
  if (name === 'caseList') {
    showCaseList.value = false
  }
  if (name === 'sessionMonitor') {
    showSessionMonitor.value = false
  }
}

const showCard = (name) => {
  if (name === 'callContext') {
    showCallContext.value = !showCallContext.value
  }
  if (name === 'userInfo') {
    showUserInfo.value = !showUserInfo.value
    showCaseList.value = !showCaseList.value
  }
  if (name === 'knowledge') {
    showKnowledge.value = !showKnowledge.value
  }
  if (name === 'assistant') {
    showAssistant.value = !showAssistant.value
  }
  if (name === 'summary') {
    showSummary.value = !showSummary.value
  }
  if (name === 'sessionMonitor') {
    showSessionMonitor.value = !showSessionMonitor.value
  }
  if (name === 'case') {
    showCase.value = !showCase.value
    aiStatusStore.setAiStatus(showCase.value, 'autoCaseStatus')
  }
  if (name === 'caseList') {
    showCaseList.value = !showCaseList.value
    showUserInfo.value = !showUserInfo.value
  }
}

const transVoiceSwitch = () => {
  showVoiceTrans.value = !showVoiceTrans.value
  console.log(showVoiceTrans.value)
}

function findLeafNodes(nodeId, data) {
  const leafNodes = []

  const node = data.find((item) => item.id === nodeId)
  if (!node) {
    return leafNodes
  }

  if (!data.some((item) => item.parentId === nodeId)) {
    leafNodes.push(node)
  } else {
    const children = data.filter((item) => item.parentId === nodeId)
    for (const child of children) {
      leafNodes.push(...findLeafNodes(child.id, data))
    }
  }

  return leafNodes
}

queryUserCallReason().then((response) => {
  const rootNodes = response.filter((item) => item.parentId === '0')
  const result = []

  for (const rootNode of rootNodes) {
    result.push(...findLeafNodes(rootNode.id, response))
  }
  result.forEach((c) => callReasonList.push({ value: c.id, display: c.name }))
})

aiSummaryStore.$subscribe((mutation, state) => {
  if (!state.sendEventName) {
    return
  }
  if (!showCase.value) {
    return
  }
  // 给appcube页面赋值
  setAppcubeData(state.data)
  aiSummaryStore.setAiSummaryData()
})

const formatDate = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

async function getTemplateModel(id) {
  let isNewModel = false
  await document.getElementById('bingoPageInitCas').contentWindow.fetch("/u-route/baas/public/view/v1.0/" + id, {
    method: "GET"
  }).then(response => response.json()).then((data) =>{
    if (data && data.resCode != "0") {
      return;
    }
    let events = data.result.events;
    events.forEach((val) => {
      if (val.name.includes('casca')) {
        isNewModel = true
      }
    })
  })
  return isNewModel
}

async function getCaseType(id) {
  let caseType = {}
  await document.getElementById('bingoPageInitCas').contentWindow.fetch("/service/AICC__Case/1.0.0/caseType/" + id, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => response.json()).then(data => {
    if (data.resCode !== "0") {
      return;
    }
    caseType = data.result.caseTypeInfo;
  });
  return caseType;
}

async function setAppcubeData(data) {
  var appCubeIframe = document.getElementById('AICC__createCasePoc')
  let pageData = appCubeIframe.contentWindow.$model.ref('page').getData()
  let appcubeInfo = pageData.caseInfo
  if (data.Summary__CST !== '' && data.Summary__CST !== undefined) {
    appcubeInfo.description = data.Summary__CST
  }
  if (data.description !== '' && data.description !== undefined) {
    appcubeInfo.description = data.description
  }
  if (data.title !== '' && data.title !== undefined) {
    appcubeInfo.title = data.title
  }
  if (appcubeInfo.dueDate === "" || appcubeInfo.dueDate === undefined || appcubeInfo.dueDate === null) {
    let now = new Date()
    let date = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000)
    appcubeInfo.dueDate = formatDate(date)
  }
  if (contactId !== '') {
    appcubeInfo.contactId = contactId
  }
  appcubeInfo.origin = 'Voice'
  if (data.type !== '' && data.type !== undefined && data.type !== '未知') {
    let currentCaseType = pageData.caseTypes.find((item) => item.label === data.type)
    if (currentCaseType != undefined) {
      let typeList = [];
      typeList.push(currentCaseType.value);
      appcubeInfo.type = typeList;
    }
    let templateFieldList = appCubeIframe.contentWindow.$model.ref('templateField').getData()
    if (templateFieldList !== undefined) {
      for (let item of templateFieldList) {
        appcubeInfo[item.name] = data[item.name]
      }
    }
    if (currentCaseType !== undefined && (caseType === '' || caseType !== appcubeInfo.type)) {
      let caseTypeInfo = appcubeInfo.type && await getCaseType(appcubeInfo.type)
      document.getElementById('bingoPageInitCas').contentWindow.fetch("/service/AICC__Case/1.0.0/caseTemplate/" + caseTypeInfo.templateId, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json()).then(async res => {
        caseType = appcubeInfo.type
        let caseTemplateInfo = res.result.caseTemplateInfo
        let isNewModel = await getTemplateModel(caseTemplateInfo.creationPage)
        let strType;
        let arrayType = pageData.caseInfo.type;
        if (arrayType instanceof Array) {
          strType = arrayType.join();
        } else {
          strType = arrayType;
        }
        let type = isNewModel ? strType : appcubeInfo.type
        let contentTemp = caseTypeInfo.contentTemplateId ? `&caseContentTemplate=${caseTypeInfo.contentTemplateId}` : '';
        let builderUrl = `${window.location.protocol}//${window.location.host}/besBaas/baas/abc/foundation/index.html#/${caseTemplateInfo.creationPage}?isNewModel=${isNewModel}&caseType=${type}`
        builderUrl += contentTemp;
        let parameterObj = appCubeIframe.contentWindow.$model.ref('parameterObj').getData() || {}
        if (parameterObj.tabKey) {
          builderUrl += `&tabKey=${parameterObj.tabKey}`
        }
        if (parameterObj.contactId) {
          let parameterURL = `&contactId=${parameterObj.contactId}`
          if (parameterObj.contactPhone) {
            parameterURL += `&contactPhone=${parameterObj.contactPhone}`
          }
          if (parameterObj.beId) {
            builderUrl += `&beId=${parameterObj.beId}`
          }
          builderUrl += parameterURL
        }
        appCubeIframe.src = builderUrl
        delete appcubeInfo.isAutoAssigned
        delete appcubeInfo.ownerType
        delete appcubeInfo.ownerId
        console.log(appcubeInfo)
        window.sessionStorage.setItem('createCaseInfo', JSON.stringify(appcubeInfo))
      })
      return
    }
  }
  appCubeIframe.contentWindow.$model.ref('page').setValue('caseInfo', appcubeInfo)
}

const handleWhatsappClose = () => {
  whatsappDialogVisible.value = false;
}

const handleSMSDialogClose = () => {
  SMSDialogVisible.value = false;
}
const internalPageCallData = reactive({})

// 订阅坐席事件状态变更事件
agentEventStore.$subscribe((mutation, state) => {
  // forCreateBother 新增的从接续发送过来的事件，控制创建骚扰记录图标专用，避免影响原有逻辑
  if (state && state.eventData && state.eventData['forCreateBother']) {
    setShowCreateBotherFlag(state, $Model)
  }
  if (state && state['eventName'] == 'endOfCall') {
    $Model.audioVideoCallId = ''
    $Model.audioVideoCaller = ''
    var clear = window.setInterval(function () {
      if ($Model.showAiTransfer) {
        clearInterval(clear);
        setTimeout(function () {
          voiceTransferStore.setTransfer("endOfCallTransfer", null, null);
        }, 1000)
      }
    }, 1000)
  }
  if (agentSkills.length === 0) {
    // 没有技能队列信息就查询,先查语音 技能队列
    queryAgentSkills($Model, {mediaTypeId: 5}, () => {
      // 再查 视频技能队列
      queryAgentSkills($Model, {mediaTypeId: 19}, () => {
        // 执行事件处理
        AICCSupporteventHandler(state);
      });
    });
  } else {
    AICCSupporteventHandler(state);
  }
})

// 订阅接续条数据变更事件
agentConsoleTabStore.$subscribe((mutation, state) => {
  if (state && state.pageParams && state.pageParams['name'] == "initPage" && state.dataSrc != "onlineChat") {
    var curCallId = state.pageParams['callid'];
    orderCallId = state.pageParams['callid'];
    // 作为callid缓存，防止endOfCall之后，callid被清理
    if (curCallId) {
      callId = curCallId;
    }
    $Model.audioVideoCallId = curCallId;
    $Model.currentRecordCallId = curCallId;
    callIdStore.setCallId(curCallId);
    window.top['Aicc$Model'] = $Model;
    agentId.value = "" + window.top['CCAgent'].agentId;
    var timer = window.setInterval(function () {
      if ($Model.showAiTransfer && state?.dataSrc === "audioAndVideo") {
        clearInterval(timer);
        setTimeout(function () {
          voiceTransferStore.setTransfer("initPageTransfer", curCallId, agentId.value);
        }, 1000)
      }
    }, 1000);

    // agentConsoleTabStore发生变化时，同步更新consoleStoreData
    consoleStoreData.pageParams = JSON.parse(JSON.stringify(state.pageParams));
    consoleStoreData.dataSrc = state.dataSrc;

    // 调用查询真实号码的接口
    queryCallInfoByCallId(curCallId, agentId.value).then(data => {
      data.callData = state.pageParams.callData;
      $Model.audioVideoCaller = data.caller;
      $Model.audioVideoCalled = data.called;
      $Model.callTypeFeature = data.callfeature
      refreshIframe(data, agentId.value, $Model);
    })
  }
  if (state?.dataSrc === "audioAndVideo" && state?.pageParams?.['name'] === "endOfCall") {
    $Model.showCreateBotherFlag = false
  }
  // 刷新AppCube客户中心页面
  sendMessageToAppcubeCustomerCenter({ data: state && state.pageParams });
  // 刷新AppCube工单列表页面
  sendMessageToAppcubeCaseList({ data: state && state.pageParams })
  intention.value = '--';
  mood.value = '--';
  complain.value = '';
  address.value = '';
})

function initWorkbeanch($scope) {
  bazhan.value = window.localStorage.getItem('bazhan')
      ? window.localStorage.getItem('bazhan')
      : false
  window.$Scope.$Params = {};

  $Model.left = {};
  $Model.right = {};
  $Model.showCustInfo = false;
  $Model.showAiTransfer = false;
  $Model.openAiTransfer = true;
  $Model.urlSelectedIndex = 0;
  initLeftItemFlag($Model);
  initRightItemFlag($Model);
  queryWaitNum($Model, $scope);
  querytalkingnum($Model, $scope);
  initPageParams($Model, $scope);
  checkSendMsgOutsideAuth($Model);
  $Model.right.customerInfo = true;

  // 实现可拖拽功能
  var AICCDragUIFunction = function (target, o) {
    var self = this;
    this._option = {
      handle: null,
      scope: window,
      start: null,
      stop: null,
    };
    this.option = $.extend(this._option, o);
    this.target = typeof target == "string" ? $(target) : $(target.container);
    this.handle = $(this.option.handle, this.target);
    this.doc = $(self.option.scope.document);
    this.endDrag = function () {
      self.doc
          .unbind("mousemove", self._mousemove)
          .unbind("mouseup", self._mouseup);
      self.doc.unbind("selectstart");
      self._mouseposx = null;
      self._mouseposy = null;
      self.target.removeClass("dragging");
      if (self.option.stop) {
        self.option.stop.call(self.target);
      }
    };
    this._mousedown = function (e) {
      if (self.option.start && self.option.start.call(self.target)) return;
      self._mouseposx = e.pageX;
      self._mouseposy = e.pageY;
      self._targetpos = self.target.position();
      self.target.addClass("dragging");
      self.doc
          .bind("mousemove", self._mousemove)
          .bind("selectstart", function () {
            return false;
          })
          .bind("mouseup", self._mouseup);
      e.preventDefault();
    };
    this._mousemove = function (e) {
      if (e.button !== 0 && !e.button) {
        return self._mouseup(e);
      }
      var offsetx = e.pageX - self._mouseposx;
      var offsety = e.pageY - self._mouseposy;
      var left = self._targetpos.left + offsetx;
      var top = self._targetpos.top + offsety;
      if (
          top < 0 ||
          e.clientY + 10 > self.option.scope.document.documentElement.clientHeight
      ) {
        return;
      }
      left = Math.max(0, Math.min(left, window.innerWidth - 63));
      top = Math.max(0, Math.min(top, window.innerHeight - 63));
      self.target.css("left", left).css("top", top);
      return e.preventDefault();
    };
    this._mouseup = function (e) {
      self.endDrag();
    };
    this.handle.css("cursor", "move").bind("mousedown", this._mousedown);
    return this;
  };
  var AICCDragUI = {
    draggable: function (target, o) {
      return new AICCDragUIFunction(target, o);
    },
  };

  var option = {
    handle: "div.ui-draggable-handle",
    stop: function () {
      if ($(this).offset().top < 0) {
        $(this).css("top", "0px");
      }
    },
  };

  AICCDragUI.draggable("#ai_transfer_drag", option);

  // 防止移动事件误触发点击事件
  let startDot = {};
  $("#ai_transfer_drag")
      .bind("mousedown", function (e) {
        startDot.x = e.screenX;
        startDot.y = e.screenY;
      })
      .bind("mouseup", function (e) {
        if (e.which == 1 && startDot.x && startDot.y) {
          // e.which == 1表示鼠标左键
          let difX = e.screenX - startDot.x;
          let difY = e.screenY - startDot.y;
          let difD = Math.sqrt(difX * difX + difY * difY);
          if (difD >= 0 && difD < 5) {
            // 偏移量在0~5之间就执行单击操作，即打开智能转写页
            openAiTransferFlag($Model);
          }
        }
      });
}

function queryWaitNum($Model, $scope) {
  let t = 2000;
  let set1 = setInterval(fn, t);
  window.audioVideoQueryWaitTask = set1;
  function fn() {
    const params = {};
    params["$callback"] = (respBody) => {
      if (respBody.retcode === "0") {
        $Model.waitingNumRes = respBody.result;
      } else {
        clearInterval(set1);
        window.audioVideoQueryWaitTask = '';
      }
      if (t === 2000) {
        clearInterval(set1);
        t = 10000;
        set1 = setInterval(fn, t);
        window.audioVideoQueryWaitTask = set1;
      }
      $scope.$apply();
    };
    const userName = window.localStorage.getItem('userName')
    const $Scope = window.$Scope ? window.$Scope : {};
    const $Model = $Scope.$Model ? $Scope.$Model : {};
    const isThird = $Model.isCCBar || $Model.isDynamics;
    if (userName && !isThird || isThird) {
      window.top["CCAgent"].waitnumbyagent(params);
    } else {
      clearInterval(set1);
      window.audioVideoQueryWaitTask = '';
    }
  }
}

function querytalkingnum($Model, $scope) {
  let t = 2000;
  let set2 = setInterval(fnc, t);
  window.audioVideoQueryTalkingTask = set2;
  function fnc() {
    const params = {};
    params["$callback"] = (respBody) => {
      if (
          respBody.retcode === "0" &&
          respBody.result &&
          respBody.result.talktime > 0
      ) {
        $Model.totalTalkingCount =
            respBody.result.answernums + respBody.result.calloutnums;
        $Model.avgTalkingTime = Math.ceil(
            respBody.result.talktime / $Model.totalTalkingCount
        );
      }
      if (respBody.retcode !== "0") {
        clearInterval(set2);
        window.audioVideoQueryTalkingTask = '';
      }
      if (t === 2000) {
        clearInterval(set2);
        t = 30000;
        set2 = setInterval(fnc, t);
        window.audioVideoQueryTalkingTask = set2;
      }
      $scope.$apply();
    };
    const userName = window.localStorage.getItem('userName')
    const $Scope = window.$Scope ? window.$Scope : {};
    const $Model = $Scope.$Model ? $Scope.$Model : {};
    const isThird = $Model.isCCBar || $Model.isDynamics;
    if (userName && !isThird || isThird) {
      window.top["CCAgent"].queryAgentStatistics(params);
    } else {
      clearInterval(set2);
      window.audioVideoQueryTalkingTask = '';
    }
  }
}

function refreshIframe(data, agentId, $Model) {
  var custId = "";
  if (data.callData) {
    data.callData.replace(/[\\]/g, '')
    let tempString = data.callData.replaceAll(':"{"', ':{"');
    tempString = tempString.replaceAll('"}",', '"},');
    let callData = JSON.parse(tempString);
    if (callData.iSales) {
      custId = callData.iSales.custId;
    }
  }
  //是否已加载客户信息弹屏页
  var hasCuntInfoHtml = false;
  for (let i = 0; i < $Model.urlList.length; i++) {
    if ($Model.urlList[i].indexInt == "custinfo") {
      hasCuntInfoHtml = true;
      break;
    }
  }
  //选中的弹屏页
  var index_selected;
  for (let i = 0; i < $Model.urlList.length; i++) {
    var arg = 'frmFlag' + $Model.urlList[i].indexInt;
    if ($Model.left[arg]) {
      index_selected = i;
      break;
    }
  }
  //删除的弹屏页
  var index_delete;
  for (let i = 0; i < $Model.urlList.length; i++) {
    //不需要展示客户信息||没有随路数据，则删除客户信息弹屏页
    if ((!$Model.showCustInfo || custId == "") && $Model.urlList[i].indexInt == "custinfo") {
      index_delete = i;
      $Model.urlList.splice(i, 1);
      break;
    }
  }
  //需要展示客户信息 && 有随路数据 && 没有客户信息弹屏页，则增加弹屏页
  if ($Model.showCustInfo && custId != "" && !hasCuntInfoHtml) {
    $Model.urlList.push({
      pageUrl: "/service-cloud/page/custinfo.html",
      pageDescript: $t("agentconsole.custinfo.title.custinfo"),
      "indexInt": "custinfo"
    });
    $Model.left['frmFlagcustinfo'] = false;
  }
  let postInnerIframeIndex = 0;
  for (let i = 0; i < $Model.urlList.length; i++) {
    // 如果集成方式是web API 就跳过(下面的逻辑是url参数)
    if ($Model.urlList[i]['integrationType'] === 2) {
      continue;
    }
    if ($Model.urlList[i].indexInt != "transfer") {
      let url = $Model.urlList[i].pageUrl.split("?")[0];
      if ($Model.urlList[i].indexInt == "custinfo") {  //泰国easybuy
        url += '?callerNum=' + encodeURIComponent(data.caller);
        url += '&calledNum=' + encodeURIComponent(data.called);
        url += '&callid=' + encodeURIComponent(data.callid);
        url += '&mediaType=' + encodeURIComponent(data.mediaAbility);
        url += '&callType=' + encodeURIComponent(data.callType);
        url += '&workNo=' + encodeURIComponent(agentId);
        url += '&origin=' + encodeURIComponent(location.origin);
        url += '&callData=' + encodeURIComponent(data.callData);
      } else if ($Model.urlList[i].type == "1" || $Model.urlList[i].type == "3") {  //外部页面/appcube集成页面
        let pageUrlParamNameList = [];
        if ($Model.urlList[i].pageUrlParam && $Model.urlList[i].pageUrlParam.length > 0) {  //使用参数配置能力
          let confParamString = "";
          for (let pageUrlParamIndex = 0; pageUrlParamIndex < $Model.urlList[i].pageUrlParam.length; pageUrlParamIndex++) {
            let pageUrlParamSingle = $Model.urlList[i].pageUrlParam[pageUrlParamIndex];
            let paramValue;
            if (pageUrlParamSingle.relSysParamType == "1") {  //呼叫数据
              if (pageUrlParamSingle.relSysParamName == "callerNum") {
                paramValue = data.caller;
              }
              if (pageUrlParamSingle.relSysParamName == "calledNum") {
                paramValue = data.called;
              }
              if (pageUrlParamSingle.relSysParamName == "callid") {
                paramValue = data.callid;
              }
              if (pageUrlParamSingle.relSysParamName == "mediaType") {
                paramValue = data.mediaAbility;
              }
              if (pageUrlParamSingle.relSysParamName == "callType") {
                paramValue = data.callType;
              }
              if (pageUrlParamSingle.relSysParamName == "workNo") {
                paramValue = agentId;
              }
            } else if (pageUrlParamSingle.relSysParamType == "2") {  //随路数据
              let paramRelKey = pageUrlParamSingle.relSysParamName;
              if (data.callData && paramRelKey) {
                data.callData.replace(/[\\]/g, '');
                let tempString = data.callData.replaceAll(':"{"', ':{"');
                tempString = tempString.replaceAll('"}",', '"},');
                let $callData = JSON.parse(tempString);
                paramValue = getParamValue($callData, paramRelKey);
              }
              if (paramValue != null && paramValue == "") {
                paramValue = "undefined";
              }
            }
            pageUrlParamNameList.push(pageUrlParamSingle.urlParamName);
            if (pageUrlParamIndex == 0) {
              confParamString += '?' + pageUrlParamSingle.urlParamName + '=' + encodeURIComponent(paramValue);
            } else {
              confParamString += '&' + pageUrlParamSingle.urlParamName + '=' + encodeURIComponent(paramValue);
            }
          }
          if (confParamString != "") {
            url += confParamString + '&origin=' + encodeURIComponent(location.origin);
          } else {
            url += confParamString + '?origin=' + encodeURIComponent(location.origin);
          }
        } else {
          url += '?callerNum=' + encodeURIComponent(data.caller);
          url += '&calledNum=' + encodeURIComponent(data.called);
          url += '&callid=' + encodeURIComponent(data.callid);
          url += '&mediaType=' + encodeURIComponent(data.mediaAbility);
          url += '&callType=' + encodeURIComponent(data.callType);
          url += '&workNo=' + encodeURIComponent(agentId);
          url += '&origin=' + encodeURIComponent(location.origin);
        }
        if (custId != "" && pageUrlParamNameList.indexOf("custId") < 0) {
          url += '&custId=' + encodeURIComponent(custId);
        }
      } else if ($Model.urlList[i].type == "2") {  //内部页面
        internalPageCallData.callerNum=encodeURIComponent(data.caller);
        internalPageCallData.calledNum=encodeURIComponent(data.called);
        internalPageCallData.callId=encodeURIComponent(data.callid);
        internalPageCallData.mediaType=encodeURIComponent(data.mediaAbility);
        internalPageCallData.callType=encodeURIComponent(data.callType);
        internalPageCallData.workNo=encodeURIComponent(agentId);
        internalPageCallData.origin=encodeURIComponent(location.origin);
      }

      $Model.urlList[i].pageUrl = url + ($Model.urlList[i].pageUrlPointString ? $Model.urlList[i].pageUrlPointString : "");

      // 收集参数
      if ($Model.urlList[i].integrationType == 3) {
        let urlItem = url.split("?");
        innerOpenWindowList[postInnerIframeIndex]["url"] = urlItem[0];
        innerOpenWindowList[postInnerIframeIndex]["messageData"]["eventData"] = urlItem.length > 1 ? urlItem[1] : "";
        postInnerIframeIndex++;
      }
    }
  }
  let outWindowIndex = 0;
  for (let i = 0; i < externalWebUrlList.length; i++) {
    let url = externalWebUrlList[i].pageUrl.split("?")[0];
    if (externalWebUrlList[i].type == "1" || externalWebUrlList[i].type == "3") {  //外部页面
      let pageUrlParamNameList = [];
      if (externalWebUrlList[i].pageUrlParam && externalWebUrlList[i].pageUrlParam.length > 0) {  //使用参数配置能力
        let confParamString = "";
        for (let pageUrlParamIndex = 0; pageUrlParamIndex < externalWebUrlList[i].pageUrlParam.length; pageUrlParamIndex++) {
          let pageUrlParamSingle = externalWebUrlList[i].pageUrlParam[pageUrlParamIndex];
          let paramValue;
          if (pageUrlParamSingle.relSysParamType == "1") {  //呼叫数据
            if (pageUrlParamSingle.relSysParamName == "callerNum") {
              paramValue = data.caller;
            }
            if (pageUrlParamSingle.relSysParamName == "calledNum") {
              paramValue = data.called;
            }
            if (pageUrlParamSingle.relSysParamName == "callid") {
              paramValue = data.callid;
            }
            if (pageUrlParamSingle.relSysParamName == "mediaType") {
              paramValue = data.mediaAbility;
            }
            if (pageUrlParamSingle.relSysParamName == "callType") {
              paramValue = data.callType;
            }
            if (pageUrlParamSingle.relSysParamName == "workNo") {
              paramValue = agentId;
            }
          } else if (pageUrlParamSingle.relSysParamType == "2") {  //随路数据
            let paramRelKey = pageUrlParamSingle.relSysParamName;
            if (data.callData && paramRelKey) {
              data.callData.replace(/[\\]/g, '');
              let tempString = data.callData.replaceAll(':"{"', ':{"');
              tempString = tempString.replaceAll('"}",', '"},');
              let $callData = JSON.parse(tempString);
              paramValue = getParamValue($callData, paramRelKey);
            }
            if (paramValue != null && paramValue == "") {
              paramValue = "undefined";
            }
          }
          pageUrlParamNameList.push(pageUrlParamSingle.urlParamName);
          if (pageUrlParamIndex == 0) {
            confParamString += '?' + pageUrlParamSingle.urlParamName + '=' + encodeURIComponent(paramValue);
          } else {
            confParamString += '&' + pageUrlParamSingle.urlParamName + '=' + encodeURIComponent(paramValue);
          }
        }
        if (confParamString != "") {
          url += confParamString + '&origin=' + encodeURIComponent(location.origin);
        } else {
          url += confParamString + '?origin=' + encodeURIComponent(location.origin);
        }
      } else {
        url += '?callerNum=' + encodeURIComponent(data.caller);
        url += '&calledNum=' + encodeURIComponent(data.called);
        url += '&callid=' + encodeURIComponent(data.callid);
        url += '&mediaType=' + encodeURIComponent(data.mediaAbility);
        url += '&callType=' + encodeURIComponent(data.callType);
        url += '&workNo=' + encodeURIComponent(agentId);
        url += '&origin=' + encodeURIComponent(location.origin);
      }
      if (custId != "" && pageUrlParamNameList.indexOf("custId") < 0) {
        url += '&custId=' + encodeURIComponent(custId);
      }
    }

    externalWebUrlList[i].pageUrl = url + (externalWebUrlList[i].pageUrlPointString ? externalWebUrlList[i].pageUrlPointString : "");

    // 独立 弹屏，post传参数的方式
    if (externalWebUrlList[i].openMode == 1 && externalWebUrlList[i].integrationType == 3) {
      let dataUrl = url.split("?");
      outOpenWindowList[outWindowIndex]["postDomain"] = dataUrl[0];
      outOpenWindowList[outWindowIndex]["messageData"]["eventData"] = dataUrl[1];
      outWindowIndex++;
    }

  }
  // 独立弹屏，URL参数方式
  for (let i = 0; i < externalWebUrlList.length; i++) {
    if (externalWebList && externalWebList.length > 0) {
      for (let j = 0; j < externalWebList.length; j++) {
        if (externalWebList[j].id == externalWebUrlList[i].id) {
          if (externalWebList[j].status == "0") {
            externalWebList[j].externalWeb.location = externalWebUrlList[i].pageUrl;
          } else {
            var externalWeb = window.open(externalWebUrlList[i].pageUrl, "audioAndVideoExternalWeb" + j);
            externalWebList[j].externalWeb = externalWeb;
            externalWebList[j].status = "0";
            externalWebList[j].loop = setInterval(function (j, webObj) {
              if (webObj.closed && externalWebList[j].status == '0') {
                externalWebList[j].status = "1";
                clearInterval(externalWebList[j].loop);
              }
            }, 1000, j, externalWebList[j].externalWeb);
          }
        }
      }
    }
  }

  // 独立 弹屏，post传参数的方式
  let outOpenWindowListLen = outOpenWindowList.length;
  for (let i = 0; i < outOpenWindowListLen; i++) {
    let tempForm = document.createElement("form");
    tempForm.id = "tempForm" + i;
    tempForm.method = "post";
    tempForm.action = outOpenWindowList[i]["postDomain"];
    tempForm.target = outOpenWindowList[i]["target"];
    createFormItem(outOpenWindowList[i]["messageData"]["eventData"].split("&"), tempForm);
    document.body.appendChild(tempForm);
    let postOpenWindow = window.open("about:blank", outOpenWindowList[i]["target"]);
    tempForm.submit();
    outOpenWindowList[i]["otherWindowObject"] = postOpenWindow;
    document.body.removeChild(tempForm);
  }
  //删掉的弹屏页是选中的弹屏页，则选中第一个弹屏页
  if (index_selected && index_delete && index_selected == index_delete) {
    var timer = setInterval(function () {
      if ($(".floatCon li") && $(".floatCon li").length > 0) {
        window.$(".floatCon li")[0].click();
        clearInterval(timer);
      }
    }, 20)
  }
  //iframe post方式刷新参数
  let innerOpenWindowListSize = innerOpenWindowList.length;
  if (innerOpenWindowListSize && innerOpenWindowListSize > 0) {
    let refreshInnerOpenTimer = setInterval(function () {
          let iframeDiv = $(".frm_content iframe");
          if (iframeDiv && iframeDiv.length > 0) {
            for (let i = 0; i < innerOpenWindowList.length; i++) {
              let tempForm = document.createElement("form");
              tempForm.id = "tempForm" + innerOpenWindowList[i]["targetName"];
              tempForm.method = "post";
              tempForm.action = innerOpenWindowList[i]["url"];
              tempForm.target = innerOpenWindowList[i]["targetName"];
              createFormItem(innerOpenWindowList[i]["messageData"]["eventData"].split("&"), tempForm);
              document.body.appendChild(tempForm);
              tempForm.submit();
              document.body.removeChild(tempForm);
            }
            clearInterval(refreshInnerOpenTimer);
          }
        }, 200
    )
  }
}

function initLeftItemFlag($Model) {
  for (let key in $Model.left) {
    $Model.left[key] = false;
  }
}

function initRightItemFlag($Model) {
  $Model.right.recommend = false;
  $Model.right.customerInfo = false;
  $Model.right.voiceTransfer = false;
}

function showItem(e, className, $Model, model, arg, index) {
  var showInit;
  if (e) {
    $(e.$Target).addClass(className).siblings().removeClass(className);
  }

  if (model == "left") {
    if (!isNaN(arg) || arg == "transfer" || arg == "custinfo") {
      showInit = "init" + arg;
      arg = "frmFlag" + arg;
    }
    $Model.urlSelectedIndex = index;
    initLeftItemFlag($Model);
    if (arg) {
      $Model[showInit] = true;
      $Model.left[arg] = true;
    }
  } else {
    initRightItemFlag($Model);
    if (arg) {
      $Model.right[arg] = true;
    }
  }
}

function initPageParams($Model, $scope) {
  if ($.isEmptyObject(window.$Scope.$Params) && GLOBAL_CURRENT_DEALCALLID != '') {
    queryCallInfoByCallId(GLOBAL_CURRENT_DEALCALLID, window.top['CCAgent'].agentId).then(data => {
      $scope.$Params = {};
      $scope.$Params.caller = data.caller;
      $scope.$Params.called = data.called;
      $scope.$Params.callid = data.callid;
      $scope.$Params.mediaAbility = data.mediaAbility;
      $scope.$Params.callType = data.callType;
      queryPageUrl($Model, $scope);
    })
  } else {
    queryPageUrl($Model, $scope);
  }
}

function queryPageUrl($Model, $scope) {
  queryEnabledPageUrls().then(data => {
    var windowIndex = 0;
    let resultData = [];
    for (let i = 0; i < data.length; i++) {
      $Model.left["frmFlag" + data[i].indexInt] = false;
      if (data[i].pageConfig) {
        data[i].pageUrl = "/service-cloud/page/custominfo.html";
      } else {
        if (data[i].pageUrl.includes("?")) {
          data[i].pageUrlQueryString = data[i].pageUrl.substring(data[i].pageUrl.indexOf("?") + 1);
        }
        // appcube集成页面
        if (data[i].type == "3" && data[i].pageUrl.includes("#")) {
          data[i].pageUrlPointString = data[i].pageUrl.substring(data[i].pageUrl.indexOf("#"));
          data[i].pageUrl = data[i].pageUrl.substring(0, data[i].pageUrl.indexOf("#"));
        }
      }

      if (data[i].type == "1" || data[i].type == "3") {
        //外部页面
        if (data[i].pageUrlParam && data[i].pageUrlParam.length > 0) {
          //使用参数配置能力
          let confParamString = "?origin=" + encodeURIComponent(location.origin);
          for (
              let pageUrlParamIndex = 0;
              pageUrlParamIndex < data[i].pageUrlParam.length;
              pageUrlParamIndex++
          ) {
            let pageUrlParamSingle =
                data[i].pageUrlParam[pageUrlParamIndex];
            let paramValue;
            if (pageUrlParamSingle.relSysParamType == "1") {
              //呼叫数据
              if (pageUrlParamSingle.relSysParamName == "callerNum") {
                paramValue = $scope.$Params.caller;
              }
              if (pageUrlParamSingle.relSysParamName == "calledNum") {
                paramValue = $scope.$Params.called;
              }
              if (pageUrlParamSingle.relSysParamName == "callid") {
                paramValue = $scope.$Params.callid;
              }
              if (pageUrlParamSingle.relSysParamName == "mediaType") {
                paramValue = $scope.$Params.mediaAbility;
              }
              if (pageUrlParamSingle.relSysParamName == "callType") {
                paramValue = $scope.$Params.callType;
              }
              if (pageUrlParamSingle.relSysParamName == "workNo") {
                paramValue = window.top["CCAgent"].agentId;
              }
            } else if (pageUrlParamSingle.relSysParamType == "2") {
              //随路数据
              paramValue = "undefined";
            }

            confParamString += "&" + pageUrlParamSingle.urlParamName + "=" + encodeURIComponent(paramValue);
          }
          data[i].pageUrl += confParamString;
        } else {
          data[i].pageUrl +=
              "?callerNum=" + encodeURIComponent($scope.$Params.caller);
          data[i].pageUrl +=
              "&calledNum=" + encodeURIComponent($scope.$Params.called);
          data[i].pageUrl +=
              "&callid=" + encodeURIComponent($scope.$Params.callid);
          data[i].pageUrl +=
              "&mediaType=" + encodeURIComponent($scope.$Params.mediaAbility);
          data[i].pageUrl +=
              "&callType=" + encodeURIComponent($scope.$Params.callType);
          data[i].pageUrl +=
              "&workNo=" + encodeURIComponent(window.top["CCAgent"].agentId);
          data[i].pageUrl += "&origin=" + encodeURIComponent(location.origin);
        }
        data[i].pageUrl += (data[i].pageUrlPointString ? data[i].pageUrlPointString : "");
      }
      if (data[i].openMode == 1 && data[i].integrationType == 1) {
        // 独立打开
        externalWebUrlList.push(data[i]);
        var externalWeb = window.open(
            data[i].pageUrl,
            "audioAndVideoExternalWeb" + windowIndex
        );
        var externalWebModel = {
          id: data[i].id,
          externalWeb: externalWeb,
          status: "0",
          loop: null,
        };
        externalWebList.push(externalWebModel);
        externalWebList[windowIndex].loop = setInterval(
            function (windowIndex, webObj) {
              if (
                  webObj.closed &&
                  externalWebList[windowIndex].status == "0"
              ) {
                externalWebList[windowIndex].status = "1";
                clearInterval(externalWebList[windowIndex].loop);
              }
            },
            1000,
            windowIndex,
            externalWebList[windowIndex].externalWeb
        );
        windowIndex++;
      }
      if (data[i].openMode == 1 && data[i].integrationType == 3) {
        // 独立打开 post方式
        let tempForm = document.createElement("form");
        tempForm.id = "tempForm" + i;
        tempForm.method = "post";
        let dateUrl = data[i].pageUrl.split("?");
        tempForm.action = dateUrl[0];
        tempForm.target = "openWindow" + i;
        if (dateUrl && dateUrl.length > 1) {
          let itemArr = dateUrl[1].split("&");
          createFormItem(itemArr, tempForm);
        }
        document.body.appendChild(tempForm);
        let postOpenWindow = window.open("about:blank", "openWindow" + i);
        tempForm.submit();
        let outOpenModel = {
          target: 'openWindow' + i,
          times: 0,
          postDomain: dateUrl[0],
          messageData: {
            eventName: "ServiceCloudCallEvent",
            eventData: dateUrl.length > 1 ? dateUrl[1] : ""
          },
          otherWindowObject: postOpenWindow
        }
        outOpenWindowList.push(outOpenModel);
        document.body.removeChild(tempForm);
        externalWebUrlList.push(data[i]);
      }
      if (data[i].type == "2") {  //内部页面
        internalPageCallData.callerNum = encodeURIComponent($scope.$Params.caller);
        internalPageCallData.calledNum = encodeURIComponent($scope.$Params.called);
        internalPageCallData.callId = encodeURIComponent($scope.$Params.callid);
        internalPageCallData.mediaType = encodeURIComponent($scope.$Params.mediaAbility);
        internalPageCallData.callType = encodeURIComponent($scope.$Params.callType);
        internalPageCallData.workNo = encodeURIComponent(window.top['CCAgent'].agentId);
        internalPageCallData.origin = encodeURIComponent(location.origin);
      }
      if (!((data[i].type == "1" || data[i].type == "3") && data[i].openMode == 1)) {
        // post传参方式的iframe
        if (data[i].integrationType == 3) {
          let iframeWindowName = "innerOpenWindow" + innerOpenWindowList.length;
          let urlItem = data[i].pageUrl.split("?");
          let target = {
            targetName: iframeWindowName,
            url: urlItem[0],
            times: 0,
            messageData: {
              eventName: "ServiceCloudCallEvent",
              eventData: urlItem.length > 1 ? urlItem[1] : ""
            }
          }
          data[i].targetIndexName = iframeWindowName;
          data[i].emptySrc = "";
          innerOpenWindowList.push(target);
        }
        resultData.push(data[i]);
      }
    }

    $Model.urlList = resultData;
    if ($Model.urlList.length > 0) {
      let urlData = $Model.urlList[0]
      showItem(null, null, $Model, 'left', urlData.indexInt, $Model.urlSelectedIndex)
    }
    let innerOpenWindowListSize = innerOpenWindowList.length;
    if (innerOpenWindowListSize && innerOpenWindowListSize > 0) {
      let innerOpenTimer = setInterval(function () {
            let iframeDiv = $(".frm_content iframe");
            if (iframeDiv && iframeDiv.length > 0) {
              for (let i = 0; i < innerOpenWindowList.length; i++) {
                let tempForm = document.createElement("form");
                tempForm.id = "tempForm" + innerOpenWindowList[i]["targetName"];
                tempForm.method = "post";
                tempForm.action = innerOpenWindowList[i]["url"];
                tempForm.target = innerOpenWindowList[i]["targetName"];
                createFormItem(innerOpenWindowList[i]["messageData"]["eventData"].split("&"), tempForm);
                document.body.appendChild(tempForm);
                tempForm.submit();
                document.body.removeChild(tempForm);
              }
              clearInterval(innerOpenTimer);
            }
          }, 200
      )
    }
    queryTenantFeatureAiNum($Model, $scope, resultData);
  })
}

function showLeftModel($Model, $scope) {
  if ($scope.$Params.callType == '5') {
    $Model.showLeft = !$Model.showLeft
  }
}

function queryTenantFeatureAiNum($Model, $scope, list) {
  queryAgentAIFlagById().then(data => {
    $Model.urlList = list;
    if (data && data[0] && data[0].AIRecognition) {
      $Model.recommend = data[0].nlpFlag == "1";
      $Model.showAiTransfer = true;
      $("#ai_transfer_open").addClass("box_on_top");
      $("#ai_transfer_drag").addClass("box_on_top");
      $("#ai_transfer_open").removeClass("box_on_bottom");
      $("#ai_transfer_drag").removeClass("box_on_bottom");
      $Model.left['frmFlagtransfer'] = false;
    }
    // 获取集成方式是2 Wab API 的页面到webApiPageList
    getWebApiPage(list);
    querySystemParam($Model, $scope);
  })
}

/**
 * 获取集成方式位web api 页面
 */
function getWebApiPage(list) {
  for (let i = 0; i < list.length; i++) {
    if (list[i]['integrationType'] === 2) {
      // 获取Web api的数组下标和页面
      webApiPageList.push({index: i, page: list[i]});
    }
  }
}

function querySystemParam($Model, $scope) {
  queryParamIsShowCallData().then(data => {
    if (data && data.paraValue == "1") {
      $Model.showCustInfo = true;
    }
    var timer = setInterval(function () {
      if ($(".floatCon li") && $(".floatCon li").length > 0) {
        for (let i = webApiPageList.length - 1; i >= 0; i--) {
          window.$(".floatCon li")[webApiPageList[i].index].click();
        }
        // 如果webApiPageList的第一个页面不是所有内嵌页面的第一个，那就打开第一个页面
        if (!webApiPageList[0] || webApiPageList[0].index !== 0) {
          window.$(".floatCon li")[0].click();
        }
        clearInterval(timer);
      }
    }, 20);
    getWebApiElement();
  })
}

/**
 * 循环getElementById获取
 */
function getWebApiElement() {
  for (let i = 0; i < webApiPageList.length; i++) {
    let interval = setInterval(() => {
      let receiver = document.getElementById(
          "frmFlag" + webApiPageList[i].page.indexInt
      );
      if (receiver) {
        webApiPageList[i].receiver = receiver;
        // 获取到Element就执行init事件
        if (initEvent) {
          webApiInitEvent();
        }
        clearInterval(interval);
      }
    }, 100);
  }
}

function getParamValue(data, paramRelKey) {
  // 解析层级关系，如system.caller这种
  if (paramRelKey && paramRelKey.indexOf(".") > -1) {
    let keyArr = paramRelKey.split(".");
    let keyArrLen = keyArr.length;
    let tempJson = data;
    for (let i = 0; i < keyArrLen; i++) {
      let jsonString = getParamValue(tempJson, keyArr[i]);
      if (i === keyArrLen - 1) {
        return jsonString;
      }
      if (jsonString !== "" && jsonString) {
        tempJson = JSON.parse(jsonString);
      }
    }
  }

  let value = "";
  for (let key in data) {
    if (key === paramRelKey) {
      if (data[key] instanceof Object) {
        value = JSON.stringify(data[key]);
      } else {
        value = data[key];
      }
    }
    if (value !== "") {
      break;
    } else {
      if (data[key] instanceof Object) {
        value = getParamValue(data[key], paramRelKey);
        if (value != "") {
          break;
        }
      }
    }
  }
  return value;
}

function openAiTransferFlag($Model) {
  $Model.openAiTransfer = true;
}

/**
 * 向集成Web API的页面发送postMessage
 * @param eventData
 * @param event
 */
function sendPostMessage(eventData, event) {
  // 没有打开音视频工作台的场景，来电自动打开时不要执行Init事件
  if (event !== 'Init') {
    initEvent = false;
  }
  let subMediaTypeName = outCallMediaType.get(eventData.calledNum);
  if ((!subMediaTypeName || subMediaTypeName === '') && eventData.callskill && eventData.callskillid) {
    let agentSkill = agentSkills.find((item, index) => {
      return item.skillId == eventData.callskillid && item.name == eventData.callskill;
    });
    if (agentSkill && agentSkill.mediaTypeId) {
      if (agentSkill.mediaTypeId == 5) {
        subMediaTypeName = 'audio';
      } else if (agentSkill.mediaTypeId == 19) {
        subMediaTypeName = 'video';
      }
    } else {
      subMediaTypeName = eventData.mediaType;
    }
  }
  if (event === 'Release') {
    outCallHandler(eventData, 'Release');
  }
  let $calldata = {};
  if (eventData && eventData.calldata) {
    eventData.calldata = eventData.calldata.replace(/[\\]/g, '');
    if (eventData.calldata && eventData.calldata != undefined && eventData.calldata != null) {
      let tempString = eventData.calldata.replaceAll(':"{"', ':{"');
      tempString = tempString.replaceAll('"}",', '"},');
      $calldata = JSON.parse(tempString);
    } else {
      $calldata = JSON.parse(eventData.calldata)
    }
  }
  let $eventData = {
    callerNum: eventData.callerNum,//主叫号码
    calledNum: eventData.calledNum,//被叫号码
    callid: eventData.callid,//会话id
    mediaType: subMediaTypeName,//媒体类型，枚举值：audio, video, wechat, email, whatsapp, web, sms
    callType: eventData.callType,//0呼入、1呼出
    workNo: eventData.workNo,//当前坐席工号 （初始化事件，只有坐席工工号）
    calldata: $calldata,//随路数据
    verifyResult: eventData.result //验证结果
  };
  for (let i = 0; i < webApiPageList.length; i++) {
    // 设置定时执行，防止页面还没加载完时就有事件来(特指没有打开音视频工作台，来电自动打开时的场景)
    let interval = setInterval(() => {
      if (webApiPageList[i].receiver) {
        // 发送postMessage
        webApiPageList[i].receiver.contentWindow?.postMessage({
          eventData: $eventData,
          eventName: event
        }, webApiPageList[i].page.pageUrl);
        clearInterval(interval)
      }
    }, 100)
  }
}

/**
 * web API init事件
 */
function webApiInitEvent() {
  let eventData = {
    callerNum: null, //主叫号码
    calledNum: null, //被叫号码
    callid: null, //会话id
    mediaType: null, //媒体类型，枚举值：audio, video, wechat, email, whatsapp, web, sms
    callType: null, //0呼入、1呼出
    workNo: window.top["CCAgent"].agentId, //当前坐席工号 （初始化事件，只有坐席工工号）
    calldata: null, //随路数据
    verifyResult: null //验证结果数据
  };
  sendPostMessage(eventData, "Init");
}

/**
 * AICC Support 事件处理
 * @param e 事件
 */
function AICCSupporteventHandler(e) {
  if (e.eventName) {
    switch (e.eventName) {
      case 'Ringing':
        sendPostMessage(e.eventData, 'Ringing');
        break;
      case 'Talking':
        sendPostMessage(e.eventData, 'Talking');
        break;
      case 'Release':
        sendPostMessage(e.eventData, 'Release');
        break;
      case 'IvrReturn':
        sendPostMessage(e.eventData, 'IvrReturn');
        break;
      case 'OutCall':
        outCallHandler(e.eventData, 'OutCall');
        break;
      default:
        console.log(e.eventName)
    }
  }
}

/**
 * 外呼事件处理
 * @param eventData 事件参数
 * @param event 事件
 */
function outCallHandler(eventData, event) {
  if (event === "OutCall") {
    // 记录外呼参数
    outCallMediaType.set(eventData.calledNum, eventData.mediaType);
  }
  if (event === "Release") {
    // 删除外呼参数
    outCallMediaType.delete(eventData.calledNum);
  }
}

/**
 * 获取多媒体技能队列列表
 */
function queryAgentSkills($Model, params, callback) {
  queryAgentSkillsApi(params).then(data => {
    if (data.message == "success" && data.result) {
      agentSkills = agentSkills.concat(data.result);
    }
    callback();
  })
}

window.addEventListener("message", (e) => {
  e = e || window.event;
  var data;
  try {
    data = JSON.parse(e.data);
  } catch (err) {
    data = e.data;
  }
  var operation = data.operation;
  if (undefined == operation) {
    return;
  }

  if ("On_Tab_Show" == operation) {
    setTimeout(() => {
      // 刷新水印
      $("div#aaaWmDivId").remove();
    }, 50);
  }
});

function createFormItem(itemArr, tempForm) {
  if (itemArr && itemArr.length > 0) {
    let arrLen = itemArr.length;
    for (let j = 0; j < arrLen; j++) {
      let hideInput = document.createElement("input");
      hideInput.type = "hidden";
      hideInput.name = itemArr[j].split("=")[0];
      hideInput.value = itemArr[j].split("=")[1];
      tempForm.appendChild(hideInput);
    }
  }
}

// 查询是否有客户接触中外发通知的权限
function checkSendMsgOutsideAuth() {
  $Model.showSMSButton = false
  $Model.showEmailButton = false
  $Model.showWhatsAppButton = false
  getauths({}).then(data => {
    if (data) {
      if (jQuery.inArray("80031001014008", data["permCodes"]) != -1) {
        $Model.showSMSButton = true;
      }
      if (jQuery.inArray("80031001014009", data["permCodes"]) != -1) {
        $Model.showWhatsAppButton = true;
      }
      if (jQuery.inArray("80031001014010", data["permCodes"]) != -1) {
        $Model.showEmailButton = true;
      }
      if (jQuery.inArray("88001001004013", data["permCodes"]) != -1) {
        hasVerifyIdentityAuth = true;
        checkVerifyIdentityAuth($Model);
      }
      if (jQuery.inArray("88001001003041", data["permCodes"]) != -1) {
        $Model.hasIolpAuth = true;
      }
      if (jQuery.inArray('90011001', data['permCodes']) != -1) {
        caseAuth.value = true
      }
    }
  })
}

function openIolpTab($Model) {
  if ($Model.hasIolpAuth) {
    window.showTab({
      title: $t('iolp.workflow.title.name'),
      name: 'IolpServiceMain',
      id: 'IolpServiceMain',
    })
  }
}

// 查询是否有展示身份验证的权限
function checkVerifyIdentityAuth($Model) {
  $Model.showVerifyButton = false;
  $Model.showVerifyLabel = false;
  queryEnabledVerifyIdentityConfig({}).then(data => {
    if (data) {
      if (data.length > 0 && hasVerifyIdentityAuth) {
        $Model.showVerifyButton = true;
        $Model.VerifyConfigItems = data;
      }
    }
  })
}

// 外发通知
function transToSendNotification(type) {
  let audioVideoCaller = '';
  if ($Model.callTypeFeature === '0') { // 呼入
    audioVideoCaller = $Model.audioVideoCaller || sessionStorage.getItem("caller")
    callInfo.audioVideoCaller = $Model.audioVideoCaller || sessionStorage.getItem("caller");
  } else if (isInner($Model.callTypeFeature)) { //内部两方三方多方通话
    audioVideoCaller = '6'
    callInfo.audioVideoCaller = ''
  } else {
    audioVideoCaller = $Model.audioVideoCalled || sessionStorage.getItem("called")
    callInfo.audioVideoCaller = $Model.audioVideoCalled || sessionStorage.getItem("called")
  }
  let audioVideoCallId = $Model.audioVideoCallId || sessionStorage.getItem("callid") // 通话状态下关闭工作台重新打时数据取值
  callInfo.audioVideoCallId = $Model.audioVideoCallId || sessionStorage.getItem("callid");
  callInfo.fromConsole = true;
  if (!audioVideoCallId) {
    audioVideoCallId = reConfigCallInfo();
    if (!audioVideoCallId) {
      AiccElMessage.warning($t('agentconsole.ccnotification.not.currently.on.call'));
      return;
    }
  }
  if (type === 'sms') {
    SMSDialogVisible.value = true;
    return;
  }
  if (type === 'whatsapp') {
    whatsappDialogVisible.value = true;
    return;
  }
  const key = 'transToSendNotification' + type
  let detailUrl
  if ('whatsapp' == type) {
    detailUrl = "/service-cloud/ccmanagement/pages/sendwhatsappmessage/sendwhatsappmessage.html?&audioVideoCaller=" + audioVideoCaller + "&audioVideoCallId=" + audioVideoCallId;
  } else {
    detailUrl = "/service-cloud/ccnotification/page/send-message-audioandvideo/sendaudiovideomessage.html?";
    detailUrl += '&audioVideoCaller=' + audioVideoCaller;
    detailUrl += '&audioVideoCallId=' + audioVideoCallId;
    detailUrl += '&audioVideoType=' + type;
  }
  if (type == 'email') {
    window.showTab({
      title: $t('ccmessaging.chat.agentconsole.sendEmail'),
      id: "SendEmailMsg",
      name: 'SendEmailMsg',
      query: { audioVideoCaller, audioVideoCallId, audioVideoType: type}
    })
    return;
  }
  top.window.openOrCreateTab(key, $t('agentconsole.header.title.send.notification'), detailUrl);

}

// 发起身份验证流程
function selectVerifyIdentity($Model, item) {
  let audioVideoCallId = $Model.audioVideoCallId || sessionStorage.getItem("callid") // 通话状态下关闭工作台重新打时数据取值
  if (!audioVideoCallId) {
    AiccElMessage.warning($t('agentconsole.ccnotification.not.currently.on.call'));
    return
  }

  if ($Model.callTypeFeature == '52' || $Model.callTypeFeature == '10') { // 三方通话
    AiccElMessage.warning($t('agentconsole.ccnotification.not.currently.on.three'));
    return
  }

  // 挂起转设置状态缓存
  sessionStorage.setItem("transfer_ivr", "true");
  let searchObject = {};
  searchObject["callid"] = audioVideoCallId;
  searchObject["verifyCode"] = item.verifyCode;
  verifyIdentityflow(searchObject).then(reqResult => {
    if (reqResult) {
      $Model.verifyResult = reqResult["resultData"];
    }
  })
}

function isInner(callType) {
  if (callType == '6' || callType == '51' || callType == '52' || callType == '53') { //6:内部通话 51:内部两方咨询；52：内部三方咨询;53：多方通话
    return true
  }
  return false
}

function isCreateCaseMeth() {
  $Model.isCreateCaseFlag = false;
  isCreateCase().then(isCreateCaseResult => {
    if (isCreateCaseResult) {
      $Model.isCreateCaseFlag = isCreateCaseResult.isCreateCase;
    }
  })
}

function createCase() {
  let audioVideoCallId = $Model.audioVideoCallId || sessionStorage.getItem("callid") // 通话状态下关闭工作台重新打时数据取值
  if (!audioVideoCallId) {
    AiccElMessage.warning($t('agentconsole.ccnotification.not.currently.on.call'));
    return
  }

  let text = top.window.$Model.realtimeTransferCusMsg[audioVideoCallId];
  if (!text) {
    AiccElMessage.warning($t('ccm.asr.create.case.text.info'));
    return;
  }
  if (text.length > 4096) {
    text = text.substring(text.length - 4096);
  }

  textClassification(text).then(textClassificationResult => {
    let label = "";
    // 2个及3个工单类型时
    if (textClassificationResult && textClassificationResult.label1) {
      label0 = textClassificationResult.label0;
      label1 = textClassificationResult.label1;
      label2 = textClassificationResult.label2;
      dialogVisibleCreateCase.value = true;
    } else {
      if (textClassificationResult && textClassificationResult.label0) {
        // 1个工单类型时
        createWorkOrder(textClassificationResult.label0);
      } else {
        // 0个工单类型时
        ElMessageBox.confirm($t('ccm.create.case.confirm'), $t('ccm.agent.label.information'), {
          confirmButtonText: $t('agentconsole.management.message.filterYes'),
          cancelButtonText: $t('agentconsole.management.message.filterNo'),
          closeOnClickModal: false,
          type: 'warning'
        })
            .then(() => {
              createWorkOrder(label);
            });
      }
    }
  })
}

const throttledHandle = throttle(createCase, 3000);

function createWorkOrder(label) {
  let audioVideoCallId = $Model.audioVideoCallId || sessionStorage.getItem("callid") // 通话状态下关闭工作台重新打时数据取值
  if (!audioVideoCallId) {
    AiccElMessage.warning($t('agentconsole.ccnotification.not.currently.on.call'));
    return
  }
  let detalUrl = "/service-cloud/cccase/pages/createcase/createcase.html";
  detalUrl = detalUrl + "?callId=" + audioVideoCallId + "&caseType=" + label;
  let userName = top.window.$Model.userNameMap[audioVideoCallId];
  top.window.openOrCreateTab(audioVideoCallId, $t('casemanagement.casequery.button.create') + "-" + userName, detalUrl);
  if (audioVideoCallId) {
    var obj = {
      "callId": audioVideoCallId,
      "caseType": label,
      "conversationText": top.window.$Model.realtimeTransferAllMsg[audioVideoCallId]
    }
    var createCaseFrame = findIframe(audioVideoCallId);
    setTimeout(function () {
      createCaseFrame.contentWindow.postMessage({"createCase": obj}, location.origin);
    }, 1000);
  }
}

function onSelectWorkOrderType(label) {
  createWorkOrder(label);
}

function findIframe(iframeId) {
  var iframes = top.document.getElementsByTagName("iframe")
  var targetIframes;
  for (let i = 0; i < iframes.length; i++) {
    targetIframes = iframes[i];
    if ($(targetIframes).attr('innerid') == iframeId) {
      break;
    }
  }
  return targetIframes;
}

// 控制创建骚扰图标是否显示
function setShowCreateBotherFlag(data, $Model) {
  if (data && data.eventData && !data.eventData['forCreateBother']) {
    return
  }
  const feature = data.eventData && data.eventData.feature
  const mediaAbility = data.eventData && data.eventData.mediaAbility
  // 通话结束
  if (data.eventName === 'endOfCall') {
    $Model.showCreateBotherFlag = false;
    window.$Scope && window.$Scope.$apply && window.$Scope.$apply();
  }
  // 三方已经完成
  if (data.eventName && data.eventName === 'AgentEvent_Conference') {
    $Model.showCreateBotherFlag = false;
    window.$Scope && window.$Scope.$apply && window.$Scope.$apply();
    return
  }
  if (feature == undefined) {
    return
  }
  if (data?.eventData?.callid != window.GLOBAL_CURRENT_DEALCALLID) {
    return;
  }
  // 0- 普通呼入 7-普通呼出  5-多媒体能力
  if ((0 == feature || 7 == feature) && (mediaAbility != 5)) {
    $Model.showCreateBotherFlag = true
  } else {
    $Model.showCreateBotherFlag = false
  }
  window.$Scope && window.$Scope.$apply && window.$Scope.$apply();
}

function openEditCallresonDialog(){
  let audioVideoCallId = $Model.audioVideoCallId || sessionStorage.getItem("callid") // 通话状态下关闭工作台重新打时数据取值
  if (!audioVideoCallId) {
    audioVideoCallId = reConfigCallInfo();
    if (!audioVideoCallId) {
      AiccElMessage.warning($t('agentconsole.ccnotification.not.currently.on.call'));
      return;
    }
  }
  dialogVisibleCase.value = true
}

function reConfigCallInfo (){
  let audioVideoCallId = '';
  let callInfos = sessionStorage.getItem("callInfos");
  if (!callInfos) {
    return audioVideoCallId
  }
  let callInfosJson = JSON.parse(callInfos);
  for (const key in callInfosJson) {
    if (callInfosJson[key].mediaType !== 53) {
      audioVideoCallId = key;
      break;
    }
  }
  if (!audioVideoCallId) {
    return audioVideoCallId
  }
  $Model.audioVideoCallId = audioVideoCallId
  callInfo.audioVideoCallId = audioVideoCallId;
  $Model.callTypeFeature = callInfosJson[audioVideoCallId].callFeature + '';
  if (callInfosJson[audioVideoCallId].callFeature === 0) { // 呼入
    $Model.audioVideoCaller = callInfosJson[audioVideoCallId].caller;
    callInfo.audioVideoCaller = callInfosJson[audioVideoCallId].caller;
  } else if (isInner(callInfosJson[audioVideoCallId].callFeature)) { //内部两方三方多方通话
    callInfo.audioVideoCaller = ''
  } else {
    $Model.audioVideoCalled = callInfosJson[audioVideoCallId].called;
    callInfo.audioVideoCaller =  callInfosJson[audioVideoCallId].called;
  }
  return audioVideoCallId;
}

// 工单列表
const appcubeCaseList = ref()

initWorkbeanch(window.$Scope);
isCreateCaseMeth();

// AppCube客户中心
const appcubeCustomerCenter = ref()
const appcubeCustomerCenterEnableFlag = ref(false)
const appcubeCustomerCenterShowFlag = ref(false)
const appcubeCustomerCenterUrl = ref('')
let lastAppcubeCustomerCenterEventData = null

const initAppcubeCustomerCenter = () => {
  queryAppcubeCustomerCenterEnable()
}

// 查询客户中心是否能开启
const queryAppcubeCustomerCenterEnable = () => {
  const appcubeCustomerCenterFeatureCode = '990002303001'
  const enableCustomerCenterSysParamId = '6004005000003'
  Promise.all([
    queryCustomerCenterForUser({ 'featureCode': appcubeCustomerCenterFeatureCode }),
    queryTenantSysParamByItemIds(enableCustomerCenterSysParamId)
  ]).then(([res1, res2]) => {
    if (res1 && res1.data === true && res2 && res2.data && res2.data.value == '1') {
      appcubeCustomerCenterEnableFlag.value = true
      setAppcubeCustomerFeatureUrl()
      // 查询客户中心用户
      queryAppCubeCustomerList()
    }
  })
}

const queryAppCubeCustomerList = () => {
  let param = { start: 0, limit: 100 }
  document.getElementById('bingoPageInitCas').contentWindow.fetch("/service/AICC__Case/1.0.0/case/contact/list", {
    method: "post",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(param)
  }).then(response => response.json()).then( res => {
    if (res.resCode == '0') {
      customerList = res.result[0].customers
    }
  })
}

// 设置嵌入的客户中心页面地址
const setAppcubeCustomerFeatureUrl = () => {
  if ($Model.tenantInfo && $Model.tenantInfo.adminAccount) {
    setCustomerCenterUrl()
  } else {
    queryTenantSpaceLocale(cookieUtils.getCookie('tenant_space_id')).then(res => {
      if (res && res.data) {
        $Model.tenantInfo = res
        setCustomerCenterUrl()
      }
    })
  }
}

const setCustomerCenterUrl = () => {
  if (appcubeCustomerCenterEnableFlag.value) {
    appcubeCustomerCenterUrl.value = location.origin + '/besBaas/baas/abc/foundation/index.html#/AICC__CustomerInfoTab';
    sendMessageToAppcubeCustomerCenter({ data: agentConsoleTabStore && agentConsoleTabStore.$state && agentConsoleTabStore.$state.pageParams});
  }
}

const setCaseCustomerName = (userId) => {
}
const sendMessageToAppcubeCaseList = (e) => {
  if (!e || !e.data) {
    return
  }
  let eventName
  if (e.data.name == 'initPage' && e.data.callType != undefined) {
    eventName = 'CallArrive'
  }
  if (
      e.data.name == 'endOfCall' ||
      (e.data.eventName == 'Release' && e.data.eventData.callType != null)
  ) {
    eventName = 'VoiceCallOver'
  }

  let eventData = {}
  if (eventName == 'CallArrive') {
    eventData = {
      channel: 'voice',
      userId: e.data.callType == 1 ? e.data.called : e.data.caller,
      callId: e.data.callid
    }
  }
  if (eventName == 'CallArrive' || eventName == 'VoiceCallOver') {
    const appcubeIframe = appcubeCaseList.value
    if (appcubeIframe) {
      if (appcubeIframe.contentWindow && appcubeIframe.contentWindow.eventHandlerFlag) {
        appcubeIframe.contentWindow.postMessage(
            {
              eventData: eventData,
              eventName: eventName
            },
            location.origin
        )
      } else {
        let count = 0
        const interval = setInterval(() => {
          if (
              (appcubeIframe.contentWindow && appcubeIframe.contentWindow.eventHandlerFlag) ||
              count > 100
          ) {
            clearInterval(interval)
            appcubeIframe.contentWindow.postMessage(
                {
                  eventData: eventData,
                  eventName: eventName
                },
                location.origin
            )
          } else {
            count++
          }
        }, 200)
      }
    }
  }
}

const sendMessageToAppcubeCustomerCenter = (e) => {
  if (!appcubeCustomerCenterEnableFlag.value || !e  || !e.data) {
    return
  }

  let eventName
  if (e.data.name == 'initPage' && e.data.callType != undefined) {
    eventName = 'CallArrive'
  }
  if (e.data.name == 'endOfCall' || (e.data.eventName == 'Release' && e.data.eventData.callType != null)) {
    eventName = 'VoiceCallOver'
  }

  let eventData = {}
  if (eventName == 'CallArrive') {
    eventData = {
      channel: 'voice',
      userId: e.data.callType == 1 ? e.data.called : e.data.caller,
      callId: e.data.callid
    }
  }
  if (eventName == 'CallArrive' || eventName == 'VoiceCallOver') {
    lastAppcubeCustomerCenterEventData = eventData
    appcubeCustomerCenterShowFlag.value = true
    const appcubeIframe = appcubeCustomerCenter.value
    if (appcubeIframe) {
      if (appcubeIframe.contentWindow && appcubeIframe.contentWindow.eventHandlerFlag) {
        appcubeIframe.contentWindow.postMessage({
          eventData: eventData,
          eventName: eventName
        }, location.origin)
      } else {
        let count = 0
        const interval = setInterval(() => {
          if ((appcubeIframe.contentWindow && appcubeIframe.contentWindow.eventHandlerFlag) || count > 100) {
            clearInterval(interval)
            appcubeIframe.contentWindow.postMessage({
              eventData: eventData,
              eventName: eventName
            }, location.origin)
          } else {
            count++
          }
        }, 200)
      }
    }
  }
  if (eventName == 'CallArrive') {
    let count = 0
    const interval = setInterval(() => {
      var iframe = document.getElementById('AICC__createCasePoc')
      if ((showCase.value && eventData.userId && iframe) || count > 100) {
        try {
          let pageData = iframe.contentWindow.$model.ref('page').getData()
          let appcubeInfo = pageData.caseInfo
          let customer = customerList.find(
              (item) =>
                  item.cellPhone1 == eventData.userId ||
                  item.cellPhone2 == eventData.userId ||
                  item.cellPhone3 == eventData.userId
          )
          if (customer != undefined) {
            appcubeInfo.contactId = customer.id
            appcubeInfo.origin = 'Voice'
            contactId = customer.id
            origin = 'Voice'
            console.log('CallArrive set contactId')
            iframe.contentWindow.$model.ref('page').setValue('caseInfo', appcubeInfo)
          }
          clearInterval(interval)
        } catch (error) {
          count++
        }
      } else {
        count++
      }
    }, 200)
  }
}

function onLoadAppcubeCaseListIframe() {
  // 传入当前menu id
  const appcubeIframe = appcubeCaseList.value
  if (appcubeIframe) {
    appcubeIframe.contentWindow.ContactHistory_originMenuId = '6006'
  }
}

function onLoadAppcubeCustomerCenterIframe() {
  const appcubeIframe = appcubeCustomerCenter.value;
  if (appcubeIframe) {
    appcubeIframe.contentWindow.ContactHistory_originMenuId = '6006';

    const iframeDocument = appcubeIframe.contentDocument || appcubeIframe.contentWindow.document;

    let contents = iframeDocument.querySelector('.contents');
    if (contents) {
      processContents(contents);
    } else {
      const observer = new MutationObserver((mutations, obs) => {
        contents = iframeDocument.querySelector('.contents');
        if (contents) {
          obs.disconnect();
          processContents(contents);
        }
      });

      observer.observe(iframeDocument.body, {
        childList: true,
        subtree: true
      });
    }
  }
}

function processContents(contents) {
  const containers = contents.querySelectorAll('.xview-container');
  const info = containers[1]
  const uselessElement = containers[containers.length - 1];
  uselessElement.remove()

  const infoContent = info.querySelectorAll('.xview-container')[1];
  const rows = infoContent.querySelectorAll('.ivu-row');

  const [nameRow, genderRow, phoneRow, emailRow, companyRow, addressRow, remarkRow] = infoContent.querySelectorAll('.ivu-row');

  genderRow.remove();
  companyRow.remove();
  emailRow.remove();
}

initAppcubeCustomerCenter()

onActivated(() => {
  // 切换tab页签时会重新加载iframe，此时更新客户中心信息
  if (lastAppcubeCustomerCenterEventData && appcubeCustomerCenterUrl.value) {
    const { channel, userId, callId } = lastAppcubeCustomerCenterEventData
    if (!callId) {
      return;
    }
    const urlSuffix = `?channel=${channel}&userId=${userId}&callId=${callId}`
    let urlPrefix = appcubeCustomerCenterUrl.value
    if (appcubeCustomerCenterUrl.value.indexOf('?') > 0) {
      urlPrefix = appcubeCustomerCenterUrl.value.split('?')[0]
    }
    appcubeCustomerCenterUrl.value = urlPrefix + urlSuffix
  }
})

const updateAcceptNo = (number) => {
  consoleStoreData.pageParams.name = "contactAcceptNum";
  consoleStoreData.pageParams.acceptNo = number;
}

window.audioAndVideoWorkbenchShowCreateCase = () => {
  return showCard('case');
}

const initFlowVisibleFeature = () => {
  queryTenantFeaturesForWeb().then(result => {
    const { tenantFeatures = [] } = result
    let feature = tenantFeatures.find(item => item?.featureCode == '88002500100' && item.value == '1')
    if (feature) {
      iolpFlowVisible.value = true;
    }
  })
}

onBeforeMount(() => {
  initFlowVisibleFeature();
})

onMounted(() => {
  const data = useAgentEventStore().getEventData();
  if (data && data.eventData && data.eventData['forCreateBother']) {
    setShowCreateBotherFlag(data, $Model);
  }
})

</script>

<style lang="less" scoped>
@import '@/views/agentconsole/css/audioandvideoworkbench.css';

.sub-title {
  font-weight: 600;
  color: #1c1c1c;
  font-size: 14px;
  padding-bottom: 10px;
  line-height: 24px;
}

.full-screen-icon {
  width: 12px;
  height: 12px;

  &:hover {
    color: #5a7dfd;
    background-color: #f5f5f5;
    cursor: pointer;
  }
}

.card-control {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 20px;
}

.user-info-name {
  font-size: 0.875rem;
  color: #1c1c1c;
  line-height: 1.5rem;
  font-weight: 600;
  padding: 0.625rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.liDropOptions {
  position: relative;
  border: 1px solid #e1e3e5;
  background: #fff;
  border-top: 0px;
  line-height: 2;
  padding: 0rem;
  white-space: nowrap;
  z-index: 3;
}

[v-cloak] {
  display: none;
}

.hoverBg {
  background: #f4f6fa;
}

.iconMargin {
  margin-right: 12px;
}

.aicc-user-info-verify {
  width: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.aicc-user-info-verify .el-dropdown{
  padding: 0;
}
.icon-button-verify {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  top: 45%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.el-dropdown-menu__item.is-disabled {
  cursor: not-allowed;
  color: var(--el-text-color-disabled);
}
.contact-history {
  font-size: 14px;
  font-weight: 500;
}
.page-left-tab .tab-selected-line{
  bottom: 0;
  width: calc(100% - 20px);
}
body.RTL .icon-button-verify {
  transform: translate(-50%,-50%) scaleX(-1);
}

.panel-width {
  width: 100% !important;
}

.panel-div {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.case-content.close {
  width: 0px !important;
}

.case-content.close> :not(.left-case-btn) {
  display: none;
}

.case-content {
  display: flex;
  flex-direction: column;
  width: 34.5rem;
  position: relative;
  transition: 0.1s;
  box-sizing: border-box;
  height: 100%;
}

.left-case-btn {
  position: absolute;
  right: 0;
  top: 50%;
  background: url('../../assets/img/open_u34714.png') no-repeat;
  z-index: 100;
  width: 0.8125rem;
  height: 5rem;
  cursor: pointer;
}

.left-case-btn:hover {
  background: url('../../assets/img/open_u34714-hover.png') no-repeat;
}

.left-case-btn.close {
  right: -1rem;
  background: url('../../assets/img/close_u34714.png') no-repeat;
}

.left-case-btn.close:hover {
  right: -1rem;
  background: url('../../assets/img/close_u34714-hover.png') no-repeat;
}

.card-div-line {
  //height: calc(100% - 1rem);
  //overflow-y: auto;
  //margin-top: 1rem;
  //overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.box-shadow {
  box-shadow: 0px 4px 14px rgba(58, 56, 97, 0.11);
}

.card-div {
  padding: 0.625rem 1.25rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 14px rgba(58, 56, 97, 0.11);
  background: #ffffff;
}

.card-div-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-div-text {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: PingFang SC;
}

.intent-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin-left: 1.25rem;
}

.intent-title {
  color: #707070;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.intent-div {
  display: flex;
  align-items: center;
  margin-top: 1.3rem;
}

.grey-div {
  background: #fafafd;
  border: 1px solid #f8faff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
  padding: 0.625rem;
}

.grey-div-value {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 0.875rem;
  margin-top: 0.625rem;
}

.grey-div-title {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  padding-left: 0.75rem;
  border-left: 0.1875rem solid #366bfc;
}

.close-icon:hover {
  color: var(--swt-color-accent-hover);
  background-color: var(--swt-color-gray2);
  cursor: pointer;
}

.right-button-div {
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  width: 3.75rem;
  background: #f3f3f3;
  cursor: pointer;
  padding-left: 0 !important;
  margin-left: 0.625rem;
}

.right-button-div img {
  border-radius: 8px;
}

.tab-div {
  flex-grow: 1;
  //margin-left: 1rem;
  //margin-top: 1rem;
  //margin-bottom: 1rem;
  overflow: hidden;
  background: white;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0px 4px 14px rgba(58, 56, 97, 0.11);
  //height: 100%;
  display: flex;
  flex-direction: column;
}

:deep(.el-tabs__header) {
  padding-left: 1.25rem;
}

.mood-text {
  display: flex;
  align-items: center;
}

.mood-text-title {
  font-size: 1rem;
}

.mood-value {
  font-size: 0.875rem;
  height: 1.625rem;
  line-height: 1.625rem;
  text-align: center;
  background: #f8faff;
  border-radius: 8px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.mood-div {
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
}

.sweetui-icon-minor:before {
  color: #ee343f;
}

.div-agent-overview {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 2rem;
  line-height: 1.5rem;
}

.div-agent {
  display: flex;
  flex-direction: column;
}

.contact_end {
  background: #f3f3f3;
  flex-grow: 1;
}

.text_mid_li {
  display: flex;
  align-items: center;
}

.sensitive_icon {
  width: 10px;
  height: 10px;
  opacity: 0.8;
  border-radius: 2px;
  margin-right: 6px;
}

.color_green {
  background: #63ca22;
}

.color_red {
  background: #ee343f;
}

.text_green {
  color: #63ca22;
}

.text_red {
  color: #ee343f;
}

.had_img {
  width: 16px;
  height: 16px;
  display: flex;
  margin-right: 3px;
  margin-left: -3px;
}

.sensitive_text {
  font-size: 0.875rem;
  line-height: 1.375rem;
}

:deep(.el-tabs__content) {
  height: calc(100% - var(--el-tabs-header-height));
}

.button-div {
  background: #f7f6f6;
  border-radius: 8px;
  margin-right: 0.75rem;
  padding: 0.625rem 0.75rem;
  display: flex;
  align-items: center;
  height: 1.6rem;
  flex-shrink: 0;
  cursor: pointer;
}

.button-img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.text-button {
  font-size: 0.625rem;
  line-height: 1rem;
  font-weight: 600;
}

.case-div {
  margin: 0.625rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(54, 107, 252, 0.1);
  border-radius: 19px;
  flex-grow: 1;
}

.click-div {
  background: #366bfc;
  border-radius: 13px;
  margin-right: 0.625rem;
}

.click-span {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #ffffff;
  margin: 0.5rem 0.875rem;
}

.case-font {
  font-weight: 600;
  color: #1c1c1c;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.timer-div {
  width: 2rem;
  height: 2rem;
  background-color: rgba(54, 107, 252, 0.1);
  border-radius: 20px;
  margin-right: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-span {
  font-weight: 600;
  color: #366bfc;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.width-large {
  min-width: 45%;
}

.width-medium {
  width: 27.5%;
}
</style>