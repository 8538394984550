<template>
  <div style="background: white; padding: 16px 0; box-sizing: border-box;height:100%">
    <div class="hlds-m-bottom--small hlds-background-white" style="height: 100%;box-sizing: border-box;">
      <div id="intentionrecommend" style="overflow:auto;height: 100%;">
        <div class="smart-assistant-header" style="display: flex;">
          <div style="margin-top: 8px;">
            <span style="font-family: PingFangSC-Regular;font-size: 16px;">{{ $t('ccm.audiovideoworkbench.aiswitch') }}：</span>
            <sweet-switch v-model="enableAI" @change="changeAiSwitch"/>
          </div>
        </div>
        <!-- 业务请求展示 -->
        <div class="words-recommended" style="margin-top: 1rem" v-if="iolpList?.length">
          <div class="words-recommended-title recommended-title">
            {{ $t('agentconsole.wordreminder.recommend') }}
          </div>
          <!-- 装饰线 -->
          <div class="decorative-line"></div>
          <div class="recommended-content" ref="scrollableDivRef">
            <div class="recommended-content-item" v-for="(item) in iolpList">
              <div class="item-content-main" @click="clickBusinessItem(item)">
                <img class="img-icon" src="@/views/agentconsole/assets/img/ic_taxt@2x.png"/>
                <span class="item-content-main__text">{{ item.name }}</span>
              </div>
              <sweet-icon>
                <arrow-right/>
              </sweet-icon>
            </div>
          </div>
        </div>
        <!-- 话术推荐 -->
        <div class="words-recommended" style="margin-top: 1rem" v-if="intentions?.length">
          <div class="words-recommended-title recommended-title">
            {{ $t('agentconsole.wordreminder.recommend') }}
          </div>
          <!-- 装饰线 -->
          <div class="decorative-line"></div>
          <div class="recommended-content" ref="scrollableDivRef">
            <div class="recommended-content-item" v-for="(item, index) in intentions.slice(0, intentIndex)">
              <div class="item-content-main" @click="handleWordShrinkExpandToggle(index)">
                <i class="item-content-main__icon"
                   :class="item.show ? 'sweetui-icon-down' : 'sweetui-icon-up'"></i>
                <span class="item-content-main__text">{{ item.name }}</span>
              </div>
              <div class="item-content-details" v-if="item.show">
                <sweet-steps direction="vertical" active="#">
                  <sweet-step class="item-content-details__step" v-for="(subItem, subIndex) in item.word"
                              :key="subIndex">
                    <template #icon>
                      <img @click.stop="handleWordsContentImg(index, subIndex, !subItem.useStatus)"
                           v-if="subItem.useStatus" class="item-content-details__img"
                           src='../../assets/img/icon_step_marker.png'>
                      <img @click.stop="handleWordsContentImg(index, subIndex, !subItem.useStatus)"
                           v-else class="item-content-details__img"
                           src='../../assets/img/icon_step_default.png'>
                    </template>
                    <template #title>
                      <div>
                        <span>{{ subItem.content }}</span>
                      </div>
                    </template>
                  </sweet-step>
                </sweet-steps>
              </div>
            </div>
          </div>
        </div>
        <div class="recommend-history" v-if="intentions.length > 1 && intentIndex !== intentions.length"
             @click="showRecommendHistory()">
          {{ $t('agentconsole.agent.assistant.recomend.history') }}
        </div>

        <!-- FAQ推荐 -->
        <div class="faq-recommended" v-if="faqContent?.length">
          <div class="faq-recommended-title recommended-title">{{ $t('agentconsole.callmessage.faq.reminder') }}
          </div>
          <div class="decorative-line"></div>
          <div class="recommended-content">
            <div class="recommended-content-item" v-for="(item, index) in faqContent.slice(0, faqIndex)"
                 :key="item.faqId">
              <div class="item-content-main" @click="handleFaqShrinkExpandToggle(index)">
                <i class="item-content-main__icon"
                   :class="item.show ? 'sweetui-icon-down' : 'sweetui-icon-up'"></i>
                <span class="item-content-main__text">{{ item.faqGroupName }}</span>
              </div>
              <div class="item-content-details" v-if="item.show">
                <div v-for="(subItem, subIndex) in item.faq" :key="subIndex">
                  <div>
                    <span class="item-content-details__info">Q：{{ subItem.question }}</span>
                  </div>
                  <div>
                    <span class="item-content-details__info">A：{{ subItem.answers.join(';') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="recommend-history" v-if="faqContent.length > 1 && faqIndex !== faqContent.length"
             @click="showFaqHistory()">
          {{ $t('agentconsole.agent.assistant.faq.history') }}
        </div>

        <div class="knowledge-recommended" v-if="knowledges?.length">
          <div class="faq-recommended-title recommended-title">{{ $t('agentconsole.agent.knowledge.recommend') }}
          </div>
          <div class="decorative-line"></div>
          <div class="recommended-content">
            <div class="recommended-content-item" v-for="(item, index) in knowledges.slice(0, knowledgeIndex)"
                 :key="index">
              <div class="item-content-main" @click="handleKnowledgeShrinkExpandToggle(index)">
                <i class="item-content-main__icon"
                   :class="item.show ? 'sweetui-icon-down' : 'sweetui-icon-up'"></i>
                <span class="item-content-main__text">{{ item.name }}</span>
              </div>
              <div class="item-content-details" v-if="item.show">
                <sweet-steps direction="vertical" active="#">
                  <sweet-step class="item-content-details__step"
                              v-for="(subItem, subIndex) in item.knowledgeList" :key="subIndex">
                    <template #icon>
                      <img @click.stop="handleKnowledgeContentImg(index, subIndex, !subItem.useStatus)"
                           v-if="subItem.useStatus" class="item-content-details__img"
                           src='../../assets/img/icon_step_marker.png'>
                      <img @click.stop="handleKnowledgeContentImg(index, subIndex, !subItem.useStatus)"
                           v-else class="item-content-details__img"
                           src='../../assets/img/icon_step_default.png'>
                    </template>
                    <template #title>
                      <div>
                        <sweet-link type="primary" @click="openWindow(subItem.id)">
                          <div v-html="subItem.name"></div>
                        </sweet-link>
                      </div>
                    </template>
                  </sweet-step>
                </sweet-steps>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ng-scope"></div>
  </div>
</template>

<script>
import {safeHtmlEncode} from '@/views/agentconsole/common/common';
import $ from 'jquery';
import {
  sendAiDataStore,
  sendAiStatusStore,
  useAiAssistantStore,
  useAudioAiSwitchStore,
  useNlpResultStore,
  useVoiceTransferStore
} from "@/views/agentconsole/stores";
import {filterXSS, getDefaultWhiteList} from "xss";
import { queryTenantFeaturesForWeb } from '@/views/agentconsole/api/audio-video-workbench/index.js'
import { getauths } from "@/views/agentconsole/api/audio-video-workbench";


window.$ = $;
window.jQuery = $;

export default {
  name: 'FaqWord',
  props: {},
  setup() {
    const voiceTransferStore = useVoiceTransferStore();
    const nlpResultStore = useNlpResultStore();
    const audioAiSwitchStore = useAudioAiSwitchStore();
    const aiAssistantStore = useAiAssistantStore();
    const aiStatusStore = sendAiStatusStore();
    const aiDataStore = sendAiDataStore();
    return { voiceTransferStore, nlpResultStore, audioAiSwitchStore, aiAssistantStore, aiStatusStore, aiDataStore }
  },
  data() {
    return {
      nlpResult: {},
      isFirstGetNlp: true,
      agentReminder: [],    // 总卡片列表
      greetingWord: "",
      intentions: [],    // 话术列表
      knowledges: [],    // 知识列表
      iolpList: [],    // 业务引导列表
      currentIntention: "",
      faqContent: [],    // faq列表
      ellipsis: {},
      currentFaqGroupName: "",
      callid: "",
      joinStr: window.isRTL ? "\u200F:" : ":",
      iolpFlowVisible: false,
      enableAI: false,
      intentIndex: 1,
      faqIndex: 1,
      knowledgeIndex: 1,
      showAction: false,
      appCubeEnable: false,
      summary: '',
      caseForChat: '',
      showSummary: false,
      showCaseForChat: false,
    }
  },
  methods: {
    openWindow(id) {
      window.open("https://" + window.location.host + "/service-cloud/ccikbs/pages/kng-pre/kng-pre-detail.html?coluKngId=" + id, "_blank", "resizable,scrollbars,status");
    },
    handleCaseForChat() {
      this.aiStatusStore.setAiStatus(true, "caseStatus");
    },
    showRecommendHistory() {
      if (this.intentIndex + 2 <= this.intentions.length) {
        this.intentIndex = this.intentIndex + 2;
      } else {
        this.intentIndex = this.intentions.length;
      }
    },
    showFaqHistory() {
      if (this.faqIndex + 2 <= this.faqContent.length) {
        this.faqIndex = this.faqIndex + 2;
      } else {
        this.faqIndex = this.faqContent.length;
      }
    },
    handleWordsContentImg(index, subIndex, useStatus) {
      this.intentions[index].word[subIndex].useStatus = useStatus;
    },
    handleKnowledgeContentImg(index, subIndex, useStatus) {
      this.knowledges[index].knowledgeList[subIndex].useStatus = useStatus;
    },
    handleWordShrinkExpandToggle(index) {
      this.intentions[index].show = !this.intentions[index].show;
    },
    handleFaqShrinkExpandToggle(index) {
      this.faqContent[index].show = !this.faqContent[index].show;
    },
    handleKnowledgeShrinkExpandToggle(index) {
      this.faqContent[index].show = !this.faqContent[index].show;
    },
    changeAiSwitch() {
      this.audioAiSwitchStore.setAudioAiSwitchData(this.enableAI, "setAudioAiSwitchData");
    },
    //添加欢迎语
    addGreetingWord(greetingWord) {
      this.greetingWord = greetingWord;
      let greetingIntention = {};
      greetingIntention["name"] = this.$t('agentconsole.wordreminder.greetings');
      greetingIntention["step"] = "greetingword";
      let tempWord = [];
      tempWord.push({"checked": false, "content": greetingWord, "step": ""});
      greetingIntention["word"] = tempWord;
      greetingIntention["show"] = false;
      this.intentions.push(greetingIntention);
      greetingIntention["reminderFlag"] = true;
      this.agentReminder.push(greetingIntention);
      this.currentIntention = "greetingword";
      this.refersh();
    },

    handleNlpResult() {
      if (this.nlpResult["call_script_matchedstep"] != "UNKNOWN_INTENT" && this.nlpResult["call_script_matchedstep"] != "UNKNOWN" && this.nlpResult["call_script_matchedstep"] != "SENSITIVE_WORDS") {
        if (this.nlpResult["role"] == "staff") {
          // 座席识别内容，需要判断话术状态
          this.updateWording();
        }
        // 添加意图
        this.addIntention();
        this.addKnowledge();
      }
    },

    handleFaqResult(faq) {
      if (faq && faq.length > 0) {
        // 问答组不能重复
        let isrepeat = false;
        let faqId = faq[0].faqId;
        const index = this.faqContent.findIndex((item) => item.faqId === faqId);
        if (index >= 0) {
          if (index === this.faqContent.length - 1) {
            return;
          }
          isrepeat = true;
          let temintention = this.faqContent[index];
          this.faqContent.splice(index, 1);
          this.faqContent.push(temintention);
          temintention["faqFlag"] = true;
          this.agentReminder.push(temintention);
        }
        // 非重复的场景下，添加到list尾部
        if (!isrepeat) {
          this.faqContent.push({"faq": faq, "faqGroupName": faq[0].faqGroupName, "show": false, faqId: faq[0].faqId})
          this.agentReminder.push({
            "faq": faq,
            "faqId": faq[0].faqId,
            "faqGroupName": faq[0].faqGroupName,
            "show": false,
            "faqFlag": true
          });
        }
        // 只展示最新的1000条FAQ
        if (this.agentReminder.length > 1000) {
          this.agentReminder.splice(0, 1);
        }
        this.currentFaqGroupName = faq[0].faqGroupName;
        this.updateScroll();
        // 替换页面内容
        window.setTimeout(() => {
          for (var m = 0; m < faq.length; m++) {
            var answerslist = faq[m]["answers"];
            // answerslist内容循环
            for (var i = 0; i < answerslist.length; i++) {
              // 内容包含超链接，进行替换
              var answerhtmlid = faq[m].faqId + "_anwers_" + i;
              if (-1 != answerslist[i].indexOf("](http")) {
                var answerforshow = this.analysisGetTag(answerslist[i]);
                // 直接取出对应id对页面进行覆盖
                $("#" + answerhtmlid).html(answerforshow);
              }

              // 折叠展开逻辑
              var contentWidth = $("#hlds-background_default").width() - 38;    // 获取外围宽度减去间距
              if (answerslist[i]) {
                if (answerforshow) {
                  this.ellipsis[answerslist[i]] = this.isEllipsis(answerforshow.trim(), contentWidth)
                } else {
                  this.ellipsis[answerslist[i]] = this.isEllipsis((answerslist[i]).trim(), contentWidth)
                }
              }
            }
          }
          // 延时，否则页面还没加载
        }, 10)
      }
    },

    // 是否需要展开收起
    isEllipsis(text, contentWidth) {
      let el = document.createElement("div");    // 创建一个临时div
      el.innerHTML = safeHtmlEncode(text);
      el.style.whiteSpace = "nowrap";    // 不换行
      el.style.position = "absolute";
      el.style.opacity = "0";    // 完全透明，报错
      document.body.appendChild(el);
      let elWidth = el.clientWidth;    // 获取这个含有content内容的临时div的宽度
      document.body.removeChild(el);
      return elWidth > contentWidth * 2;    // 判断这个临时div的宽度是否大于原节点宽度的两倍
    },

    analysisGetTag(s) {
      if (-1 != s.indexOf("](http")) {
        const whiteList = getDefaultWhiteList();
        let midpoint = s.indexOf("](http");
        let forepart = s.substring(0, midpoint);    //前半部分 [普通链接不带标题
        let afterpart = s.substring(midpoint, s.length);    //后半部分 "](http://url)"
        let startpoint = forepart.lastIndexOf("[");
        let title = forepart.substring(startpoint + 1, midpoint);
        let endpoint = afterpart.indexOf(")");
        let url = afterpart.substring(2, endpoint);
        url = this.urlRegCheck(url) ? url : "";    // 特殊字符校验
        url = url.replaceAll("&", "&amp;"); // 避免html编码后绕过的问题
        let click = "<a onclick= \"window.open('" + url + "');\">" + filterXSS(title, whiteList) + "</a>";
        let html = filterXSS(s.substring(0, startpoint), whiteList) + click + filterXSS(afterpart.substring(endpoint + 1, afterpart.length), whiteList);
        return this.analysisGetTag(html);
      }

      return s;
    },

    // 添加意图和话术到展示栏，仅客户生效
    addIntention() {
      let step = this.nlpResult["call_script_matchedstep"];
      // 匹配到了欢迎语，不用处理
      if (step == "greetingword") {
        return;
      }

      // 处理流程可视化流程
      if (this.iolpFlowVisible && step == "IolpFlow") {
        let newBusinessItem = {};
        newBusinessItem.name = this.nlpResult.Intend;
        newBusinessItem.businessReqFlag = true;
        this.iolpList.push(newBusinessItem);
        this.agentReminder.push(newBusinessItem);
        return;
      }

      // 匹配到的意图没有话术，不用处理
      if (!this.nlpResult["call_script"] || this.nlpResult["call_script"].length == 0) {
        return
      }
      // 匹配到了话术，不用处理
      let flag = true;
      let tempIntention;
      for (let i = 0; i < this.nlpResult["call_script"].length; i++) {
        if (this.nlpResult["call_script"][i]["intentcode"] == step) {
          flag = false;
          tempIntention = this.nlpResult["call_script"][i];
          break;
        }
      }
      if (flag) {
        return;
      }

      if (this.nlpResult["Intend"]) {
        let newIntention = {};
        newIntention["name"] = this.nlpResult["Intend"];
        newIntention["step"] = step;
        let tempIntentions = tempIntention.childintent;
        let tempWord = [];
        if (!tempIntentions) {
          return;
        }
        for (let i = 0; i < tempIntentions.length; i++) {
          tempWord.push({
            "checked": false,
            "content": tempIntentions[i]["intentDsp"],
            "step": tempIntentions[i]["intentcode"]
          });
        }
        newIntention["word"] = tempWord;
        newIntention["show"] = true;
        newIntention["reminderFlag"] = true;
        this.agentReminder.push(newIntention);
        this.intentions.push(newIntention);
        this.refersh();
        this.updateScroll();
      }
    },

    addKnowledge() {
      let step = this.nlpResult["call_script_matchedstep"];
      // 匹配到了欢迎语，不用处理
      if (step == "greetingword") {
        return;
      }
      // knowledge不为空数组
      if (this.nlpResult["knowledge"] && this.nlpResult["knowledge"].length > 0) {
        let knowledgeList = this.nlpResult["knowledge"];
        let tempKnowledgeList = [];
        for (let i = 0; i < knowledgeList.length; i++) {
          tempKnowledgeList.push({
            "checked": false,
            "name": knowledgeList[i]["coluKngName"],
            "id": knowledgeList[i]["coluKngId"],
            "useStatus": false
          });
        }
        let newKnowledge = {};
        newKnowledge["name"] = this.nlpResult["Intend"];
        newKnowledge["step"] = step;
        newKnowledge["knowledgeList"] = tempKnowledgeList;
        newKnowledge["show"] = true;
        newKnowledge["reminderFlag"] = true;
        this.agentReminder.unshift(newKnowledge);
        this.knowledges.unshift(newKnowledge);
        this.refersh();
        this.updateScroll();
      }
    },

    // 更新话术状态
    updateWording() {
      let matchStep = this.nlpResult["call_script_matchedstep"];
      if (this.intentions.length > 0 && this.intentions[0]["step"] == "greetingword") {
        if (matchStep == "greetingword") {
          this.intentions[0]["word"][0]["checked"] = true;
          this.agentReminder[0]["word"][0]["checked"] = true;
          this.refersh();
          return;
        }
      }
      this.updateWordImpl(matchStep)
    },

    // 更新最近一个意图下的话术状态
    updateWordImpl(word) {
      let lastIntention = this.intentions[this.intentions.length - 1];
      let tempword = lastIntention["word"];
      for (var i = 0; i < tempword.length; i++) {
        if (tempword[i]["step"] == word) {
          this.intentions[this.intentions.length - 1]["word"][i]["checked"] = true;
          this.agentReminder[this.agentReminder.length - 1]["word"][i]["checked"] = true;
          this.refersh();
        }
      }
    },

    // 意图点击事件
    onIntentionClick(index) {
      if (this.intentions.length > 10) {
        index = this.intentions.length - (10 - index);
      }
      this.agentReminder[index]["show"] = !this.agentReminder[index]["show"];
      this.refersh();
    },

    updateScroll() {
      window.setTimeout(() => {
        $("#intentionrecommend")[0].scrollTop = $("#intentionrecommend")[0].scrollHeight;
      }, 50)
    },

    escapeText(text) {
      text = '' + text;
      var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
        '(': '&#40;',
        ')': '&#41;'
      };
      return text.replace(/[&<>"'()/]/g, function (m) {
        return map[m];
      });
    },

    refersh() {
    },

    clickBusinessItem(item) {
      window.showTab({
        title: $t('iolp.workflow.title.name'),
        name: 'IolpServiceMain',
        id: 'IolpServiceMain',
        query: {
          flowName: item.name,
          clickMark: new Date().getTime()
        },
      })
    },

    urlRegCheck(value) {
      var urlReg = /^[a-zA-Z0-9:\/?#=&%.]*$/;
      return urlReg.test(value);
    },
    initFlowVisibleFeature() {
      queryTenantFeaturesForWeb().then(result => {
          const { tenantFeatures = [] } = result
          let feature = tenantFeatures.find(item => item?.featureCode == '88002500100' && item.value == '1')
          if (feature) {
              this.iolpFlowVisible = true;
          }
      })
    }
  },
  watch: {
    // 监听消息数组长度，目前限制在20
    agentReminder(newAgentReminder, oldAgentReminder) {
      newAgentReminder.length > 20 ? this.agentReminder.splice(0, this.agentReminder.length - 20) : "";
    }
  },
  mounted() {
    this.voiceTransferStore.$subscribe((mutation, e) => {
      if (!e) {
        return;
      }
      if (e['name'] === "initPageTransfer") {
        let callId = e['callId']
        if (this.callid == callId) {
          return;
        }
        this.callid = callId;
        this.intentions = [];
        this.nlpResult = {};
        this.currentIntention = "";
        this.isFirstGetNlp = true;
        this.greetingWord = "";
        this.faqContent = [];
        this.iolpList = [];
        this.knowledges = [];
        this.ellipsis = {};
        this.agentReminder = [];
        this.intentIndex = 1;
        this.faqIndex = 1;
        this.knowledgeIndex = 1;
        this.showAction = false;
        this.appCubeEnable = false;
        this.summary = '';
        this.caseForChat = '';
        this.showSummary = false;
        this.showCaseForChat = false;
        return;
      }
    });

    this.nlpResultStore.$subscribe((mutation, e) => {
      if (!e || !e.data) {
        return;
      }
      let eventType = e.data['name'];
      if (eventType == "nlpResultData" && e.data['nlpResult']) {
        // 第一次获得的字符串nlpresult是欢迎语
        if ( this.isFirstGetNlp && typeof (e.data['nlpResult']) == "string" && typeof (JSON.parse(e.data['nlpResult'])) == "string" && e.data['nlpResult'].trim() != "" && e.data['nlpResult'].trim() != "{}" ) {
          this.isFirstGetNlp = false;
          this.addGreetingWord(e.data['nlpResult']);
          return;
        }

        this.nlpResult = JSON.parse(e.data['nlpResult']);
        this.handleNlpResult();

        let faq = [];
        if (this.nlpResult && this.nlpResult['recomand_KP'] && this.nlpResult['recomand_KP'][0] && this.nlpResult['recomand_KP'][0].faqGroupName) {
          faq = this.nlpResult['recomand_KP'];
          this.handleFaqResult(faq);
        }
      }
    });

    this.aiAssistantStore.$subscribe((mutation, state) => {
      if (!state.sendEventName) {
        return
      }
      let json = state.data;
      let answers = json.llm_answer;
      let intention = json.intention;
      let newIntention = {};
      newIntention["name"] = intention;
      newIntention["step"] = "step";
      let tempWord = [];
      answers.forEach(answer => {
        tempWord.push({
          "checked": false,
          "content": answer,
          "step": "step",
          "useStatus": false
        });
      })
      newIntention["word"] = tempWord;
      newIntention["show"] = true;
      newIntention["reminderFlag"] = true;
      newIntention["intention"] = intention;
      this.agentReminder.unshift(newIntention);
      this.intentions.unshift(newIntention);
      this.showAction = true;
      this.refersh();
      this.updateScroll();
      this.aiAssistantStore.setAiAssistantData();
    })

    this.aiDataStore.$subscribe((mutation, state) => {
      if (!state.sendEventName) {
        return
      }
      if (state.sendEventName === 'summaryData') {
        this.summary = state.data.description;
      }
      if (state.sendEventName === 'caseData') {
        this.caseForChat = state.data.result;
      }
      this.aiDataStore.setAiData();
    })

    getauths({}).then(data => {
      if (data) {
        if (jQuery.inArray("90011001", data["permCodes"]) !== -1) {
          this.appCubeEnable = true;
        }
      }
    });
  },
  created() {
    this.initFlowVisibleFeature();
  }
}
</script>

<style lang="less" scoped>
@import '@/views/agentconsole/css/audioandvideoworkbench.css';

.recommended-action-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weight-title {
  font-family: PingFang SC;
  font-weight: 600;
  font-size: var(--14px-to-rem);
  line-height: 1.5rem;
}

.recommend-history {
  font-weight: 600;
  color: #366bfc;
  font-size: var(--14px-to-rem);
  padding-bottom: 0.625rem;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 1.5rem;
}

.words-recommended,
.faq-recommended,
.action-recommended,
.knowledge-recommended {
  //max-height: calc((50% - 5rem));
  width: 100%;
  background: #FBFBFB;
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem;

  .recommended-title {
    font-size: var(--12px-to-rem);
    color: #1C1C1C;
    font-weight: 600;
    padding-left: 0.5rem;
    line-height: 1rem;
  }

  .decorative-line {
    margin: 1rem 0;
    border-bottom: 1px solid #EEEEEE;
  }

  .recommended-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &-item {
      display: flex;
      flex-direction: column;

      .item-content-main {
        display: flex;
        gap: 10px;
        cursor: pointer;

        &__icon {
          align-self: center;
          font-size: var(--12px-to-rem);
          font-weight: 500;
          color: #9E9E9E;
        }

        &__text {
          flex: 1;
          font-size: var(--14px-to-rem);
          color: #1C1C1C;
          font-weight: 500;
          word-wrap: break-word;
          word-break: break-all;
        }
      }

      .item-content-details {
        padding-top: var(--12px-to-rem);

        :deep(.el-step__title) {
          font-size: var(--12px-to-rem);
          color: #707070;
          font-weight: 400;
          word-wrap: break-word;
          word-break: break-all;
        }

        :deep(.el-step__icon.is-icon) {
          background-color: #FBFBFB;
        }

        &__step {
          cursor: pointer;
        }

        &__img {
          width: var(--18px-to-rem);
        }

        &__info {
          font-size: var(--12px-to-rem);
          color: #707070;
          font-weight: 400;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
}

.words-recommended,
.action-recommended {
  .words-recommended-title {
    border-left: 0.1875rem solid #366BFC;

  }
}

.faq-recommended,
.knowledge-recommended {
  .faq-recommended-title {
    border-left: 0.1875rem solid #FF9600;
  }

  .item-send-button {
    align-self: center;
    width: var(--14px-to-rem);
    height: var(--14px-to-rem);
    cursor: pointer;
  }
}

.spanfontsize {
  font-size: 1rem;
  width: 250px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateY(25%);
}

.word-checkbox .hlds-checkbox .hlds-form-element__label {
  white-space: normal;
  word-break: break-all;
  height: auto;
  width: 250px;
}

.word-checkbox .hlds-checkbox__label {
  align-items: unset;
}

.word-checkbox .hlds-checkbox .hlds-checkbox--faux {
  margin-top: 2px;
  border-radius: 1rem;
}

.word-checkbox {
  white-space: normal;
  word-break: break-all;
  height: auto;
  width: 250px;
  align-items: unset;
  margin-top: 2px;
  border-radius: 1rem;
}

.item + .item {
  margin-top: 16px
}

.faq-word-hlds-bold {
  letter-spacing: 0.5px;
  font-size: 14px;
  color: #1C1C1C;
  font-weight: 600;
}

.uee-gadget.faq-word {
  padding-top: 16px;
}

.item {
  background: #FFFFFF;
  border: 1px solid rgba(238, 238, 238, 1);
  border-radius: 8px;
}

span.faq-word-hlds-bold {
  font-size: 14px;
  color: #1C1C1C;
  font-weight: 500;
}

.img-icon {
  width: 16.3px;
  height: 16.16px;
  margin-inline-end: 5px;
  vertical-align: super;
}

.word-checkbox .hlds-checkbox .hlds-checkbox--faux {
  border-radius: 2px;
}

.hlds-checkbox [type=checkbox]:checked + .hlds-checkbox__label .hlds-checkbox--faux {
  border-color: #366BFC;
  background: #366BFC;
  width: 16px;
  height: 16px;
}

.hlds-checkbox .hlds-checkbox--faux {
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(173, 176, 184, 1);
  border-radius: 2px;
}

.hlds-background-white {
  background: #fff
}

.hlds-grow-fixed {
  -ms-flex-positive: 1;
  flex-grow: 1
}

.hlds-is-relative {
  position: relative;
}

.hlds-m-bottom--small {
  margin-bottom: 1rem
}

.hlds-m-bottom--x-small {
  margin-bottom: .5rem;
}

.hlds-m-left--small {
  margin-inline-start: 18px
}

.hlds-m-left--x-small {
  margin-inline-start: 9px
}

.hlds-m-left--xx-large {
  margin-inline-start: 54px
}

.hlds-m-right--x-small {
  margin-right: .5rem
}

.hlds-m-top--x-small {
  margin-top: .5rem
}

.hlds-m-top--xxx-small {
  margin-top: .25rem
}

.hlds-p-around--small {
  padding: 1rem;
}

.hlds-text-color--inverse {
  color: #1b8adb
}
.qus-ans {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #1C1C1C;
  line-height: 24px;
  font-weight: 500;
}
.smart-assistant-header {
  .header-text {
    font-size: var(--14px-to-rem);
    color: #1C1C1C;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
  }
}
.item-first {
  line-height: 33px;
  font-size: 14px;
  padding-inline-start: 15px;
  border-radius: 21px;
}
.business-item-nav {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  :deep(.el-icon) {
    margin-top: 10px;
    margin-inline-end: 12px;
  }
}
.business-item-nav:hover {
  color: var(--color-primary);
  .faq-word-hlds-bold {
    color: var(--color-primary);
  }
}
.business-item-pop {
  width: 220px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
} 
body.RTL {
  .business-item-nav {
  :deep(.el-icon) {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}
}
</style>