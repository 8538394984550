<template>
  <div class="right-content-top">
    <div class="user-tel">
      <div class="user-tel-number tooLong200px number-plaintext" :title="$Model.callNumberName"
           v-text=" $Model['callNumberName'] || callNumberShow || '-----'">
      </div>
      <div v-show="isCallBack && has_callBack_auth" :value="$t('ccm.agent.recall')" class="show-title-recall"
           id="show_title">
        <img
          v-show="showModel['callid'] && callRecallType() != null && (callRecallType() == '1' || callRecallType() == '0') && (agentStatusIsIdle() || agentStatusIsBusy() || isEndOfCall)"
          id="user_tel_img" src="@/views/agentconsole/assets/img/ic_callback@2x.png" alt=""
          @click="reCall();">
      </div>
    </div>
  </div>
  <div class="right-content-between">
    <div class="between-title" v-text="$t('agentconsole.callmessage.title')"></div>
    <div class="msg-item">
      <div class="item-label textHeight" v-text="$t('agentconsole.callmessage.callerNo')" :title="$t('agentconsole.callmessage.callerNo')"></div>
      <div class="item-content tooLong200px number-plaintext" :title="showModel.callerName">{{ showModel.callerName || '--' }}
      </div>
    </div>
    <div class="msg-item">
      <div class="item-label textHeight" v-text="$t('agentconsole.callmessage.calledNo')" :title="$t('agentconsole.callmessage.calledNo')"></div>
      <div class="item-content tooLong200px number-plaintext" :title="showModel.calledName">{{ showModel.calledName || '--' }}
      </div>
    </div>
    <div class="msg-item">
      <div class="item-label textHeight" v-text="$t('ccm.agent.contact.acceptno')" :title="$t('ccm.agent.contact.acceptno')"></div>
      <div class="item-content tooLong200px number-plaintext" style="flex:none" :title="handledNumber">{{ handledNumber || callNumberShow || '--' }}</div>
      <!-- 设置受理号码-->
      <sweet-tooltip :content="$t('agentconsole.callmessage.setHandledNumber')" placement="top"
                     v-if="showModel['callid']">
        <div>
          <sweet-popover :visible="visible" placement="bottom" :width="458" trigger="click">
            <div class="popper-all">
              <div class="popper-title">{{ $t('agentconsole.callmessage.setHandledNumber') }}</div>
              <div class="popper-coles" @click="visible = false"></div>
            </div>
            <div style="height: 36px"> </div>
              <sweet-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                label-position="left">
                <!-- 用户号码 -->
                <sweet-form-item :label="$t('ccm.speciallist.msg.userNo')" prop="phoneNumber">
                  <div v-show="phoneNumber.length > 0" class="del-icon" @click="cleanSetNumber()">x</div>
                  <sweet-input v-model="ruleForm.phoneNumber"/>
                </sweet-form-item>
              </sweet-form>
            <div class="set-button">
              <sweet-button @click="visible = false">{{ $t('ccd.transfer.button.cancel') }}</sweet-button>
              <sweet-button type="primary" @click="submitForm('ruleForm')">
                {{ $t('ccm.agent.button.save') }}
              </sweet-button>
            </div>
            <template #reference>
              <img id="set_number_img" src="@/views/agentconsole/assets/img/edit@2x.png" @click="resetNumber"/>
            </template>
          </sweet-popover>
        </div>
      </sweet-tooltip>
    </div>
    <div class="msg-item">
      <div class="item-label textHeight" v-text="$t('agentconsole.callmessage.callskill')" :title="$t('agentconsole.callmessage.callskill')"></div>
      <div class="item-content tooLong200px" :title="showModel.callSkill || '--' ">{{ showModel.callSkill || '--' }}</div>
    </div>
    <div class="msg-item" v-show="showModel.phoneNum">
      <div class="item-label textHeight" v-text="$t('agentconsole.Intelligent.labelname')" :title="$t('agentconsole.Intelligent.labelname')"></div>
      <div class="item-content link-word"
           @click="showIntelligentRecord = true">{{
          showModel.callSkill ||
          '-'
        }}
      </div>
    </div>
    <div class="msg-item" v-show="iolpFlowVisible && isShowIolp && $Model.hasIolpAuth">
      <div class="item-label textHeight" v-text="$t('agentconsole.iolp.labelname')" :title="$t('agentconsole.iolp.labelname')"></div>
      <aicc-tooltip :open-delay="800" overflow-target-selector=".iolp-selector" effect="light"
                    placement="bottom-start" :content="flowName">
        <div class="item-content link-word tooLong200px iolp-selector" @click="openIolpFlow">
          {{ flowName + ' >' }}
        </div>
      </aicc-tooltip>
    </div>

  </div>
  <!--智能语音记录-->
  <IntelligentRecord v-if="showIntelligentRecord" v-model:show="showIntelligentRecord" ></IntelligentRecord>
</template>

<script>
import {
  doRecallByCallId,
  getauths,
  queryAccountNameByWorkNos,
  updateContactAcceptNo
} from "@/views/agentconsole/api/audio-video-workbench";
import {ElMessageBox} from 'element-plus';
import $ from "jquery";
import IntelligentRecord from "@/views/agentconsole/pages/audio-video-workbench/IntelligentRecord.vue";
import validate from '@/utils/validate.js'
import { queryTenantFeaturesForWeb } from '@/views/agentconsole/api/audio-video-workbench/index.js'
import AiccElMessage from "@/utils/el-message";

window.$ = $;
window.jQuery = $;

export default {
  name: 'CallMessage',
  components: {IntelligentRecord},
  emits: ['updateAcceptNo'],
  setup() {
    const agentConsoleTabStore = window.useAgentConsoleTabStore();
    return {agentConsoleTabStore}
  },
  data() {
    var specialCharValidate = (rule, value, callback) => {
      if (validate.hasSpecialChar(value) || value.indexOf('>') !== -1 || value.indexOf('<') !== -1) {
        callback(new Error($t('ccm.agent.message.pageNameValidateFail') + '`~!@#$%^&*()+=|{}\'":;\\[\\]/?.<>~！@#￥%……&*（）——+|{}【】‘；：”“’,、？'));
      } else {
        callback();
      }
    };
    return {
      showModel: {},
      params: {},
      searchObject: {},
      showResult: [],
      resultModel: {},
      phoneNumber: '',
      response: {},
      isCallBack: true,
      handledNumber: '',
      callId: '',
      has_callBack_auth: false,
      callback_authId: "80031001001016",
      $Model: this.load$Model(),
      callRecallType: () => window.top['callrecall_callType'],
      agentStatusIsBusy: () => window.top['agentStatus'].isBusy(),
      agentStatusIsIdle: () => window.top['agentStatus'].isIdle(),
      showIntelligentRecord: false,
      callNumberShow: window.$Model['callNumberName'],
      rules: {
        phoneNumber: [
          {required: true, message: this.$t('ccm.agent.label.mobileagententer'), trigger: 'blur'},
          {validator: specialCharValidate, trigger: 'blur'},
          {max: 24, message: this.$t('agentconsole.callmessage.setNumerLengthError'), trigger: 'blur'}
        ]
      },
      ruleForm: {
        phoneNumber: ''
      },
      visible : false,
      isEndOfCall : false, // 音视频会话结束标志位
      flowName : '', // iolp流程名称
      isShowIolp : false, // 是否展示iolp可视化流程,
      iolpFlowVisible : false
    }
  },
  methods: {
    setShowModel() {
      const $Model = window.$Model;
      if (this.showModel['callid']) {
        //通话状态自动打开工作台取值
        var phoneNum = this.getAcceptNo(this.params);
        if (this.params['clickNickName']) {
          this.params['caller'] = this.params['clickNickName']
        }
        sessionStorage.setItem("called", this.params['called']);
        sessionStorage.setItem("caller", this.params['caller']);
        sessionStorage.setItem("callSkill", this.params['callSkill']);
        sessionStorage.setItem("phoneNum", phoneNum);
        this.showModel['called'] = this.params['called'] || "--";
        this.showModel['caller'] = this.params['caller'] || "--";
        this.getAgentNameByWorkNos(this.params['caller'], this.params['called'], $Model['callNumber'] ? $Model['callNumber'] : window.top.$(".call-number").html());
        this.showModel['callSkill'] = this.params['callSkill'] || "--";
        this.showModel['phoneNum'] = phoneNum;
      } else {
        //通话状态下关闭工作台重新打时数据取值
        if (sessionStorage.getItem("callid") && sessionStorage.getItem("callSkill")) {
          this.showModel['callid'] = sessionStorage.getItem("callid");
          this.showModel['called'] = sessionStorage.getItem("called") || "--";
          this.showModel['caller'] = sessionStorage.getItem("caller") || "--";
          this.showModel['callSkill'] = sessionStorage.getItem("callSkill") || "--";
          this.showModel['phoneNum'] = sessionStorage.getItem("phoneNum") == "null" ? null : sessionStorage.getItem("phoneNum");
          $Model['callNumber'] = sessionStorage.getItem('callNumber')
          this.handledNumber = $Model['callNumber']
          this.getAgentNameByWorkNos(this.showModel['caller'], this.showModel['called'], $Model['callNumber'] ? $Model['callNumber'] : window.top.$(".call-number").html());
        } else {
          //解决来话时页面初始化没有调到message时数据取值
          this.showModel['callid'] = this.agentConsoleTabStore.pageParams.callid;
          this.showModel['called'] = this.agentConsoleTabStore.pageParams.called || "--";
          this.showModel['caller'] = this.agentConsoleTabStore.pageParams.caller || "--";
          this.getAgentNameByWorkNos(this.agentConsoleTabStore.pageParams.caller, this.agentConsoleTabStore.pageParams.called, $Model['callNumber'] ? $Model['callNumber'] : window.top.$(".call-number").html());
          this.showModel['callSkill'] = unescape(this.agentConsoleTabStore.pageParams.callSkill || '') || "--";
          $Model['callNumber'] = window.top.$(".call-number").html()
          this.handledNumber = $Model['callNumber']
          sessionStorage.setItem("called", this.showModel['called']);
          sessionStorage.setItem("caller", this.showModel['caller']);
          sessionStorage.setItem("callSkill", this.showModel['callSkill']);
          sessionStorage.setItem('callNumber', this.handledNumber)
        }

      }
    },
    resetNumber() {
      this.visible = true;
      this.$refs['ruleForm'].resetFields()
    },
    load$Model() {
      if (!window.$Model) {
        window.$Model = {"buttonBox": new Map()};
      }
      this.$Model = window.$Model;
      return window.$Model;
    },
    /**
     * 根据工号数组查询坐席名称，返回map集合
     */
    getAgentNameByWorkNos(caller, called, callNumber) {
      let $Model = window.$Model;
      const callerCopy = [];
      callerCopy.push(caller);
      callerCopy.push(called);
      callerCopy.push(callNumber);
      //如果number是在101-50000之间则拿着number去查询坐席
      for (let i = 0; i < callerCopy.length; i++) {
        if (sessionStorage.getItem("caller_" + callerCopy[i]) || !(callerCopy[i] >= 101 && callerCopy[i] <= 50000)) {
          callerCopy.splice(i, 1); // 将使后面的元素依次前移，数组长度减1
          i--; // 如果不减，将漏掉一个元素
        }
      }
      const leftParenthesis = window.isRTL ? "\u200F(" : "(";
      this.showModel['calledName'] = sessionStorage.getItem("caller_" + called) === null ? (called || "--") : sessionStorage.getItem("caller_" + called) + leftParenthesis + called + ")";
      this.showModel['callerName'] = sessionStorage.getItem("caller_" + caller) === null ? (caller || "--") : sessionStorage.getItem("caller_" + caller) + leftParenthesis + caller + ")";
      $Model['callNumberName'] = sessionStorage.getItem("caller_" + callNumber) === null ? callNumber : sessionStorage.getItem("caller_" + callNumber) + leftParenthesis + callNumber + ")";
      if (callerCopy.length == 0) {
        return;
      }
      queryAccountNameByWorkNos(callerCopy).then(res => {
        if (res.returnCode == "0") {
          const map = res.result;
          for (const key in map) {
            if (key) {
              sessionStorage.setItem("caller_" + key, map[key]);
            }
          }
        }
        this.showModel['calledName'] = sessionStorage.getItem("caller_" + called) === null ? (called || "--") : sessionStorage.getItem("caller_" + called) + leftParenthesis + called + ")";
        this.showModel['callerName'] = sessionStorage.getItem("caller_" + caller) === null ? (caller || "--") : sessionStorage.getItem("caller_" + caller) + leftParenthesis + caller + ")";
        $Model['callNumberName'] = sessionStorage.getItem("caller_" + callNumber) === null ? (callNumber || "--") : sessionStorage.getItem("caller_" + callNumber) + leftParenthesis + callNumber + ")";
      })
    },

    showTitle() {
      let preShowId;
      let preTimeoutListener;
      $(".show-title-recall")
        .mouseenter(function (e) {
          //指向Title所在的标签
          let newTitle = this.attributes.getNamedItem('value').value;
          const id = $(this).attr('id');
          if (preShowId === id) {
            // 上次显示的id和当前元素id一致，即在同一个按钮上反复触发了移出移入，此时要将上一个计时器移除
            clearTimeout(preTimeoutListener);
          }
          preShowId = id;
          if ($(`#titletips-${id}`).length === 0) {
            // 避免反复插入相同id的dom元素，要提前判断该dom是否存在
            $(`#${id}`).append(`<div style="margin-top:8px;" id="titletips-${id}" class="titletips">${newTitle}</div>`);
          }
          $(`#titletips-${id}`).show();
        })
        .mouseleave(function () {
          const id = $(this).attr('id');
          preTimeoutListener = setTimeout(() => {
            $(`#titletips-${id}`).hide();
          }, 200)
        });
    },

    getAcceptNo(params) {
      var acceptNo = null;
      var acceptNum = null;
      if (params && params.ivrType) {
        acceptNo = params['callType'] == "0" ? params['caller'] : params['called'];
        acceptNum = params.ivrType == "1" ? acceptNo : null;
      }
      return acceptNum;
    },
    cleanSetNumber() {
      this.phoneNumber = ''
      $("#number_input").parent().find("#error_msg").remove()
    },
    cancel() {
      $("#number_input").parent().find("#error_msg").remove()
      this.phoneNumber = ''
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateContactAcceptNo(this.ruleForm.phoneNumber)
        } else {
          return false
        }
      })
    },
    updateContactAcceptNo(acceptNo) {
      if (!this.showModel['callid']) {
        this.showModel['callid'] = sessionStorage.getItem("callid");
      }
      const req = {callId: this.showModel['callid'], acceptNo: acceptNo};
      if (!req.acceptNo || !req.callId || acceptNo.length > 24) {
        return;
      }
      const $Model = window.$Model;
      this.visible = false;

      updateContactAcceptNo(req).then(response => {
        this.response = response;
        if (this.response && "0" == this.response.returnCode) {
          this.handledNumber = acceptNo;

          sessionStorage.setItem('callNumber', acceptNo);
          $Model['callNumber'] = acceptNo;
          // 更新受理号码后发送回调事件，更新接触记录
          this.$emit('updateAcceptNo', acceptNo);

          AiccElMessage.success($t('agentconsole.callmessage.setNumerSuccess'));
        } else {
          this.ruleForm.phoneNumber = '';
          AiccElMessage.error($t('agentconsole.callmessage.setNumerError'));
        }
      })
    },
    findIframe(iframeId) {
      var iframes = top.document.getElementsByTagName("iframe")
      var targetIframes;
      for (let i = 0; i < iframes.length; i++) {
        targetIframes = iframes[i];
        if ($(targetIframes).attr('innerid') == iframeId) {
          break;
        }
      }
      return targetIframes;
    },

    judgeRecallAuth() {
      // 如果页面通过传参给 has_callBack_auth 赋值为 true 不再调用接口去后台查询
      if (this.has_callBack_auth) {
        return;
      }
      getauths({}).then(resultModel => {
        this.resultModel = resultModel;
        if (this.resultModel) {
          if (jQuery.inArray(this.callback_authId, this.resultModel["permCodes"]) != -1) {
            this.has_callBack_auth = true;
          }
        }
      })
    },
    /**
     * 回呼
     */
    reCall() {
      let recallCallId = this.showModel['callid'];
      let recallCallType = window.top['callrecall_callType'];
      const skillId = window.top['callrecall_skillId'];
      if (recallCallId == null || recallCallType == null) {
        AiccElMessage.warning($t('ccm.agent.recall.param.callid.empty'));
        return;
      }
      // 当存在多媒体会话时，坐席状态为占用，增加音视频呼叫结束标志位，允许进行回呼
      if (!window.top['agentStatus'].isIdle() && !window.top['agentStatus'].isBusy() && !this.isEndOfCall) {
        AiccElMessage.warning($t('ccm.agent.recall.agent.status.invalid'));
        return;
      }
      if (recallCallType !== "0" && recallCallType !== "1") {
        AiccElMessage.warning($t('ccm.agent.recall.agent.calltype.invalid'));
        return;
      }

      if (skillId) {
        this.searchObject['skillId'] = skillId;
      }

      this.searchObject['callId'] = recallCallId;
      this.searchObject["callType"] = recallCallType;
      doRecallByCallId(this.searchObject).then(resultModel => {
        this.resultModel = resultModel;
        if ("0001" == this.resultModel["retcode"]) {
          AiccElMessage.error($t('ccd.callmessage.whiteNumberExist'))
          return;
        } else if ("0" != this.resultModel["retcode"]) {
          AiccElMessage.error($t('ccm.agent.recall.fail'))
        }
      })
    },
    openIolpFlow() {
      window.showTab({
        title: $t('iolp.workflow.title.name'),
        name: 'IolpServiceMain',
        id: 'IolpServiceMain',
        query: {
          flowName: this.flowName,
          clickMark: new Date().getTime()
        },
      })
    },
    checkCallMsgState(e) {
      if (e.dataSrc !== "audioAndVideo") {
        return;
      }
      /** 解决回呼图标不刷新，v-show不生效 */
      this.params = e.pageParams;
      this.isCallBack = false
      const timer = setInterval(() => {
        if (this.showModel['callid'] && window.top['callrecall_callType'] != null && (window.top['callrecall_callType'] == '1' || window.top['callrecall_callType'] == '0') && (window.top['agentStatus'].isIdle() || window.top['agentStatus'].isBusy() || this.isEndOfCall)) {
          this.isCallBack = true
          clearInterval(timer)
          this.showTitle()
        }
      }, 500)
      this.isEndOfCall = false;
      if (this.params && this.params['name'] != "initPage") {
        this.isEndOfCall = this.params['name'] === "endOfCall";
        return;
      }
      //获取客户电话
      $Model['callNumber'] = window.top.$(".call-number").html()
      // 解决事件处理时还没获取到受理号码
      if (!$Model['callNumber']) {
        let num = 0;
        let interval = setInterval(() => {
          num++;
          if ($Model['callNumber'] || num > 10) {
            this.callNumberShow = $Model['callNumber']
            clearInterval(interval);
          }
        }, 200);
      }

      this.handledNumber = $Model['callNumber']
      sessionStorage.setItem('callNumber', this.handledNumber)
      //end
      var phoneNum = this.getAcceptNo(this.params);
      if (this.params['clickNickName']) {
        this.params['caller'] = this.params['clickNickName']
      }
      sessionStorage.setItem("called", this.params['called']);
      sessionStorage.setItem("caller", this.params['caller']);
      sessionStorage.setItem("callSkill", this.params['callSkill']);
      sessionStorage.setItem("phoneNum", phoneNum);
      this.showModel['callid'] = this.params['callid'] || "--";
      this.showModel['caller'] = this.params['caller'] || "--";
      this.showModel['called'] = this.params['called'] || "--";
      this.getAgentNameByWorkNos(this.params['caller'], this.params['called'], $Model['callNumber']);
      this.showModel['callSkill'] = this.params['callSkill'] || "--";
      this.showModel['phoneNum'] = phoneNum;
      window.top['callrecall_callType'] = this.params['callType'];
      window.top['callrecall_skillId'] = this.params['callSkillId'];
      // 在随路数据中获取iolp可视化流程展示的流程编码
      this.isShowIolp = false;
      if (this.iolpFlowVisible && this.params?.callData) {
        // 智能ivr直接获取随路数据下的属性；ccivr需先获取随路数据下的data
        const callData = JSON.parse(this.params.callData);
        this.flowName = callData?.serviceRequest || callData?.data?.serviceRequest;
        this.isShowIolp = !!this.flowName;
      }
    },
    initFlowVisibleFeature() {
      queryTenantFeaturesForWeb().then(result => {
          const { tenantFeatures = [] } = result
          let feature = tenantFeatures.find(item => item?.featureCode == '88002500100' && item.value == '1')
          if (feature) {
            this.iolpFlowVisible = true;
          }
      })
    }
  },
  mounted() {
    // 订阅数据状态的变化
    this.agentConsoleTabStore.$subscribe((mutation, state) => {
      this.checkCallMsgState(state);
    });

    // 首次处理数据
    this.checkCallMsgState(this.agentConsoleTabStore);

    this.setShowModel();
    setTimeout(() => {
      this.showTitle();
    }, 800)
    this.judgeRecallAuth();
  },
  created() {
    this.initFlowVisibleFeature();
  }
}
</script>

<style lang="less" scoped>
@import '@/views/agentconsole/css/audioandvideoworkbench.css';
.right-content-top {
  height: 116x;
  width: 100%;
  border-bottom: 1px solid rgba(243, 243, 243, 1);
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#user_tel_img {
  height: 12px;
  width: 12px;
  margin-inline-start: 8px;
  cursor: pointer;
}

.user-tel {
  height: 32px;
  text-align: center;
  flex: 0 0 100%;
  font-size: 16px;
  color: #1c1c1c;
  line-height: 32px;
  font-weight: 500;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.set-number-button {
  height: 26px;
  border-radius: 13px;
  background: rgba(54, 107, 252, 0.04);
  font-size: 12px;
  color: #366bfc;
  line-height: 26px;
  padding: 0 20px;
  cursor: pointer;
  position: relative;
}

.right-content-between {
  border-bottom: 1px solid rgba(243, 243, 243, 1);
  align-content: space-around;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 18px 0;
  box-sizing: border-box;
}

.between-title,
.msg-item {
  flex: 0 0 100%;
}

.msg-item {
  align-items: center;
  display: flex;
  height: 26px;
  line-height: 26px;
  margin-top: 22px;
}

.item-label {
  font-size: var(--12px-to-rem);
  color: #707070;
  font-weight: 400;
  flex: 0 0 30%;
  cursor: pointer;
}

.item-content {
  font-size: var(--12px-to-rem);
  color: #1c1c1c;
  font-weight: 500;
  flex: 1;
  margin-left: 10px;
}
.link-word {
  color: #366BFC;
  cursor:pointer;
}

.between-title,
.lower-title {
  font-size: var(--14px-to-rem);
  color: #1c1c1c;
  font-weight: 600;
}

.el-popper[x-placement^=bottom] {
  margin-top: 12px;
}

.el-popover {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.11);
  word-break: break-all;
  top: 18px;
  left: 50%;
  transform: translate(-52%, 0);

}

.content-input {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: warp;
  margin-top: 20px;
}

#error_msg {
  color: red;
  position: absolute;
  top: 40px;
}

input.number-input {
  flex: 1;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 8px;
  padding: 0 32px 0 10px;
  font-size: 16px;
  color: #1C1C1C;
  /* text-align: center; */
  font-weight: 500;
}

.del-icon {
  position: absolute;
  right: 13px;
  height: 16px;
  width: 16px;
  font-size: 14px;
  background: #eeee;
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  top: 10px;
}

.button-cancel, .button-confirm {
  width: 96px;
  height: 32px;
  border-radius: 4px;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.button-cancel {
  border: 1px solid #c1c1c1;
  color: #1c1c1c;
  background: #fff;
}

.button-confirm {
  background: #366bfc;
  border: none;
  margin-left: 10px;
  color: #fff;
}

.foot-button {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}

.show-title-recall {
  z-index: 20;

}

#set_number_img {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  cursor: pointer;
}

.content-input-label {
  font-size: 12px;
  color: #707070;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-right: 12px;

}

.popper-head {
  width: 100%;
  display: flex;
}

.popper-title {
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #1C1C1C;
  line-height: 24px;
  font-weight: 600;
  float: left;
}

.popper-all {
  padding-bottom: 20px;
}
.popper-coles {
  display: flex;
  justify-content: flex-end;
  height: 24px;
  width: 24px;
  background: url("@/views/ccdesktop/resources/default/images/icon/close@2x.png");
  background-size: 100% 100%;
  cursor: pointer;
  float: right;
}

.textHeight {
  display: inline-block;
  line-height: 16px;
}

.tooLong200px {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  display: inline-block;
}
.set-button {
  text-align: right;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
:deep(form span) {
  white-space: normal;
  word-break: normal;
  text-align: left;
}
body.RTL {
  .popper-title {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #1C1C1C;
    line-height: 24px;
    font-weight: 600;
    float: right;
  }

  .popper-coles {
    display: flex;
    justify-content: flex-end;
    height: 24px;
    width: 24px;
    background: url("@/views/ccdesktop/resources/default/images/icon/close@2x.png");
    background-size: 100% 100%;
    cursor: pointer;
    float: left;
  }
}
</style>