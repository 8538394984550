<template>
  <div class="aicc-p-around--small pop-up-internal-page" ref="popUpInternalPageRef">
    <div class="cus-content" v-if="queryAuth">
      <sweet-row>
        <sweet-col :span="8">
          <sweet-form
            ref="ruleFormRef"
            :model="ruleForm"
            :inline-message="true"
            label-width="0px"
            @submit.prevent
          >
            <sweet-form-item>
              <sweet-input
                :placeholder="$t('agentconsole.querycustominfo.msg.inputHandleNumber')"
                v-model="ruleForm.handleNumber"
                maxlength="50"
                clearable
                @clear="searchData(ruleFormRef)"
                @keyup.enter="searchData(ruleFormRef)"
                style="width: var(--240px-to-rem);"
              >
                <template #prefix>
                  <sweet-icon class="input-icon" @click="searchData(ruleFormRef)">
                    <search />
                  </sweet-icon>
                </template>
              </sweet-input>
            </sweet-form-item>
          </sweet-form>
        </sweet-col>
        <sweet-col :span="16">
          <div
            v-if="showCustomDetails && modifyAuth && queryAuth"
            class="aicc-align--absolute-right"
          >
            <sweet-button v-if="mode == 'edit'" @click="cancel()" class="pop-up-background-white">{{
              $t('agentconsole.common.button.cancel')
            }}</sweet-button>
            <sweet-button v-if="mode == 'view'" @click="modify()" type="primary">{{
              $t('agentconsole.common.button.modify')
            }}</sweet-button>
            <sweet-button v-if="mode == 'edit'" @click="save()" type="primary">{{
              $t('agentconsole.common.button.save')
            }}</sweet-button>
          </div>
        </sweet-col>
      </sweet-row>
    </div>
    <div class="hlds-text-align--center" v-if="!showCustomDetails">
      <div class="pop-up-flex">
        <p>
          <span class="pop-up-no-data">{{ $t('agentconsole.querycustominfo.msg.noData') }}</span>
        </p>
      </div>
    </div>
    <div class="seach-content" ref="seachContentRef">
      <div
        v-if="layoutId == '001' && showCustomDetails"
        :class="{ 'overflow-content': mode === 'edit' }"
      >
        <sweet-form
          class="custom-details-form"
          ref="customDetailsRef"
          :model="customDetailForm"
          label-width="6.57rem"
          :label-position="labelPosition"
          :inline-message="true"
        >
          <div
            class="cus-block"
            v-for="(customDetail, customDetailsIndex) in customDetailForm.customDetails"
            :key="customDetailsIndex"
          >
            <div class="object-name">
              {{ customDetail.objectName }}
            </div>
            <div :id="customDetailsIndex" class="cus-content">
              <sweet-row :id="customDetail.objectId + '_' + customDetailsIndex">
                <sweet-col
                  :span="24 / numInRow"
                  v-for="($attribute, $index) in customDetail.attributes"
                  :key="$index"
                >
                  <sweet-form-item
                    :label="$attribute.columnName"
                    :prop="
                      'customDetails.' + customDetailsIndex + '.attributes.' + $index + '.value'
                    "
                    :rules="$attribute.rules"
                  >
                    <template v-if="'edit' == mode">
                      <sweet-input
                        :id="$attribute.id"
                        :disabled="$attribute.isIndex == 'Y'"
                        v-if="$attribute.columnType == '1' || $attribute.columnType == '2'"
                        v-model="$attribute.value"
                        clearable
                        @change="changeValue($attribute)"
                        @focusin="resetAttributeValue($attribute)"
                        style="margin-right: 20px; margin-left: 10px;"
                      ></sweet-input>
                      <aicc-select
                        :id="$attribute.id"
                        v-if="$attribute.columnType == '5'"
                        :disabled="$attribute.isIndex == 'Y'"
                        v-model="$attribute.value"
                        @change="changeValue($attribute)"
                        :clearable="$attribute.isMandatory == 'N'"
                        fit-input-width
                        :options="$attribute.dictItems || []"
                        defaultLabelKey="label"
                        defaultValueKey="key"
                        style="margin-right: 20px; margin-left: 10px;"
                      />
                      <sweet-date-picker
                        :disabled="$attribute.isIndex == 'Y'"
                        v-model="$attribute.value"
                        type="date"
                        :clearable="$attribute.isMandatory == 'N'"
                        v-if="$attribute.columnType == '3'"
                        @change="changeValue($attribute)"
                        style="margin-right: 20px; margin-left: 10px;"
                      />
                      <sweet-date-picker
                        :disabled="$attribute.isIndex == 'Y'"
                        v-model="$attribute.value"
                        :clearable="$attribute.isMandatory == 'N'"
                        type="datetime"
                        v-if="$attribute.columnType == '4'"
                        @change="changeValue($attribute)"
                        style="margin-right: 20px; margin-left: 10px;"
                      />
                    </template>
                    <template v-else>
                      <span class="attribute-value" v-if="$attribute.columnType == '5'">{{
                        $attribute.dictLabel
                      }}</span>
                      <span class="attribute-value" v-else>{{ $attribute.value }}</span>
                    </template>
                  </sweet-form-item>
                </sweet-col>
              </sweet-row>
            </div>
            <sweet-divider />
          </div>
        </sweet-form>
      </div>
      <div
        class="cus-content"
        :class="{ 'overflow-content': mode === 'edit' }"
        v-if="layoutId == '002' && showCustomDetailTileds"
      >
        <sweet-form
          class="custom-details-form"
          ref="customDetailsRef"
          :model="customDetailTiledsForm"
          label-width="6.57rem"
          :label-position="labelPosition"
          :inline-message="true"
        >
          <sweet-row id="customDetailTileds">
            <sweet-col
              :span="24 / numInRow"
              v-for="($attribute, $index) in customDetailTiledsForm.customDetailTileds"
              :key="$index"
            >
              <sweet-form-item
                :label="$attribute.columnName"
                :prop="'customDetailTileds.' + $index + '.value'"
                :rules="$attribute.rules"
              >
                <template v-if="'edit' == mode">
                  <sweet-input
                    :disabled="$attribute.isIndex == 'Y'"
                    :id="$attribute.id + '_tiled'"
                    v-if="$attribute.columnType == '1' || $attribute.columnType == '2'"
                    v-model="$attribute.value"
                    clearable
                    @change="changeValue($attribute)"
                    style="margin-right: 20px; margin-left: 10px;"
                  ></sweet-input>
                  <aicc-select
                    :id="$attribute.id"
                    v-if="$attribute.columnType == '5'"
                    :disabled="$attribute.isIndex == 'Y'"
                    v-model="$attribute.value"
                    @change="changeValue($attribute)"
                    :clearable="$attribute.isMandatory == 'N'"
                    fit-input-width
                    :options="$attribute.dictItems || []"
                    defaultLabelKey="label"
                    defaultValueKey="key"
                    style="margin-right: 20px; margin-left: 10px;"
                  />
                  <sweet-date-picker
                    :disabled="$attribute.isIndex == 'Y'"
                    v-model="$attribute.value"
                    :clearable="$attribute.isMandatory == 'N'"
                    type="date"
                    v-if="$attribute.columnType == '3'"
                    @change="changeValue($attribute)"
                    style="margin-right: 20px; margin-left: 10px;"
                  />
                  <sweet-date-picker
                    :disabled="$attribute.isIndex == 'Y'"
                    v-model="$attribute.value"
                    :clearable="$attribute.isMandatory == 'N'"
                    type="datetime"
                    v-if="$attribute.columnType == '4'"
                    @change="changeValue($attribute)"
                    style="margin-right: 20px; margin-left: 10px;"
                  />
                </template>
                <template v-else>
                  <span class="attribute-value" v-if="$attribute.columnType == '5'">{{
                    $attribute.dictLabel
                  }}</span>
                  <span class="attribute-value" v-else>{{ $attribute.value }}</span>
                </template>
              </sweet-form-item>
            </sweet-col>
          </sweet-row>
        </sweet-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, onMounted, toRefs, watch, computed } from 'vue'
import { getUserPermissions } from '@/utils/organization.js'
import {
  queryCustomInfoApi,
  modifyCustomInfo
} from '@/views/agentconsole/api/audio-video-workbench'
import { useBaseStore } from "@/views/base/stores";
import AiccElMessage from "@/utils/el-message";

const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance()

const props = defineProps(['pageId', 'layoutId', 'numInRow', 'callData'])
const { pageId, layoutId, numInRow, callData } = toRefs(props)
const popUpInternalPageRef = ref()
const seachContentRef = ref()
let observer = undefined
const ruleFormRef = ref()
const ruleForm = reactive({
  handleNumber: ''
})
const customDetailsRef = ref()
const customDetailTiledsForm = reactive({})
const customDetailForm = reactive({})
const labelPosition= window.isRTL ? ref('right') : ref('left')

const validateNumber = (rule, value, callback) => {
  let numReg = /^[0-9]*$/
  if (numReg.test(value)) {
    callback()
  } else {
    callback(new Error(globalProperties.$t('agentconsole.must.be.a.number')))
  }
}

const validateNumberLength = (max, i18nMessage) => (rule, value, callback, source, options) => {
  if (value && value.length > max) {
    callback(new Error(i18nMessage))
  }
  callback()
}
const baseStore = useBaseStore()
const hasMaskViewAuth = computed(() => baseStore.userRoleAuths?.includes('80031001014004') || false)
let userPermCodes = reactive({})
let queryAuth = ref(false)
let modifyAuth = ref(false)
let customDetails = reactive([])
let customDetailTileds = reactive([])
let customDetailsBak = reactive([])
let customDetailTiledsBak = reactive([])
let changedAttributes = reactive([])
let changedAttributeIds = reactive([])
let handleNumberSearch = ref('')
let mode = ref('view')
let showCustomDetails = ref(false)
let showCustomDetailTileds = ref(false)
const labelWidth = computed(() => {
  return mode.value == 'edit' ? '10rem' : '6.57rem'
})

watch(
  () => callData,
  (newValue, oldValue) => {
    afterGetCallData()
  },
  { deep: true }
)
onMounted(() => {
  // 使查询内容显示区域出现滚动条，即使在内容超长下也显示正常
  if (seachContentRef.value) {
    observer = new ResizeObserver(handleResize)
    observer.observe(popUpInternalPageRef.value, { box: 'border-box' })
  }
  afterGetCallData()
})

const afterGetCallData = () => {
  let phoneNum = getAcceptNo(callData.value)
  if (!phoneNum || phoneNum == 'undefined') {
    handleNumberSearch.value = ''
  } else {
    handleNumberSearch.value = phoneNum
  }
  getUserPermissions().then((res) => {
    userPermCodes = res
    if (userPermCodes['permCodes'].indexOf('80031001014002') >= 0) {
      queryAuth.value = true
    }
    queryCustomInfo(handleNumberSearch.value)
  })
}
const handleResize = (val) => {
  if (val[0] && val[0].contentRect && val[0].contentRect.width) {
    let width = val[0].contentRect.width
    seachContentRef.value.style.cssText += `width: ${width}px`
  }
}

const getAcceptNo = (params) => {
  let acceptNo = null
  if (params) {
    acceptNo = params['callType'] == '0' ? params['callerNum'] : params['calledNum']
  }
  return acceptNo
}

const changeValue = (attribute) => {
  if (changedAttributeIds.indexOf(attribute.id) < 0) {
    let attributeTemp = {}
    attributeTemp['id'] = attribute.id
    attributeTemp['basicStorageDataId'] = attribute.basicStorageDataId
    attributeTemp['relaBaseColumn'] = attribute.relaBaseColumn
    attributeTemp['value'] = attribute.value || ''
    attributeTemp['type'] = attribute.columnType

    changedAttributes.push(attributeTemp)
    changedAttributeIds.push(attribute.id)
  } else {
    for (let i = 0; i < changedAttributes.length; i++) {
      if (changedAttributes[i].id == attribute.id) {
        changedAttributes[i].value = attribute.value
        break
      }
    }
  }
}

const queryCustomInfo = (phoneNum) => {
  if (!queryAuth.value) {
    AiccElMessage.info(globalProperties.$t('agentconsole.modifycustominfo.noqueryauth'))
  }
  customDetails = reactive([])
  customDetailTileds = reactive([])
  customDetailsBak = reactive([])
  customDetailTiledsBak = reactive([])
  changedAttributes = reactive([])
  changedAttributeIds = reactive([])
  showCustomDetails.value = false
  showCustomDetailTileds.value = false
  if (!pageId.value || !phoneNum || phoneNum == 'undefined') {
    return
  }
  let params = {}
  params['pageId'] = pageId.value
  params['userNumber'] = phoneNum
  queryCustomInfoApi(params).then((resp) => {
    if (!resp || !resp.returnCode) {
      AiccElMessage.error(globalProperties.$t('SM.LOGIN.LABEL.SYSTEM_ERROR'))
    }
    if ('0' == resp.returnCode) {
      // 权限检查,外部接口不支持修改
      modifyAuth.value = userPermCodes['permCodes'] && userPermCodes['permCodes'].indexOf('80031001014003') >= 0 && resp.interfaceType != 2

      customDetails = resp.data.customInfo
      for (var a = 0; a < customDetails.length; a++) {
        for (var b = 0; b < customDetails[a].attributes.length; b++) {
          addValidatorAndDictLabel(a, b)
        }
      }
      customDetailTiledsForm.customDetailTileds = customDetailTileds
      customDetailForm.customDetails = customDetails
      customDetailsBak = JSON.parse(JSON.stringify(customDetails))
      customDetailTiledsBak = JSON.parse(JSON.stringify(customDetailTileds))
    } else if ('2' == resp.returnCode) {
      AiccElMessage.error(globalProperties.$t('agentconsole.callmessage.setNumerError'))
    } else if ('7' == resp.returnCode) {
      AiccElMessage.error(globalProperties.$t('agentconsole.callmessage.query.external.custom.info'))
    } else {
      AiccElMessage.error(globalProperties.$t('SM.LOGIN.LABEL.SYSTEM_ERROR'))
    }
    if (customDetails.length > 0) {
      showCustomDetails.value = true
    }
    if (customDetailTileds.length > 0) {
      showCustomDetailTileds.value = true
    }
  })
}

// 编辑查询到的数据，添加校验等
const addValidatorAndDictLabel = (a, b) => {
  let ruleList = []
  if (
    customDetails[a].attributes[b].isMandatory == 'Y' &&
    customDetails[a].attributes[b].isIndex != 'Y'
  ) {
    ruleList.push({
      required: true,
      message: globalProperties.$t('SM.LOGIN.TIPS.REQUIRED'),
      trigger: ['blur','change']
    })
  }
  // 若为掩码展示内容，直接将当前的校验规则赋值并退出
  if (customDetails[a].attributes[b].isMaskDisplay === 'Y' && !hasMaskViewAuth.value) {
    customDetails[a].attributes[b].rules = ruleList
    customDetailTileds.push(customDetails[a].attributes[b])
    return
  }
  if (customDetails[a].attributes[b].columnLength) {
    const i18nMessage = globalProperties.$t('aicc.lengthLimit', {
      limit: customDetails[a].attributes[b].columnLength
    })
    ruleList.push({
      validator: validateNumberLength(
        parseInt(customDetails[a].attributes[b].columnLength),
        i18nMessage
      ),
      trigger: ['blur','change']
    })
  }
  if (customDetails[a].attributes[b].columnType == '2') {
    ruleList.push({
      validator: validateNumber,
      trigger: ['blur','change']
    })
  }
  if (customDetails[a].attributes[b].columnType == '5') {
    let dictItem =
      customDetails[a].attributes[b].dictItems &&
      customDetails[a].attributes[b].dictItems.find(
        (item) => item.key == customDetails[a].attributes[b].value
      )
    customDetails[a].attributes[b].dictLabel = dictItem && dictItem.label
  }
  customDetails[a].attributes[b].rules = ruleList
  customDetailTileds.push(customDetails[a].attributes[b])
}

const searchData = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (!valid) {
      return
    }
    handleNumberSearch.value = ruleForm.handleNumber
    mode.value = 'view'
    queryCustomInfo(handleNumberSearch.value)
  })
}

const cancel = () => {
  customDetailsRef.value.resetFields()
  customDetails = JSON.parse(JSON.stringify(customDetailsBak))
  customDetailTileds = JSON.parse(JSON.stringify(customDetailTiledsBak))
  mode.value = 'view'
}

const modify = () => {
  mode.value = 'edit'
}

// 保存编辑的数据
const save = () => {
  if (!customDetailsRef.value) return
  customDetailsRef.value.validate((valid) => {
    if (!valid) {
      return
    }
    if (!modifyAuth.value) {
      AiccElMessage.info(globalProperties.$t('agentconsole.modifycustominfo.nomodifyauth'))
      return
    }
    if (changedAttributes.length <= 0) {
      AiccElMessage.info(globalProperties.$t('agentconsole.modifycustominfo.nodatamodify'))
      return
    }
    let params = {}
    params['pageId'] = pageId.value
    params['attributes'] = []
    for (let attribute of changedAttributes) {
      let attr = {
        id: attribute.id,
        basicStorageDataId: attribute.basicStorageDataId,
        relaBaseColumn: attribute.relaBaseColumn
      }
      if (attribute.type == 3 || attribute.type == 4) {
        if (attribute.value) {
          attr['value'] = isNaN(attribute.value)
            ? attribute.value
            : attribute.type == 3
            ? globalProperties.$TimeUtils.transDate(attribute.value, '-')
            : globalProperties.$TimeUtils.transTime(attribute.value, '-')
        } else {
          attr['value'] = ''
        }
      } else {
        attr['value'] = attribute.value || ''
      }
      params['attributes'].push(attr)
    }
    modifyCustomInfo(params).then((resp) => {
      if (resp && resp.returnCode) {
        if ('0' == resp.returnCode) {
          mode.value = 'view'
          queryCustomInfo(handleNumberSearch.value)
          AiccElMessage.success(globalProperties.$t('agentconsole.modifycustominfo.modifysuccess'))
        } else if ('2' == resp.returnCode) {
          AiccElMessage.info(globalProperties.$t('agentconsole.modifycustominfo.modifyexternalfailed'))
        } else if ('3' == resp.returnCode) {
          AiccElMessage.info(globalProperties.$t('agentconsole.modifycustominfo.modifyfailed.validate'))
        }else if ('4' == resp.returnCode) {
          AiccElMessage.info(globalProperties.$t('agentconsole.modifycustominfo.modifyfailed.validate.num'))
        }else if ('5' == resp.returnCode) {
          AiccElMessage.info(globalProperties.$t('agentconsole.modifycustominfo.modifyfailed.validate.date'))
        }else if ('6' == resp.returnCode) {
          AiccElMessage.info(globalProperties.$t('agentconsole.modifycustominfo.modifyfailed.validate.time'))
        } else {
          AiccElMessage.error(globalProperties.$t('agentconsole.modifycustominfo.modifyfailed'))
        }
      } else {
        AiccElMessage.error(globalProperties.$t('agentconsole.modifycustominfo.modifyfailed'))
      }
    })
  })
}

/**
 * 重设输入框属性值和校验规则
 * @param attribute 需要重设的属性
 */
const resetAttributeValue = (attribute) => {
  if (!attribute || attribute.isMaskDisplay != "Y" || hasMaskViewAuth.value) {
    return
  }
  const {isMandatory, isIndex, columnLength, columnType} = attribute
  let rules = []
  if (isMandatory == 'Y' && isIndex != 'Y') {
    rules.push({required: true, message: globalProperties.$t('SM.LOGIN.TIPS.REQUIRED'), trigger: ['blur','change']})
  }
  if (columnLength) {
    rules.push({
      validator: validateNumberLength(parseInt(columnLength), globalProperties.$t('aicc.lengthLimit', {limit: columnLength})),
      trigger: ['blur','change'],
    })
  }
  if (columnType == 2) {
    rules.push({ validator: validateNumber, trigger: ['blur','change'] })
  }
  attribute.rules = rules
  attribute.value = ''
}
</script>
<style lang="less" scoped>
.aicc-p-around--small {
  padding: 20px;
}
.seach-content {
  overflow-x: auto;
  //330组成：除了seach-content区域的其余视图的各种高度 如header头 tab头等 88 46 48 16+16 52+16 32
  height: calc(100vh - 330px);
}
.overflow-content {
  width: 68rem;
}
.pop-up-internal-page {
  width: 100%;
}
:deep(.el-input__wrapper) {
  border-radius: 8px !important;
}

.input-icon {
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
}
.cus-content {
  margin: 0 0 0.5rem 0;

  :deep(.sweet-form-tip-error) {
    white-space: unset;
    word-break: keep-all;
  }
}
.hlds-text-align--center {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cus-block + .cus-block {
  margin-top: 32px;
}
.object-name {
  margin: 8px 0;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1c1c1c;
  font-size: var(--14px-to-rem);
  padding-bottom: 10px;
  line-height: 24px;
}
.attribute-value {
  font-family: PingFang SC;
  font-weight: 500;
  color: #1c1c1c;
  font-size: var(--12px-to-rem);
  word-break: break-all;
}
.pop-up-background-white {
  background: #ffffff !important;
}
.pop-up-flex {
  flex: 1;
}
.pop-up-no-data {
  font-size: var(--12px-to-rem);
  color: #cccccc;
}
</style>