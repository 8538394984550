export function safeHtmlEncode(inputStr) {
    let ret = inputStr;
    ret = ret.replace(/\&/g, "&amp;");
    ret = ret.replace(/\</g, "&lt;");
    ret = ret.replace(/\>/g, "&gt;");
    ret = ret.replace(/\"/g, "&quot;");
    ret = ret.replace(/\'/g, "&#39;");
    ret = ret.replace(/\//g, "&#47;");
    ret = ret.replace(/\\/g, "&#92;");
    ret = ret.replace(/\(/g, "&#40;");
    ret = ret.replace(/\)/g, "&#41;");
    return ret;
}