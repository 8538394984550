<template>
  <div class="hlds-is-relative m_t20" id="voicetransfer" style="height: 100%">
    <div class="hlds-background--default hlds-is-relative" style="height: 100%;display: flex;flex-direction: column;background:#f3f3f3;padding-top: 1rem">
      <div :class="['inline_block',{'had-active':showCust}]" v-if="showCust">
        <sweet-button text @click="showCustomer()" style="padding:0" class="hlds-truncate" variant="base">
          {{ $t('agentconsole.title.onlycustomer') }}
        </sweet-button>
      </div>
      <div :class="['inline_block',{'had-active':!showCust}]" v-if="!showCust">
        <sweet-button text @click="showCustomer()" style="padding:0" class="hlds-truncate" variant="base">
          {{ $t('agentconsole.title.allchat') }}
        </sweet-button>
      </div>
      <div class="contact_end">
        <div class="call_details">
          <ul class="message_ul">
            <li v-for="($item, $index) in MessageList">
              <!-- 展示本座席内容 -->
              <div class="ml_message" v-if="$item.talkertype==1 && $item.isSelf" v-show="!showCust">
                <div class="call_message message_right">
                  <div :class="[{'right_message':$item.talkertype==1}]">
                    <div class="message_context">
                      <div :id="'isSensitive_'+$item.senindex" class="message_right_div"
                           v-if="$item.sensitiveFlag && $item.negativeFlag">
                      </div>
                      <div :id="'isSensitive_'+$item.senindex" class="message_right_div"
                           v-if="$item.sensitiveFlag && !$item.negativeFlag">
                      </div>
                      <div :class="[{'message_right_div':$item.talkertype==1,'message_left_div':$item.talkertype==2}]"
                           v-show="!$item.sensitiveFlag">
                        <div
                          :class="['message_text',{'is_negativeFlag':$item.negativeFlag,'is_positiveFlag':$item.positiveFlag}]"
                          v-text="$item.result.content"></div>
                      </div>
                      <div class="message_red_div" v-show="$item.sensitiveFlag">
                        <img alt=""
                             src="@/views/agentconsole/assets/img/ic_Prohibited_Words@2x.png"
                             style="width: 18px;height: 18px;margin-top: 3px;margin-right: 2px;"/>
                        <span>{{ $t('ccm.agent.label.sensitiveWord') }}</span>
                      </div>
                    </div>
                  </div>
                  <div style="position:relative;margin-top: 6px;margin-left: .625rem;">
                    <img alt="" src="@/views/agentconsole/assets/img/agentAvatar.png"
                         style="width: 1.88rem;" />
                  </div>
                </div>
              </div>

              <!-- 展示非本座席内容 -->
              <div class="ml_message" v-if="$item.talkertype==1 && !$item.isSelf" v-show="!showCust">
                <div class="call_message">
                  <div style="position:relative;margin-top: 6px;">
                    <img alt="" src="@/views/agentconsole/assets/img/agentAvatar.png"
                         style="width: 24px;"/>
                  </div>
                  <div :class="[{'right_message':$item.talkertype==1}]" style="margin-left:10px;margin-right:10px">
                    <div class="message_context">
                      <div class="message_left_div">
                        <div class="message_text" v-text="$item.result.content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 展示机器人内容 -->
              <div class="ml_message" v-if="$item.talkertype == '3' && !$item.isSelf" v-show="!showCust">
                <div class="call_message message_right">
                  <div :class="[{ 'right_message': $item.talkertype == '3' }]" style="margin-left:10px">
                    <div class="message_context">
                      <div class="message_right_div">
                        <div class="message_text" v-text="$item.result.content"></div>
                      </div>
                    </div>
                  </div>
                  <div style="position:relative;margin-top: 6px;">
                    <img alt="" src="@/views/agentconsole/assets/img/icon_robot.png"
                         style="width: 1.88rem;" />
                  </div>
                </div>
              </div>

              <!-- 展示客户内容 -->
              <div v-if="$item.talkertype==2">
                <div class="call_message">
                  <div style="position:relative;margin-top: 6px;">
                    <img alt="" src="@/views/agentconsole/assets/img/user1.png"
                         style="width: 24px;"/>
                  </div>
                  <div :class="[{'right_message':$item.talkertype==1}]" style="margin-left:10px;margin-right:10px">
                    <div class="message_context">
                      <div class="message_left_div">
                        <div class="message_text" v-text="$item.result.content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryASRResult,
  queryAiSummary,
  queryCaseType,
  queryCaseTemplateFields,
  queryAiIntention,
  queryAiAssistant,
  queryCase, getauths
} from "@/views/agentconsole/api/audio-video-workbench";
import $ from 'jquery';
import {
  sendAiDataStore,
  sendAiStatusStore, sendSummarySwitchStore,
  useAiAssistantStore,
  useAiSummaryStore,
  useAudioAiSwitchStore,
  useNlpResultStore,
  useVoiceTransferStore
} from "@/views/agentconsole/stores";
import { useAgentConsoleTabStore } from "@/views/ccdesktop/stores/agentConsoleStore";
import cookieUtils from "@/utils/cookie";

window.$ = $;
window.jQuery = $;

export default {
  name: 'VoiceTransfer',
  props: {
    callReasonList: {
      type: Array,
      default: () => []
    },
    callDataBot : {
      type: String,
      default: ""
    }
  },
  watch: {
    callDataBot: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal && newVal !== "") {
          // val转json
          newVal.replace(/[\\]/g, '')
          let tempString = newVal.replaceAll(':"{"', ':{"');
          tempString = tempString.replaceAll('"}",', '"},');
          if (tempString.indexOf("history") === -1) {
            return;
          }
          let callData = JSON.parse(tempString);
          let history = callData['data']['history'];
          // let history = callData['history'];
          let historyArr = history.split('-');
          if (historyArr.length > 0) {
            for (let i = 0; i < historyArr.length; i++) {
              let item = historyArr[i];
              let message = {};
              message.result = {};
              let itemArr = item.split(':');
              if (itemArr[0] === 'user') {
                message.talkertype = "2"
              }
              if (itemArr[0] === 'robot') {
                message.talkertype = "3"
              }
              message.result.content = itemArr[1];
              this.botMessageList.push(message);
            }
          }
          // 调用摘要接口
          this.queryChatBotSummary();
        }
      },
      immediate: true
    }
  },
  setup() {
    const voiceTransferStore = useVoiceTransferStore();
    const nlpResultStore = useNlpResultStore();
    const aiSummaryStore = useAiSummaryStore();
    const agentConsoleTabStore = useAgentConsoleTabStore();
    const audioAiSwitchStore = useAudioAiSwitchStore();
    const aiAssistantStore = useAiAssistantStore();
    const aiStatusStore = sendAiStatusStore();
    const aiDataStore = sendAiDataStore();
    const summarySwitchStore = sendSummarySwitchStore();
    return {voiceTransferStore, nlpResultStore, aiSummaryStore, agentConsoleTabStore, audioAiSwitchStore, aiAssistantStore, aiStatusStore, aiDataStore, summarySwitchStore}
  },
  data() {
    return {
      MessageList: [],
      botMessageList: [],
      showCust: false,
      result: {},
      positiveEmotionNumber: 0,
      negativeEmotionNumber: 0,
      sensitiveWordNumber: 0,
      index: 0,
      positiveFlag: false,
      negativeFlag: false,
      sensitiveFlag: false,
      textClassificationResult: {},
      queryFlag: false,
      callId: "",
      messageMap: {},
      allMessageMap: {},
      userNameMap: {},
      joinStr: window.isRTL ? "\u200F : " : " : ",
      caseType: "",
      caseTypeList: [],
      caseTypeNameList: [],
      caseFiledList: [],
      caseFiledForAiList: [],
      isPending: false,
      mood: "--",
      intention: "",
      currentCase: "",
      complain: "",
      moodNegativeList: [
        $t('ccmessaging.chat.case.upset'),
        $t('ccmessaging.chat.case.angry'),
        $t('ccmessaging.chat.case.little.angry'),
        $t('ccmessaging.chat.case.very.angry')
      ],
      complainList: [$t('ccmessaging.chat.case.complaint.yes')],
      timerId: null,  // 计时器的 ID
      isIntentRequestPending: false,  // 是否有正在进行中的后台请求
      hasUpdate: false,
      enableAIAssistant: false,
      assistantIntention: "",
      showIntelligentCase: false,
      history: [],
      robotSummary: "",
    }
  },
  computed: {},
  methods: {
    assistant(userMessage) {
      if (this.intention === "" || this.intention === "无") {
        return;
      }
      this.assistantIntention = this.intention;
      let history = [];
      for (let i = 0; i < this.MessageList.length; i++) {
        let array = [];
        let message = this.MessageList[i];
        if (message.talkertype === "3") {
          array.push("");
          array.push($t("agentconsole.voicetransfer.chatbot") + ': ' + message.result.content);
        } else if (message.talkertype === "1") {
          array.push("");
          array.push($t("agentconsole.voicetransfer.agent") + ': ' + message.result.content);
        } else {
          array.push($t("agentconsole.voicetransfer.user") + ': ' + message.result.content)
          if (i < this.MessageList.length - 1) {
            let nextMessage = this.MessageList[i+1];
            if (nextMessage.talkertype === "1") {
              array.push($t("agentconsole.voicetransfer.agent") + ': ' + nextMessage.result.content);
              i++;
            } else if (nextMessage.talkertype === "3") {
              array.push($t("agentconsole.voicetransfer.chatbot") + ': ' + nextMessage.result.content);
              i++;
            } else {
              array.push("");
            }
          } else {
            array.push("");
          }
        }
        history.push(array);
      }
      this.history = history;
      let language = cookieUtils.getCookie('u-locale');
      let param = {
        "knowledge_base_id": $t("ccmessaging.chat.case.very.knowledgeId"),
        "customer_input": userMessage.result.content,
        "history": history,
        "model": "PanGuV11",
        "language": language
      }
      queryAiAssistant(param).then(res => {
        if (res) {
          res.intention = this.assistantIntention;
          this.aiAssistantStore.setAiAssistantData(res, "setAiAssistantData");
        }
      }).catch(e => {
        this.assistantIntention = "";
      })
    },
    startTimer() {
      this.timerId = setInterval(() => {
        if (!this.isIntentRequestPending && this.hasUpdate && this.enableAIAssistant) {
          this.queryIntention();
        }
      }, 3000);
    },
    stopTimer() {
      clearInterval(this.timerId);
    },
    queryData() {
      if (!this.queryFlag) {
        return
      }
      var callId = sessionStorage.getItem("asr_callid");
      queryASRResult(callId).then(result => {
        this.result = result;
        // 递归查询
        this.queryData();
        if (!this.result) {
          return;
        }
        var resp = this.result.resp;
        if (resp && resp.length > 0) {
          for (var i = 0; i < resp.length; i++) {
            this.handleData(resp[i], callId);
          }
        }
      })
    },

    hasChinese(testWord) {
      var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      return reg.test(testWord);
    },

    handleData(result, callId) {
      // 推送事件给faq和话术
      if (JSON.stringify(result["nlpResult"])) {
        let data = {
          name: "nlpResultData",
          talkertype: result["talkertype"],
          nlpResult: JSON.stringify(result["nlpResult"])
        };
        this.nlpResultStore.setNlpData(data);
      }

      var content;
      // 对于asr_callid和callid一致时不能直接退出循环调用
      if (result && result['asrusercase'] == '63') {
        if (callId === sessionStorage.getItem("callid")) {
          this.queryFlag = false;
        } else {
          sessionStorage.setItem("asr_callid", sessionStorage.getItem("callid"));
        }
      }
      if (result && result["nlpResult"]) {
      }

      if (result && result['result'] && result.result.content) {
        content = result.result.content;
        try {
          if (content.indexOf("<id") != -1) { // 捷通asr aicc22.100对接的新版本，格式 {<id jws2312351sgfaew><nlresult 喂你好>}
            result.result.content = content.substring(content.indexOf('ult ') + 4, content.lastIndexOf('>'));
          } else if (content.indexOf(";") != -1) { // 捷通asr aicc22.100之前对接的版本  格式：{<nlresult 喂;time=4400 4640>}
            result.result.content = content.substring(content.indexOf('ult ') + 4, content.indexOf(';'));
          } else { // 科大讯飞asr格式兼容 格式 {<nlresult 喂你好>}
            result.result.content = content.substring(content.indexOf('ult ') + 4, content.indexOf('>'));
          }
          if (this.hasChinese(content)) {
            result.result.content = result.result.content.replace(/\s/g, "");
          }
        } catch (error) {

        }

        result['positiveFlag'] = false;
        result['negativeFlag'] = false;
        result['sensitiveFlag'] = false;
        if (result.nlpResult) {
          if (result.nlpResult.sensitive_word && JSON.stringify(result.nlpResult.sensitive_word) != "{}") {
            result['senindex'] = this.index;
            var nlpResult = result.nlpResult;
            var sensitiveObject = result.nlpResult.sensitive_word;
            var sensType = "";
            var sensConcent = [];
            for (var key in sensitiveObject) {
              sensType = sensType + key + "|";
              sensConcent.push(sensitiveObject[key]);
            }
            sensType = sensType.substring(0, sensType.length - 1);
            var word = result.result.content;
            if (result.talkertype == "1" && nlpResult.call_script_matchedstep == "SENSITIVE_WORDS") {
              this.sensitiveWordNumber = this.sensitiveWordNumber + 1;
              result['sensitiveFlag'] = true;
              //违禁语标签创建
              sensConcent = sensConcent[0];
              for (var j = 0; j < sensConcent.length; j++) {
                if (word.indexOf(sensConcent[j]) != -1) {
                  word = word.split(sensConcent[j]).join("<span style='font-size: 14px;color: #EE343F;font-weight: 500;'>" + this.escapeText(sensConcent[j]) + "</span>");
                }
              }
            }
          }
          if (result.nlpResult.sentiment_analysis) {
            if (result.talkertype == "1") {
              if (result.nlpResult.sentiment_analysis == "positive" && result.nlpResult?.call_script_matchedstep !== "SENSITIVE_WORDS") {
                this.positiveEmotionNumber = this.positiveEmotionNumber + 1;
                result['positiveFlag'] = true;
              }
              if (result.nlpResult.sentiment_analysis == "negative") {
                this.negativeEmotionNumber = this.negativeEmotionNumber + 1;
                result['negativeFlag'] = true;
              }
            }
          }
        }
        if (result.talkertype) { // talkertype 为1是座席说的话  为2的话是客户说的话
          if (this.allMessageMap && this.allMessageMap[callId]) {
            this.allMessageMap[callId] = this.allMessageMap[callId] + result.result.content;
          } else {
            this.allMessageMap[callId] = result.result.content;
          }
          top.window.$Model.realtimeTransferAllMsg = this.allMessageMap;

          if (result.talkertype == "2") {
            if (this.messageMap && this.messageMap[callId]) {
              this.messageMap[callId] = this.messageMap[callId] + result.result.content;
            } else {
              this.messageMap[callId] = result.result.content;
            }
            this.userNameMap[callId] = result.talkerid;
            this.hasUpdate = true;
            if (this.enableAIAssistant) {
              this.assistant(result);
            }
          }
        }
        this.addProperty(result);
        this.MessageList.push(result);
        if (this.showIntelligentCase && result.talkertype === "2") {
          this.queryAi(true);
        }
        //违禁语标签append到dom元素上
        var timer = window.setTimeout((e) => {
          if (result && result.nlpResult && result.nlpResult.sensitive_word) {
            if (result.talkertype == "1" && result.nlpResult.call_script_matchedstep == "SENSITIVE_WORDS") {
              let className = "message_text";
              if (result['negativeFlag']) {
                className = "message_text is_negativeFlag"
              }
              var sensitiveWord = "<div class='" + className + "'>" + " " + word + "</div>";
              window.$("#isSensitive_" + this.index).append(sensitiveWord);
              this.index++;
            }
          }
          window.$(".call_details").scrollTop(window.$('.message_ul').height() + 100);
          window.clearTimeout(timer);
        }, 10)
      }
    },
    queryIntention() {
      this.isIntentRequestPending = true;
      this.hasUpdate = false;
      let message = "";
      for (let result of this.MessageList) {
        if (result.talkertype === "2") {
          message = message + result.result.content + ";"
        }
      }
      let language = cookieUtils.getCookie('u-locale');
      let callReason = [];
      this.callReasonList.forEach((c) => {
        if (language === "zh_CN") {
          let pattern = new RegExp("[\u4E00-\u9FA5]+");
          if(pattern.test(c.display)) {
            callReason.push(c.display)
          }
        } else if (language === "en_US") {
          let pattern = new RegExp("[A-Za-z]+");
          if(pattern.test(c.display)) {
            callReason.push(c.display)
          }
        } else {
          callReason.push(c.display)
        }
      });
      let param = {
        "knowledge_base_id": $t("ccmessaging.chat.case.very.knowledgeId"),
        "customer_input": message,
        "history": [],
        "model": "PanGuV11",
        "language": language,
        "intent_list":callReason,
        "emotion_list":[
          $t("ccmessaging.chat.case.puzzled"),
          $t("ccmessaging.chat.case.calm"),
          $t("ccmessaging.chat.case.happy"),
          $t("ccmessaging.chat.case.upset"),
          $t("ccmessaging.chat.case.surprise"),
          $t("ccmessaging.chat.case.pleasant"),
          $t("ccmessaging.chat.case.angry"),
          $t("ccmessaging.chat.case.little.angry"),
          $t("ccmessaging.chat.case.very.angry")
        ]
      };
      queryAiIntention(param).then(res => {
        this.isIntentRequestPending = false;
        if (res !== undefined && Array.isArray(res) && res.length === 4) {
          this.mood = res[0];
          if (callReason.includes(res[1])) {
            this.intention = res[1];
          }
          this.complain = res[2];
          this.address = res[3];
          this.$emit('moodChange', {
            "mood": this.mood,
            "intention": this.intention,
            "complain": this.complain,
            "address": this.address
          })
          if (this.caseTypeNameList.includes(this.intention) && this.currentCase !== this.intention) {
            this.$emit('countDown', this.intention);
            this.currentCase = this.intention;
          }
        }
      }).catch(error => {
        this.isIntentRequestPending = false;
      })
    },

    queryAi(structured, fromClickSummary) {
      if (this.isPending && structured) {
        return;
      }
      this.isPending = true;
      let history = '';
      for (let i = 0; i < this.MessageList.length; i++) {
        let message = this.MessageList[i];
        if (message.talkertype === '2') {
          history = history + $t("agentconsole.voicetransfer.user") + ': ';
        }
        if (message.talkertype === '1') {
          history = history + $t("agentconsole.voicetransfer.agent") + ': ';
        }
        if (message.talkertype === '3') {
          history = history + $t("agentconsole.voicetransfer.chatbot") + ': ';
        }
        history = history + message.result.content + '\n';
      }
      let entities = ["title", "caseType", "callReason"];
      entities = entities.concat(this.caseFiledForAiList);
      let orderType = [];
      let language = cookieUtils.getCookie('u-locale');
      for (let item of this.caseTypeList) {
        if (language === "zh_CN") {
          let pattern = new RegExp("[\u4E00-\u9FA5]+");
          if(pattern.test(item.typeName)) {
            orderType.push(item.typeName)
          }
        } else if (language === "en_US") {
          let pattern = new RegExp("[A-Za-z]+");
          if(pattern.test(item.typeName)) {
            orderType.push(item.typeName)
          }
        } else {
          orderType.push(item.typeName)
        }
      }
      let callReason = [];
      this.callReasonList.forEach((c) => {
        if (language === "zh_CN") {
          let pattern = new RegExp("[\u4E00-\u9FA5]+");
          if(pattern.test(c.display)) {
            callReason.push(c.display)
          }
        } else if (language === "en_US") {
          let pattern = new RegExp("[A-Za-z]+");
          if(pattern.test(c.display)) {
            callReason.push(c.display)
          }
        } else {
          callReason.push(c.display)
        }
      });
      let param = {
        "knowledge_base_id": $t("ccmessaging.chat.case.very.knowledgeId"),
        "history": [
          [
            history, ""
          ]
        ],
        "model": "PanGuV11",
        "language": language,
        "structured": structured,
        "entities": entities,
        "order_type": orderType,
        "call_reason": callReason
      };
      queryAiSummary(param).then(result => {
        this.isPending = false;
        let pageInfo = {};
        if (structured) {
          const jsonObject = JSON.parse(result.replaceAll("，", ","));
          // 发送事件到AudioAndVideoWorkbench
          if (jsonObject.title !== "" && jsonObject.title !== undefined) {
            pageInfo.title = jsonObject.title;
          }
          if (jsonObject.deadline !== "" && jsonObject.deadline !== undefined) {
            pageInfo.dueDate = jsonObject.deadline;
          }
          if (jsonObject.caseType !== "" && jsonObject.caseType !== undefined) {
            pageInfo.type = jsonObject.caseType;
            this.caseType = jsonObject.caseType;
          }
          if (jsonObject.callReason !== "" && jsonObject.callReason !== undefined) {
            pageInfo.callReason = jsonObject.callReason;
          }
          for (let item of this.caseFiledForAiList) {
            pageInfo[item + "__CST"] = jsonObject[item];
          }
        } else {
          pageInfo.description = result;
        }

        this.aiSummaryStore.setAiSummaryData(pageInfo, "setAiSummaryData");
        if (fromClickSummary) {
          this.aiDataStore.setAiData(pageInfo, "summaryData");
        }
      })
    },
    queryCaseForChat() {
      let language = cookieUtils.getCookie('u-locale');
      let param = {
        "knowledge_base_id": $t("ccmessaging.chat.case.very.knowledgeId"),
        "history": this.history,
        "model": "PanGuV11",
        "language": language
      }
      queryCase(param).then(result => {
        this.aiDataStore.setAiData({"result" : result}, "caseData");
      })
    },

    addProperty(result) {
      let agentInfoJson = sessionStorage.getItem("agentInfo");
      if (!agentInfoJson) {
        return;
      }
      result["isSelf"] = false;
      let agentInfo = JSON.parse(agentInfoJson);
      if (result.talkertype === "1" && result.talkerid == agentInfo.workNo) {
        result["isSelf"] = true;
      }
    },

    escapeText(text) {
      text = '' + text;
      var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
        '(': '&#40;',
        ')': '&#41;'
      };
      return text.replace(/[&<>"'()/]/g, function (m) {
        return map[m];
      });
    },

    showCustomer() {
      this.showCust = !this.showCust;
    },
    createWorkOrder(label) {
      let detalUrl = "resource.root/cccase/pages/createcase/createcase.html";
      detalUrl = detalUrl + "?callId=" + this.callId + "&caseType=" + label;
      let userName = this.userNameMap[this.callId];
      top.window.$BES.$Portal.tabpanel.createTabItemByConf(this.callId, $UEE.i18n('casemanagement.casequery.button.create') + "-" + userName, detalUrl);
      if (this.callId || this.textClassificationResult) {
        var obj = {
          "callId": this.callId,
          "caseType": label,
          "conversationText": this.allMessageMap[this.callId]
        }
        var createCaseFrame = this.findIframe(this.callId);
        setTimeout(function () {
          createCaseFrame.contentWindow.postMessage({"createCase": obj}, location.origin);
        }, 1000);
      }
    },

    selectWorkOrderType(data) {
      this.createWorkOrder(data);
    },

    findIframe(iframeId) {
      var iframes = top.document.getElementsByTagName("iframe")
      var targetIframes;
      for (let i = 0; i < iframes.length; i++) {
        targetIframes = iframes[i];
        if ($(targetIframes).attr('innerid') == iframeId) {
          break;
        }
      }
      return targetIframes;
    },
    checkTransferState(voiceTransferStore) {
      let callId, agentId;
      if (voiceTransferStore && voiceTransferStore.name === "initPageTransfer") {
        this.callId = voiceTransferStore.callId;
        callId = sessionStorage.getItem("asr_callid");
        agentId = voiceTransferStore.agentId;
        if (callId && agentId) {
          this.MessageList = [];
          if (this.botMessageList.length > 0) {
            this.MessageList = this.MessageList.concat(this.botMessageList);
            this.botMessageList = [];
          }
          this.intention = "";
          this.currentCase = "";
          this.positiveEmotionNumber = 0;
          this.negativeEmotionNumber = 0;
          this.sensitiveWordNumber = 0;
          this.$emit('sensitiveWord', {
            "positiveEmotionNumber": this.positiveEmotionNumber,
            "negativeEmotionNumber": this.negativeEmotionNumber,
            "sensitiveWordNumber": this.sensitiveWordNumber
          })
          if (this.queryFlag === true) {
            return;
          }
          this.queryFlag = true;
          this.queryData();
        }
      }
      if (voiceTransferStore && voiceTransferStore.name === "endOfCallTransfer") {
        this.queryFlag = false;
      }
    },
    querySummary() {
      let history = '';
      for (let i = 0; i < this.MessageList.length; i++) {
        let message = this.MessageList[i];
        if (message.talkertype === '2') {
          history = history + $t("agentconsole.voicetransfer.user") + ': ';
        }
        if (message.talkertype === '1') {
          history = history + $t("agentconsole.voicetransfer.agent") + ': ';
        }
        if (message.talkertype === '3') {
          history = history + $t("agentconsole.voicetransfer.chatbot") + ': ';
        }
        history = history + message.result.content + '\n';
      }
      let language = cookieUtils.getCookie('u-locale');
      let param = {
        "knowledge_base_id": $t("ccmessaging.chat.case.very.knowledgeId"),
        "history": [
          [
            history, ""
          ]
        ],
        "model": "PanGuV11",
        "language": language,
        "structured": false,
        "entities": [],
        "order_type": [],
        "call_reason": []
      };
      queryAiSummary(param).then(result => {
        this.isPending = false;
        console.log(result);
        this.$emit('summaryChange', result);
      })
    },
    queryChatBotSummary() {
      let history = '';
      for (let i = 0; i < this.MessageList.length; i++) {
        let message = this.MessageList[i];
        if (message.talkertype === '2') {
          history = history + $t("agentconsole.voicetransfer.user") + ': ';
        }
        if (message.talkertype === '3') {
          history = history + $t("agentconsole.voicetransfer.agent") + ': ';
        }
        history = history + message.result.content + '\n';
      }
      let language = cookieUtils.getCookie('u-locale');
      let param = {
        "knowledge_base_id": $t("ccmessaging.chat.case.very.knowledgeId"),
        "history": [
          [
            history, ""
          ]
        ],
        "model": "PanGuV11",
        "language": language,
        "structured": false,
        "entities": [],
        "order_type": [],
        "call_reason": []
      };
      queryAiSummary(param).then(result => {
        this.isPending = false;
        this.$emit('chatBotSummaryChange', result);
      })
    },
  },
  created() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  mounted() {
    //将实时转写的对话文本等信息给agentconsole页面，供创建工单使用
    top.window.$Model.realtimeTransferCusMsg = this.messageMap;
    top.window.$Model.realtimeTransferAllMsg = this.allMessageMap;
    top.window.$Model.userNameMap = this.userNameMap;

    // 订阅数据状态的变化
    this.voiceTransferStore.$subscribe((mutation, state) => {
      this.checkTransferState(state);
    });

    // 首次处理数据
    this.checkTransferState(this.voiceTransferStore);

    this.agentConsoleTabStore.$subscribe((mutation, state) => {
      if (state?.dataSrc === "audioAndVideo" && state?.pageParams?.['name'] === "endOfCall" && this.showIntelligentCase) {
        if (this.MessageList&& this.MessageList.length !== 0) {
          this.isPending = false;
          this.queryAi(false);
        }
      }
    });

    this.audioAiSwitchStore.$subscribe((mutation, state) => {
      if (!state.sendEventName || state.sendEventName !== "setAudioAiSwitchData") {
        return
      }
      this.enableAIAssistant = state.data;
      this.audioAiSwitchStore.setAudioAiSwitchData();
    })

    this.aiStatusStore.$subscribe((mutation, state) => {
      if (!state.sendEventName) {
        return
      }
      if (state.sendEventName === "autoCaseStatus") {
        this.showIntelligentCase = state.data;
        if (this.showIntelligentCase) {
          this.queryAi(true);
          this.queryAi(false);
        }
      }
      if (state.sendEventName === "summaryStatus") {
        this.queryAi(false, true);
      }
      if (state.sendEventName === "caseStatus") {
        this.queryCaseForChat();
      }
      this.aiStatusStore.setAiStatus();
    })

    this.summarySwitchStore.$subscribe((mutation, state) => {
      if (!state.sendEventName && state.sendEventName !== "summarySwitch") {
        return
      }
      // 生成summary
      this.querySummary();
      this.summarySwitchStore.setSummarySwitch();
    })
    getauths({}).then(data => {
      if (data) {
        if (jQuery.inArray("90011001", data["permCodes"]) !== -1) {
          document.getElementById('bingoPageInitCas').contentWindow.fetch("/service/AICC__Case/1.0.0/caseType/list", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({"start":0,"limit":100,"status":"Active","typeName":""})
          }).then(response => response.json())
              .then(data => {
                if (data.resCode === "0") {
                  this.caseTypeList = data.result.caseTypes;
                  if (this.caseTypeList.length > 0) {
                    for (let caseInfo  of this.caseTypeList) {
                      this.caseTypeNameList.push(caseInfo.typeName);
                    }
                  }
                }
              });

          let data = { "flag": "F", "base": "N" };
          queryCaseTemplateFields(data).then(response => {
            if (response && response.resCode && response.resCode !== "0") {
              return;
            }
            let language = cookieUtils.getCookie('u-locale');
            let caseField = response.result;
            for (let item of caseField.fields) {
              let itemFieldName = item.name;
              if (itemFieldName.includes("CST") && !itemFieldName.includes("AICC")) {
                if (language === "zh_CN") {
                  let pattern = new RegExp("[\u4E00-\u9FA5]+");
                  if(pattern.test(item.label)) {
                    this.caseFiledList.push(itemFieldName);
                    this.caseFiledForAiList.push(itemFieldName.replace("__CST", ""));
                  }
                } else if (language === "en_US") {
                  let pattern = new RegExp("[A-Za-z]+");
                  if(pattern.test(item.label)) {
                    this.caseFiledList.push(itemFieldName);
                    this.caseFiledForAiList.push(itemFieldName.replace("__CST", ""));
                  }
                } else {
                  this.caseFiledList.push(itemFieldName);
                  this.caseFiledForAiList.push(itemFieldName.replace("__CST", ""));
                }
              }
            }
          })
        }
      }
    });
  }
}
</script>

<style lang="less" scoped>
@import '@/views/agentconsole/css/audioandvideoworkbench.css';
.m_t20 {
  background-color: #fff;
}

.title {
  font-weight:600;
  font-size:var(--14px-to-rem);
  line-height:1.5rem;
}

.sensitive_left {
  width: 100%;
  display: flex;
  padding: 0 1.25rem;
  box-sizing: border-box;
  height: 3rem;
  line-height: 22px;
  flex-wrap: wrap;
  align-content: space-evenly;
  font-family: PingFang SC;
  font-size: var(--14px-to-rem);
  color: #1C1C1C;
  font-weight: 400;
}

.inline_block {
  display: flex;
  justify-content: end;
  margin-right: 1.25rem;
  font-size: 0.875rem;
  line-height: 1rem;
}

.position-bottom {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #eee;
  background:#fbfbfb;
  height: 6.19rem;
}

:deep(.inline_block .el-button>span) {
  font-size: var(--14px-to-rem);
}

.text_mid {
  display: flex;
  align-items: center;
  flex: 0 0 100%;
}

.button_right {
  display: flex;
  line-height: 1.5rem;
  align-items: center;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
  padding: 1.25rem 1.25rem 0;
  box-sizing: border-box;
  background: #F3F3F3;
}


.call_details {
  max-height: calc(100vh - 18rem);
  overflow: auto;
}

.message_ul {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  background-color: rgb(243, 243, 243);
}

.ml_message {
  display: flow-root;
}

.call_message {
  margin-top: 0.5rem;
  display: flex;
  padding-bottom: 1rem;
  position: relative;
}

.message_right {
  text-align: end;
  float: right;
}

body.RTL .message_right {
  text-align: end;
  float: left;
}

.call_name {
  color: rgb(145, 152, 158);
}

.message_left_div {
  background-color: #393939;
  width: fit-content;
  font-size: var(--14px-to-rem);
  color: #F5F5F5;
  border-radius: 4px;
  padding: 6px 4px;
  position: relative;
  font-weight: 500;
}

.message_right_div {
  width: fit-content;
  font-size: var(--14px-to-rem);
  padding: 6px 4px;
  text-align: left;
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 4px;
  display: inline-block;
  background: #e9e9f3;
  margin-right: 0.625rem;
}

.message_context {
  max-width: 600px;
}

.message_green_div {
  background-color: #DFF4E7;
  width: fit-content;
  padding: 0.5rem;
  color: #61C789;
  border-radius: 8px;
}

.message_red_div {
  height: auto;
  background: rgba(238, 52, 63, 0.07);
  border-radius: 4px;
  width: fit-content;
  color: #ee343f;
  box-sizing: border-box;
  margin-top: 8px;
  line-height: 24px;
  padding: 0 6px;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  text-align: start;
}

.mess_mr_10 {
  margin-inline-end: 10px;
}

.mess_ml_10 {
  margin-inline-start: 10px;
}

.message_text {
  line-height: 18px;
  padding: 4px 8px;
  max-width: 200px;
  white-space: normal;
  display: flex;
}

.hlds-button {
  color: #1C1C1C;
}

.had-active > button {
  color: #366BFC !important;
}

.text_mid_li {
  flex: 0 0 60%;
  display: flex;
  align-items: center;
}

.sensitive_icon {
  width: 10px;
  height: 10px;
  opacity: 0.8;
  border-radius: 2px;
  margin-inline-start: 6px;
  margin-inline-end: 6px;
}

.color_green {
  background: #63CA22;
}

.color_red {
  background: #EE343F;
}

#audio_and_video_workbench_id {
  overflow-y: hidden;
}

.had_img {
  width: 16px;
  height: 16px;
  display: flex;
  margin-inline-start: 3px;
  margin-inline-end: 3px;
}

.message_right_div::after, .message_left_div::after {
  box-sizing: content-box;
  position: absolute;
  top: 20px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  transform: translate(0, -50%);
  display: block;
  content: '';
  z-index: 1;
}

.message_right_div::after {
  right: -7.6px;
  border-right: 4px solid transparent;
  border-left: 4px solid rgba(228, 228, 228, 1);
}

.message_right_div::before {
  right: -6.9px;
  border-right: 4px solid transparent;
  border-left: 4px solid #fff;
}

.message_left_div::after {
  left: -8px;
  border-right: 4px solid #393939;
  border-left: 4px solid transparent;
}

.message_left_div::before {
  left: -7.9px;
  border-right: 4px solid #393939;
  border-left: 4px solid transparent;
}
body.RTL {
  .message_right_div::after {
    left: -7.6px;
    border-left: 4px solid transparent;
    border-right: 4px solid rgba(228, 228, 228, 1);
  }

  .message_right_div::before {
    left: -7.9px;
    border-left: 4px solid rgba(228, 228, 228, 1);
    border-right: 4px solid #fff;
    right: unset;
  }

  .message_left_div::after {
    right: -8px;
    border-left: 4px solid #fff;
    border-right: 4px solid transparent;
  }

  .message_left_div::before {
    right: -7.9px;
    border-left: 4px solid transparent;
    border-right: 4px solid #393939;
    left: unset;
  }

  .message_right_div::before, .message_left_div::before {
    box-sizing: content-box;
    position: absolute;
    top: 20px;
    transform: translate(0, -50%) rotate(180deg);
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    display: block;
    content: '';
    z-index: 2;
  }

  .message_right_div::after, .message_left_div::after {
    box-sizing: content-box;
    position: absolute;
    top: 20px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    transform: translate(0, -50%) rotate(180deg);
    display: block;
    content: '';
    z-index: 1;
  }
}

.message_right_div::before, .message_left_div::before {
  box-sizing: content-box;
  position: absolute;
  top: 20px;
  transform: translate(0, -50%);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  display: block;
  content: '';
  z-index: 2;
}

.is_positiveFlag {
  background: rgba(99, 202, 34, 0.2)
}

.is_negativeFlag {
  background: rgba(238, 52, 63, 0.2)
}
</style>